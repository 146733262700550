import React from 'react';
import {Tooltip} from "reactstrap";

export default class BaseIcon extends React.Component {

    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.renderTooltip = this.renderTooltip.bind(this);
        this.state = {
            tooltipOpen: false,
            id: "icon" + String(Math.ceil(Math.random() * 100000000)),
            cls: []
        };
        this.state.cls.push(props.className);
        this.state.cls.push(props.active ? "active" : "inactive");
    }

    toggle() {
        this.setState({
            tooltipOpen: !this.state.tooltipOpen
        });
    }


    renderTooltip = () => {
        return (
            <Tooltip placement="top" isOpen={this.state.tooltipOpen} target={this.state.id} toggle={this.toggle}>
                {this.props.tooltipText} <br /> {this.props.active ? "available" : "not available"}
            </Tooltip>)
    }

    render() {
        return (
            <div>
                <div id={this.state.id}>
                    <svg version="1.1"
                         xmlns="http://www.w3.org/2000/svg"
                         x="0px"
                         y="0px"
                         viewBox="0 0 600 600"
                         style={{...this.props.style, enableBackground: "new 0 0 600 600"}}
                         className={this.state.cls.join(" ")}>
                        {this.props.children}
                    </svg>
                    {this.props.displayTooltip && this.renderTooltip()}
                </div>
            </div>
        )
    }
}

