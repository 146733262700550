import React, {Component} from 'react';
import _ from 'lodash';
import {Col, Card, CardHeader} from "reactstrap";
import {ListGroup, ListGroupItem} from 'reactstrap';
import {sprintf} from 'sprintf-js';
import CardBody from "reactstrap/es/CardBody";


class ModelIdentifier extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let ids = _.filter(this.props.model.identifiers, id => id.source.is_supplier === this.props.suppliers && id.source.public);
        if (ids.length === 0) {
            return null
        }

        return (
            <Col className=" md-6 my-3" md={4}>
                <Card className="models">
                    <CardHeader>{this.props.header}</CardHeader>
                    <CardBody className="card-body-flush">
                        {this.props.header=='Suppliers' ?
                          ( <div className="msg_n">
                                  <b>Note:&nbsp;</b> *  denotes a commercial supplier.
                            </div>
                          ) : ''}
                        <ListGroup className="identifier-list" flush>
                            {(!this.props.suppliers) && (
                                <ListGroupItem>
                                    <strong className="d-inline-block w-50">Sanger
                                        ID</strong><span>{this.props.model.id}</span>
                                </ListGroupItem>
                            )}
                            {_.map(ids, id => {
                                return (
                                    id.source && id.source.name && id.identifier && (
                                        <ListGroupItem className="model-identifier w-100"
                                                       key={id.id}>
                                            <strong
                                                className="d-inline-block w-50">{
                                                  (this.props.header=='Suppliers' &&
                                                            ( id.source.name == 'DSMZ' ||
                                                              id.source.name == 'ATCC' ||
                                                              id.source.name == 'NCI' ||
                                                              id.source.name == 'KCLB' ||
                                                              id.source.name == 'RIKEN' ||
                                                              id.source.name == 'JCRB' ||
                                                              id.source.name == 'Rockland' ||
                                                              id.source.name == 'ECACC' ||
                                                              id.source.name == 'ICLC' ||
                                                              id.source.name == 'CLS' ||
                                                              id.source.name == 'Ximbio' ||
                                                              id.source.name == 'CellBank' ||
                                                              id.source.name == 'Australia' ||
                                                              id.source.name == 'Lonza' ||
                                                              id.source.name == 'BioIVT' ||
                                                              id.source.name == 'AddexBio'
                                                            )
                                                  )
                                                  ?  (id.source.name + ' *') : id.source.name }

                                                </strong>
                                            <span>
                                            <a href={id.source.url_format && sprintf(id.source.url_format, id.identifier)}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                            >
                                                {id.identifier}
                                            </a>
                                        </span>
                                        </ListGroupItem>
                                    ))
                            })}
                        </ListGroup>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default ModelIdentifier;
