import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {
        return (
            <BaseIcon style={props.style} active={props.active}>
                <polygon class="cls-1" points="271 29.55 271 64.45 175.44 0 175.14 0.44 175.08 0.35 79 65.15 79 29.55 0 29.55 0 146.55 79 146.55 79 98.24 175.74 33 271 97.24 271 146.55 419 146.55 419 29.55 271 29.55"/>
            </BaseIcon>
    )

}