import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {

    return (
        <BaseIcon
            {...props}
            tooltipText="DNA Methylation Data">

            <path d="M533,184l-8.9-10.6c0,0-10.1,25.9-14.9,36c-42.5,89.2-76.1,42.4-148.6,18.9c-3.6-1.2-7.4-2.3-11.5-3.3
	c0.2-18.6,1.4-35.1,5.8-49.5l23.4,40.5l30.1-17.4l-32.9-56.9c8.9-8.3,20.6-15.5,36.2-21.7l53.6,92.9l30.1-17.4l-49.6-86
	c7-1.7,14.5-3.2,22.6-4.7L451,84.1c-132.1-16.9-137.3,65-136.7,134.7c-78.4-8.7-190.2,12.8-196.4,142.7l17.4,20.7
	c37.8-102.4,105-139.5,179.3-135.6c0,3.7,0,7.4-0.1,10.9c-1.1,40.1,1.8,73.5-1.3,101l-23.7-41.1l-30.1,17.4l39.1,67.8
	c-7.8,11.2-19.2,20.9-35.9,29l-54-93.5l-30.1,17.4l51,88.3c-10.2,2.8-21.5,5.4-34.2,7.7C198,456,204,463,204,463s5,6,8.6,9.1
	c157.9,20.2,134.5-101.3,136.5-173.3c0.5-17.1,0.2-33,0-47.7c13.3,2.8,26.8,6.7,40.3,11.7c68.1,25.1,129.9,89,152-68.7
	C539,191,533,184,533,184z"/>
            <path d="M212.8,204.4c-27.5,6.8-44.9,13.9-55.2,21.5c-9.7,7.2-26.4,22.7-36.4,46.2c-37.1-6.5-74.8-44.1-67.7-91.5
	c6.6-44,47.5-74.3,91.5-67.7S219.4,160.4,212.8,204.4z"/>
        </BaseIcon>
    )

}