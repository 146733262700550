import React, {Component} from 'react';
import _ from 'lodash';
import {
    Col,
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import {api_base_url} from "../../../settings";
import Deserialiser from 'deserialise-jsonapi'

import Loading from '../../dumb/loading'
import DriverFilter from './driverFilter';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import filterFactory, {
    selectFilter,
    textFilter,
    multiSelectFilter,
    FILTER_TYPES,
    customFilter
} from 'react-bootstrap-table2-filter';

import ExportCSVButton from '../../dumb/fancyTable/ExportCSVButton'
import ExportXLSXButton from '../../dumb/fancyTable/ExportXLSXButton'
import {paginationOptions} from '../../dumb/fancyTable/Pagination'
import ColumnHeader from "../../dumb/fancyTable/columnHeader";

const des = new Deserialiser();

class ModelMutations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColumns: 9,
            model: props.model
        };

        this.wideBoxThreshold = 5;

        this.loadMutations = this.loadMutations.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.onColumnToggleHandler = this.onColumnToggleHandler.bind(this);
    }

    onColumnToggleHandler(activeColumns) {
        this.setState({activeColumns});
    }

    loadMutations() {
        const model_id = this.state.model.id;

        const url_muts = `${api_base_url}/models/${model_id}/datasets/mutations?merged=true&include=gene&page[size]=0&fields[gene]=symbol,cancer_driver&fields[mutation]=cancer_driver,protein,cdna,type,gene,effect,vaf,cancer_predisposition_variant`;
        const url_cnv = `${api_base_url}/models/${model_id}/datasets/genecnv?drivers=true&include=gene&page[size]=0&fields[gene]=symbol,cancer_driver`;

        Promise.all([
            fetch(url_muts).then(response => response.json()).then(resp_obj => des.deserialise(resp_obj)),
            fetch(url_cnv).then(response => response.json()).then(resp_obj => des.deserialise(resp_obj))
        ]).then(([muts, cnv]) => {
            for (let i = 0; i < muts.length; i++) {
                if (muts[i].cancer_driver === true && muts[i].cancer_predisposition_variant === true){
                    muts[i].driver_gene_alterations = 'DRV,CPV'
                }else if (muts[i].cancer_driver === true) {
                    muts[i].driver_gene_alterations = 'DRV'
                }else if (muts[i].cancer_predisposition_variant === true) {
                    muts[i].driver_gene_alterations = 'CPV'
                } else {
                    muts[i].driver_gene_alterations = ''
                }
            }
            for (let i = 0; i < cnv.length; i++) {
                cnv[i].driver_gene_alterations = cnv[i].cn_category
            }
            this.setState({
                mutations: muts.concat(cnv),
                load_complete: 1
            })
        }).catch((ex) => {
             console.log('parsing failed', ex);
        });

    }

    renderTable() {
        const booleanOptions = {
            true: 'Yes',
            false: 'No',
        };

        const typeOptions = {
            deletion: "Deletion",
            snp: "SNP",
            splice_variant: "Splice Variant",
            insertion: "Insertion",
            frameshift: "Frameshift",
            CNA: "CNA",
        };

        const pcnOptions = {
            Loss: "Loss",
            Gain: "Gain",
            Deletion: "Deletion",
            Amplification: "Amplification"
         };

        const columns = [
            {
                dataField: 'gene.symbol',
                text: "Symbol",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Gene Symbol",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '120px', whiteSpace: 'wrap'};
                }
            }, {
                dataField: 'driver_gene_alterations',
                text: "Driver alteration",
                filter: customFilter({
                    type: FILTER_TYPES.MULTISELECT
                }),
                filterRenderer: (onFilter, column) =>
                <DriverFilter onFilter={onFilter} column={column}/>,
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Driver gene alteration",
                formatter: (col, row) => { return <span style={{display: 'block', width: 200, textOverflow: 'ellipsis'}}>{col}</span> },
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '120px', whiteSpace: 'wrap'};
                }
            }, {
                dataField: 'gene.cancer_driver',
                text: "Cancer driver gene",
                filter: selectFilter({placeholder: ' ', options: booleanOptions}),
                formatter: cell => booleanOptions[cell],
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Mutation is in a known cancer driver gene",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '120px', whiteSpace: 'wrap'};
                }
            }, {
                dataField: 'type',
                text: "Type",
                filter: selectFilter({placeholder: ' ', options: typeOptions}),
                formatter: cell => typeOptions[cell],
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Mutation type",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '120px', whiteSpace: 'wrap'};
                }
            }, {
                dataField: 'protein',
                text: "Protein mutation",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Predicted protein mutation",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '120px', whiteSpace: 'wrap'};
                },
                formatter: (col, row) => { return <span style={{display: 'block', width: 100, whiteSpace: 'wrap', textOverflow: 'ellipsis'}}>{col}</span> }
            }, {
                dataField: 'cdna',
                text: "cDNA mutation",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Predicted cDNA mutation",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '120px', whiteSpace: 'wrap'};
                },
                formatter: (col, row) => { return <span style={{display: 'block', width: 100, whiteSpace: 'wrap', textOverflow: 'ellipsis'}}>{col}</span> }
            }
            ,{
                dataField: 'effect',
                text: "Effect",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Mutation effect",
                formatter: (col, row) => { return <span style={{display: 'block', width: 100, whiteSpace: 'wrap', textOverflow: 'ellipsis'}}>{col}</span> },
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '120px', whiteSpace: 'wrap'};
                }

            }, {
                dataField: 'vaf',
                text: "VAF",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Variant allele fraction",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '120px', whiteSpace: 'wrap'};
                }
            }, {
                dataField: 'total_copy_number',
                text: "Total copy number",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Total Copy Number",
                sort: true,
                headerStyle: (colum, colIndex) => {
                    return { width: '120px', whiteSpace: 'wrap'};
                }
            }
        ];

        return (

            <ToolkitProvider
                keyField="id"
                data={this.state.mutations}
                columns={columns}
                exportCSV={{fileName: `CMP_${this.state.model.names[0]}_mutations.csv`}}
            >
                {
                    props => (
                        <div>
                            <div className="d-flex justify-content-left">
                                <b>Note:&nbsp;</b>Displays coding mutations, cancer somatic driver variants (DRV) and cancer predisposition variants (CPV) with a VAF > 0.05, and copy number alterations of driver genes.
                            </div>
                            <div className="d-flex justify-content-end">
                                <ExportCSVButton {...props.csvProps} />
                                <ExportXLSXButton data={this.state.mutations} filename={`CMP_${this.state.model.names[0]}_mutations`} />
                            </div>
                            
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                hover
                                keyField='id'
                                bordered={false}
                                pagination={this.state.mutations.length > 5 ? paginationFactory(paginationOptions(5, this.state.mutations.length)) : null}
                                filter={filterFactory()}
                                tableHeaderClass="border-top-none"
                                defaultSortDirection="asc"
                                wrapperClasses="table-responsive w-100 col-md-auto"
                            />
                        </div>
                    )
                }
            </ToolkitProvider>


        )
    }

    componentDidMount() {
        this.loadMutations()
    }

    componentDidUpdate() {
        if (this.props.model.id !== this.state.model.id) {
            this.setState({model: this.props.model, load_complete: false}, this.loadMutations)

        }
    }

    render() {
        if (this.state.load_complete && _.isEmpty(this.state.mutations)) return null;

        let content = null;


        if (!this.state.load_complete) {
            content = (
                <div className="py-5"><Loading subtitle="Loading Mutations"/></div>);
        } else {
            content = this.renderTable()
        }
        return (
            <Col className="d-flex my-3" lg={12}
                 xl={12} md={12}>
                <Card className="flex-fill models fusions">
                    <CardHeader>Mutations & Copy Number Alterations</CardHeader>
                    <CardBody>
                        {content}
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default ModelMutations;
