import React, {Fragment} from 'react';
import _ from "lodash";
import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label } from "reactstrap";
import {withRouter} from 'react-router-dom';
import qs from 'qs';

import {urlify} from "../../utils";
import CollapseButton from '../dumb/collapseButton';
import baseFilter from "./baseFilter";

class GenderFilter extends baseFilter {
    constructor(props){
        super(props);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.updateAPIURL = this.updateAPIURL.bind(this);

        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        this.state = {
            isOpen: false,
            filters: GenderFilter.getFiltersFromQS(current_qs, {
                "Male": {filter_name: "male", active: false},
                "Female": {filter_name: "female", active: false},
                "Unknown": {filter_name: "unknown", active: false},

             }),
            activeCount:0
         };
        this.aggregates = {'sample.patient.gender': 'count'}
    }

    updateAPIURL(current_api_qs, newlistprops){
        let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
        //let qs_genderFilter = ('gender' in new_qs)? _.split(new_qs.gender, '_'):[];

        let qs_genderFilter = GenderFilter.getFiltersFromQS(new_qs,this.state.filters);
        let api_gender_filters = _.map(
            _.filter(
                _.toPairs(qs_genderFilter),
                ([gender, filterProps]) => {
                    return filterProps.active}),
                 ([gender, filterProps]) => {
                return filterProps.filter_name
            }
        );

        let filter_qs = {"name":"sample","op":"has","val":{"name":"patient","op":"has","val":{"name":"gender","op":"in","val":api_gender_filters}}};
        if(api_gender_filters.length > 0){
            if('filter' in current_api_qs){
                current_api_qs.filter.push(filter_qs)
            } else {
                current_api_qs['filter'] = [filter_qs]
            }
        }

        return _.merge(current_api_qs, {agg: this.aggregates})
    }

    processResponse(response){
        let resp_agg = this.get_agg_values(response);
        let agg_field = _.keys(this.aggregates)[0];
        if(! resp_agg){
            return
        }

        let newfilters = _.reduce(resp_agg[agg_field][this.aggregates[agg_field]],
            (res, count, gender) => {
                if(gender in this.state.filters){
                    res[gender] = this.state.filters[gender];
                    res[gender].count = count
                } else {
                    res[gender] = {'count': count, active: false}
                }
                return res
            }, {});
        this.setState({
            filters: newfilters
        })
    }

    static getFiltersFromQS(qs, oldfilters) {
        let qs_genderFilter = ('gender' in qs)? _.split(qs.gender, '_'):[];

        return _.reduce(oldfilters,
            (res, filterProps, gender) => {
                res[gender] = filterProps;
                res[gender].active = _.indexOf(qs_genderFilter, urlify(gender)) >= 0;
                return res
            }, {});
    }


    static getDerivedStateFromProps(newprops, oldstate){
        let current_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        let newfilters = GenderFilter.getFiltersFromQS(current_qs, oldstate.filters);

        return {
            filters: newfilters,
            activeCount: _.filter(newfilters, 'active').length
        }
    }

    toggleCheckbox(event){
        let gender = urlify(event.target.name);
        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let genderFilter = ('gender' in current_qs)? _.split(current_qs.gender, '_'):[];

        if(event.target.checked){
            genderFilter.push(gender)
        } else {
            genderFilter = genderFilter.filter((item) => urlify(item) !== gender)
        }
        current_qs.gender = (genderFilter.length > 0)? _.join(genderFilter.sort(), '_'): undefined;
        current_qs.page = undefined;
        this.props.history.push(window.location.pathname + qs.stringify(current_qs, { addQueryPrefix: true }))

    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    renderGenderCheckboxes(){
        if(! _.isUndefined(this.state.filters)) {

            let active = [],
                inactive = [];

            _.map(_.toPairs(this.state.filters), ([key, filterProps]) => {
                let cb = (
                    <FormGroup check key={key}>
                        <Input type="checkbox" name={key} onChange={this.toggleCheckbox} checked={filterProps.active}/>{' '}
                        <Label className="ml-4 mt-1" check>
                            {key} {filterProps.count > 0 && (<span className="secondary">({filterProps.count})</span>)}
                        </Label>
                    </FormGroup>);
                if (filterProps.active) {
                    active.push(cb)
                } else {
                    inactive.push(cb)

                }
            });

            active = _.sortBy(active, 'key');
            inactive = _.sortBy(inactive, 'key');

            if(active.length < 5 && active.length + inactive.length > 0) {
                active.push(inactive.splice(0, 5 - active.length ))
            }


            return (
                <Fragment>
                    {active}
                    {inactive}
                </Fragment>)
        }

    }

    componentDidMount () {
        this.props.registerResponse(this.processResponse);
        this.props.registerRequest(this.updateAPIURL);
    };

    componentWillUnmount () {
        this.props.unregisterResponse(this.processResponse);
        this.props.unregisterRequest(this.updateAPIURL);
    };

    render() {
        return (
            <Card className="mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline w-50">Gender</div>
                    <div className="d-inline-block">
                        {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        {this.renderGenderCheckboxes()}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(GenderFilter);
