import React from 'react';
import qs from "qs";
import _ from 'lodash';

class ListSummary extends React.Component {

    state = {}

    constructor(props){
        super(props)
        this.processResponse = this.processResponse.bind(this)
    }

    processResponse(response){

        let response_qs = qs.parse(_.get(response, "links.self"), { ignoreQueryPrefix: true });

        if ('page' in response_qs) {
            let currentPage = parseInt(response_qs.page.number || 1, 10);
            let pageSize = parseInt(response_qs.page.size || 30, 10);
            let modelCount = parseInt(response.meta.count || 0, 10);

            this.setState({
                currentListMin: (pageSize * (currentPage - 1)) + 1,
                currentListMax: Math.min(pageSize * currentPage, modelCount),
                modelCount: modelCount
            })
        }

    }

    componentDidMount(){
        this.props.registerResponse(this.processResponse)
    }

    componentWillUnmount(){
        this.props.unregisterResponse(this.processResponse)
    }

    render(){
        if (this.state.currentListMin && this.state.currentListMax && this.state.modelCount){
            return(
                <div className="d-flex flex-column justify-content-center ml-4" >
                    <span>Showing <strong>{this.state.currentListMin} - {this.state.currentListMax}</strong> out of <strong>{this.state.modelCount}</strong> models</span>
                </div>
            )
        }
        return null
    }
}

export default ListSummary;
