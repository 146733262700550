import React, {Component, Fragment} from 'react';

import Header from './header';
import Main from './main';
import Footer from './footer';
import {withRouter} from "react-router-dom";

class Wrapper extends Component {
    render() {
        return (
            <Fragment>
                <Header/>
                <Main/>
                <Footer/>
            </Fragment>
        )
    }
}

export default withRouter(Wrapper);