import React from 'react';
import {capitalise, humanFileSize} from '../../../utils';

export default function(props){

    let linktext;
    let source;
    if(props.file.size) {
        linktext = props.file.url.substring(props.file.url.lastIndexOf("/") + 1);
        linktext += (props.file.size ? " (" + humanFileSize(props.file.size) + ") " : " ");
    } else {
        if(props.file.meta.filetype) {
            linktext = props.file.meta.data_type + " (" + props.file.meta.filetype + ") ";
        } else {
            linktext = props.file.meta.data_type + " ";
        }
    }
    if(props.file.meta.data_source) {
        source = props.file.meta.data_source
    } else {
        if(props.file.dataset) {
            source = props.file.dataset.location
        }
    }
    return(
        <div className="dataset-list-item">
            <span className="dataset-type">{capitalise(props.file.meta.level)} </span>
            <a href={props.file.url}>
                {linktext}
            </a>
            {(props.file.dataset || props.file.meta.data_source)  && (<span className="small">- Source: <i>{source}</i></span>)}
        </div>
    )
}