import React, {Fragment} from 'react';
import {Form, FormGroup, Input, Label, Pagination, PaginationItem, PaginationLink} from 'reactstrap';
import {Link, withRouter} from 'react-router-dom';
import qs from 'qs'
import _ from "lodash";

class PaginationBlock extends React.Component {

    constructor(props) {
        super(props)

        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        this.state = {
            current_base : window.location.pathname,
            current_qs : current_qs,
            currentPage : parseInt(current_qs.page || 1, 10),
            pageSize: parseInt(current_qs.pageSize || 30, 10),
            maxPage : 0
        };

        this.pageNumberCutoff = 6;

        this.processResponse = this.processResponse.bind(this)
        this.updateAPIURL = this.updateAPIURL.bind(this)
    }

    static getDerivedStateFromProps(newprops, oldstate){
        let current_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        return {
            current_base : newprops.location.pathname,
            current_qs : current_qs,
            currentPage : parseInt(current_qs.page || 1, 10),
            pageSize: parseInt(current_qs.pageSize || 30, 10)
        }

    }

    updateAPIURL(api_qs, newlistprops){

        let page_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true })

        api_qs['page[number]'] = parseInt(page_qs.page || 1, 10);
        api_qs['page[size]'] = parseInt(page_qs.pageSize || 30, 10)

        return api_qs
    }

    processResponse(response){

        let response_qs = qs.parse(_.get(response, "links.self"), { ignoreQueryPrefix: true });

        let page_number = _.get(response_qs, "page.number", 0)
        let res_count = _.get(response, "meta.count", 0)


        if(response_qs && page_number && res_count){
          this.setState({
              maxPage: Math.max(
                  _.get(response_qs, 'page.number', 0),
                  Math.ceil(response.meta.count / this.state.pageSize)
              )
          })
        } else {
          this.setState({maxPage: 0})
        }

    }


    makeurlforpage = (pageno) => {
      return this.state.current_base + qs.stringify(Object.assign(this.state.current_qs, {page: pageno}), { addQueryPrefix: true })
    }

    linkForPage = (page_no, active=false) => {
      return (
        <PaginationItem active={active} key={page_no}>
          <PaginationLink to={this.makeurlforpage(page_no)} tag={Link}>
            {page_no}
          </PaginationLink>
        </PaginationItem>
      )
    }

    startblock = () => {

      if(this.state.currentPage <= this.pageNumberCutoff + 1) return;

      let to_return = [this.linkForPage(1)];

      if (this.state.currentPage > this.pageNumberCutoff + 3){
        to_return.push((
          <PaginationItem disabled key='dotpre'>
            <PaginationLink className="px-4">
              ...
            </PaginationLink>
          </PaginationItem>
        ))
      } else{
        for(let i = 2; i < this.state.currentPage - this.pageNumberCutoff; i++){
          to_return.push(this.linkForPage(i))
        }
      }

      return (
        <Fragment>
          {to_return}
        </Fragment>
      )
    }

    prevblock = () => {

      let to_return = [];

      for(let i = Math.max(1, this.state.currentPage - this.pageNumberCutoff); i < this.state.currentPage; i++){
          to_return.push(this.linkForPage(i))
      }

      return (
        <Fragment>
          {to_return}
        </Fragment>
      )
    }

    nextblock = () => {

      let to_return = [];

      for(let i = this.state.currentPage + 1; i <= Math.min(this.state.maxPage, this.state.currentPage + this.pageNumberCutoff); i++){
          to_return.push(this.linkForPage(i))
      }

      return (
        <Fragment>
          {to_return}
        </Fragment>
      )
    }

    endblock = () => {

      let pagesToEnd = this.state.maxPage - this.state.currentPage

      if(pagesToEnd < this.pageNumberCutoff + 1) return

      let to_return = [];

      if (pagesToEnd > this.pageNumberCutoff + 2){
        to_return.push((
          <PaginationItem disabled key='dotpost'>
            <PaginationLink href="#" className="px-4">
              ...
            </PaginationLink>
          </PaginationItem>
        ))
      } else{
        for(let i = this.state.currentPage + this.pageNumberCutoff + 1; i < this.state.maxPage; i++){
          to_return.push(this.linkForPage(i))
        }
      }

      to_return.push(this.linkForPage(this.state.maxPage))

      return (
        <Fragment>
          {to_return}
        </Fragment>
      )
    };

    handlePageSizeSelect = (e) => {
        let page_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true })
        page_qs['pageSize'] = e.target.value;
        page_qs['page'] = 1;
        window.scrollTo(0,0);
        this.props.history.push(window.location.pathname + qs.stringify(page_qs, { addQueryPrefix: true }))
    };

    componentDidMount() {
        this.props.registerResponse(this.processResponse);
        this.props.registerRequest(this.updateAPIURL);
    };

    componentWillUnmount() {
        this.props.unregisterResponse(this.processResponse);
        this.props.unregisterRequest(this.updateAPIURL);
    };


    render(){
      if(!this.state.currentPage || !this.state.maxPage) {
        return null
      }
        return (
            <div className="pagination-wrapper d-flex flex-row">
                {this.state.maxPage > 1 && (
                    <Fragment>
                        <div className="pt-2 px-2">
                            <span>
                                Page
                            </span>
                        </div>
                        <Pagination>
                            {this.startblock()}
                            {this.prevblock()}
                            {this.linkForPage(this.state.currentPage, true)}
                            {this.nextblock()}
                            {this.endblock()}
                        </Pagination>
                    </Fragment>
                )}
                <div className="ml-5 pr-2">
                    <Form inline>
                        <FormGroup>
                            <Label className="pr-2" for="exampleSelect">Page Size</Label>
                            <Input type="select" name="select" id="exampleSelect" onChange={this.handlePageSizeSelect} value={this.state.pageSize}>
                                {_.map([10, 30, 50, 100, 250], (count) => {
                                    return (<option key={count} value={count}>{count}</option>)
                                })}
                            </Input>
                        </FormGroup>
                    </Form>
                </div>
            </div>
        )
    }


}

export default withRouter(PaginationBlock);