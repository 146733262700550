import CNVIconSrc from './cnvIcon'
import DrugIconSrc from './drugIcon'
import ExomeIconSrc from './exomeIcon'
import ExprIconSrc from './exprIcon'
import PatientIconSrc from './patientIcon'
import MetIconSrc from './metIcon'
import CrisprKOIconSrc from './crisprKOIcon'
import FusionIconSrc from './fusionIcon'
import RNASeqIconSrc from './rnaseqIcon'
import ModelIconSrc from './modelIcon'
import GeneIconSrc from './geneIcon'
import ProteomicsIconSrc from './proteomicsIcon'

export const CNVIcon = CNVIconSrc;
export const DrugIcon = DrugIconSrc;
export const ExomeIcon = ExomeIconSrc;
export const ExprIcon = ExprIconSrc;
export const PatientIcon = PatientIconSrc;
export const MetIcon = MetIconSrc;
export const CrisprKOIcon = CrisprKOIconSrc;
export const FusionIcon = FusionIconSrc;
export const RNASeqIcon = RNASeqIconSrc;
export const ModelIcon = ModelIconSrc;
export const GeneIcon = GeneIconSrc;
export const ProteomicsIcon = ProteomicsIconSrc;