import React, {Fragment} from 'react';
import _ from 'lodash';

import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label } from "reactstrap";
import qs from "qs";
import {withRouter} from "react-router-dom";
import {urlify} from "../../utils";
import CollapseButton from '../dumb/collapseButton';
import baseFilter from "./baseFilter";

class CpvFilter extends baseFilter {

    constructor(props){
        super(props);
        this.renderCpvCheckboxes = this.renderCpvCheckboxes.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.updateAPIURL = this.updateAPIURL.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);

        this.state = {
            filters: {},
            isOpen: false,
            fields: ["Yes"],
            activeCount: 0
        };
        this.aggregates = {'has_cpv': 'count'}
    }

    static getDerivedStateFromProps(newprops, oldstate){
        let querystring = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        let activeFilters = CpvFilter.getActiveFiltersFromQS(querystring, 'cpv');

        let newstate = oldstate;

        for(let field of newstate.fields){
            newstate = _.merge(newstate, {filters: {[field]: {active: (activeFilters.indexOf(field) >= 0)}}})
        }

        return {
            filters: newstate.filters,
            activeCount: activeFilters.length
        }
    }

    static getActiveFiltersFromQS(qs_obj, field, defaultValue = [], sep="_"){
          return (field in qs_obj)? _.split(_.get(qs_obj, field), sep):defaultValue;
    }

    updateAPIURL(api_qs, newlistprops){
        let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
        let activeCpvFilters = CpvFilter.getActiveFiltersFromQS(new_qs, 'cpv');

        let filter_qs = {"name": "has_cpv", "op": "eq", "val": true};

        if(activeCpvFilters.length > 0){
            api_qs.filter = _.get(api_qs, 'filter', []).concat(filter_qs)

        }
        return _.merge(api_qs, {agg: this.aggregates})
    }

    getFromResponse(resp, field){
        let basePath = `meta.agg.has_cpv`;
        return _.get(resp, basePath + ".true") || _.get(resp, basePath + ".count.True")
    }

    processResponse(response){
        let newfilters = _.reduce(this.state.fields, (res, field) =>{
            res[field] = _.merge(this.state.filters[field], {count: this.getFromResponse(response, field)});
            return res
        }, {});
        this.setState({
            filters: newfilters
        })
    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    toggleCheckbox(event){
        let cpv = event.target.name;
        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let cpvFilter = ('cpv' in current_qs)? _.split(current_qs.cpv, '_'):[];

        if(event.target.checked){
            cpvFilter.push(cpv)
        } else {
              cpvFilter = cpvFilter.filter((item) => item !== cpv)
        }

        current_qs.cpv = (cpvFilter.length > 0)? _.join(cpvFilter.sort(), '_'): undefined;
        current_qs.page = undefined;
        this.props.history.push(window.location.pathname + qs.stringify(current_qs, { addQueryPrefix: true }));
    }

    renderCpvCheckboxes(){
        return (
            _.map(_.toPairs(this.state.filters), ([cpv, details]) => {
                return (
                    <FormGroup check key={cpv}>
                        <Input type="checkbox" name={cpv} onChange={this.toggleCheckbox} checked={_.get(details, 'active')}/>{' '}
                        <Label className="ml-4 mt-1" check>
                            {cpv} <span className="secondary">({_.get(details, 'count')})</span>
                        </Label>
                    </FormGroup>
                )}
            )
        )

    }

    componentDidMount() {
        this.props.registerResponse(this.processResponse);
        this.props.registerRequest(this.updateAPIURL);
    };

    componentWillUnmount() {
        this.props.unregisterResponse(this.processResponse);
        this.props.unregisterRequest(this.updateAPIURL);
    };

    render(){
        return(
            <Card className="genetic-filters mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline w-50">Cancer Predisposition Variants</div>
                    <div className="d-inline-block">
                        {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        {this.renderCpvCheckboxes()}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(CpvFilter)
