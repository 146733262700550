import React, {Component} from 'react';
import {Link, NavLink as RRNavLink} from 'react-router-dom';
import {
    Navbar,
    NavbarBrand,
    NavItem,
    Nav,
    NavLink,
    NavbarToggler,
    Collapse
} from 'reactstrap';
import HomeSearch from "../dumb/homeSearch";
import sangerLogo from "../../assets/img/wellcomesangerinstitutelogo1-800x275.png"
import cmpLogo from "../../assets/img/CMP_Purple.svg"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { faCaretUp } from '@fortawesome/free-solid-svg-icons';

const openIcon = <span><FontAwesomeIcon icon={faCaretDown}/></span>
const closeIcon = <span><FontAwesomeIcon icon={faCaretUp}/></span>


class Header extends Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.state = {
            isOpen: false
        };
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (

            <Navbar className="navbar-expand-xl navbar-light bg-primary align-items-center">
                <div className="d-flex justify-content-center col-10 col-md-5 col-lg-4">

                    <NavbarBrand tag={Link} to="/" className="mb-2 ml-1 pl-0">
                        <img src={cmpLogo} height="50" alt="Cell Model Passports" />
                    </NavbarBrand>
                    <div className="header-search d-none d-md-block">
                        <HomeSearch placeholder="Search..." />
                    </div>
                </div>
                <NavbarToggler className="d-md-none navbar-toggler ml-auto custom-toggler"  onClick={this.toggle}/>
                <NavbarToggler className="d-none d-md-inline-block d-xl-none menu-btn-lg"  onClick={this.toggle}>Menu {this.state.isOpen?closeIcon:openIcon}</NavbarToggler>


                <Collapse className="navbar-collapse justify-content-center" id="navbarNav"
                          isOpen={this.state.isOpen}>

                    <div className="header-search d-block d-md-none">
                        <HomeSearch placeholder="Search..." />
                    </div>
                    <Nav navbar className="ml-3 pl-1 mr-3">
                        <NavItem active={window.location.href.indexOf("/passports") >= 0 }>
                            <NavLink tag={RRNavLink} to="/passports" className="mb-2">Browse Models</NavLink>
                        </NavItem>
                        <NavItem active={window.location.href.indexOf("/downloads") >= 0 } className="ml-xl-3">
                            <NavLink tag={RRNavLink} to="/downloads" className="mb-2">Datasets & Downloads</NavLink>
                        </NavItem>
                        <NavItem active={window.location.href.indexOf("/documentation") >= 0 } className="ml-xl-3">
                            <NavLink as="a" href="https://depmap.sanger.ac.uk/documentation/" target="_blank" rel="noopener noreferrer" className="mb-2">Documentation</NavLink>
                        </NavItem>
                        <NavItem active={window.location.href.indexOf("/changes") >= 0 } className="ml-xl-3">
                            <NavLink tag={RRNavLink} to="/changes" className="mb-2">Change Log</NavLink>
                        </NavItem>
                        <NavItem active={window.location.href.indexOf("/tutorials") >= 0 } className="ml-xl-3">
                            <NavLink tag={RRNavLink} to="/tutorials" className="mb-2">Tutorials</NavLink>
                        </NavItem>
                    </Nav>
                </Collapse>
                <div className="d-flex justify-content-center col-10 col-md-3 col-lg-2">
                <NavbarBrand className="ml-1 pl-0 d-none d-sm-block">
                        <a target="_blank" rel="noopener noreferrer" href="https://www.sanger.ac.uk/"><img src={sangerLogo} height="50" alt="Wellcome Sanger Institute"/></a>
                </NavbarBrand>
                </div>
            </Navbar>
        )
    }
}

export default Header;