import React, {Fragment} from 'react';
import {Col, Row} from "reactstrap";

import org_img from '../../../../assets/img/Model_Organoids.jpg'
import cell_img from '../../../../assets/img/Models_CellLines.jpg'

export default function(){
    return(
        <Fragment>
            <h1>Models Overview</h1>
            <p>
                Two model types are currently represented in the passports: Cell Lines and Organoids.
            </p>
            <Row className="mb-3">
                <Col sm={6}>
                    <h5>Cell Lines</h5>
                    <img src={cell_img} className="w-100 mb-3" alt="Cell line in culture"/>
                    <p className="small text-justify">
                        Cancer cell lines have been generated over many decades and used extensively by the research community.
                        They are amenable to many assays and applications and can be easily maintained within the laboratory.
                    </p>
                </Col>
                <Col sm={6}>
                    <h5>Organoids</h5>
                    <img src={org_img} className="w-100 mb-3" alt="Organoid in culture"/>
                    <p className="small text-justify">
                        Organoids are a long-term culture system which allow cells to be grown in specialised three dimensional
                        culture conditions that maintain the stem cell population as well as much of the cell/tissue
                        architecture found in vivo. Cancer organoid models have been shown to recapitulate features of the
                        original tumour and can model clinically relevant drug responses. Newly derived organoids also aim to
                        overcome many of the limitations associated with traditional cell lines such as limited clinical data
                        and lack of associated normal samples that can empower genomic analyses.

                    </p>
                </Col>
            </Row>
            <p>Cross-contamination and mis-identification are obstacles facing researchers when working with cell
                models. The Cell Model Passports provides users with detailed hierarchical annotation grouping models
                belonging to the same individual or sample. This information has been assembled using data acquired
                from cell banks, community resources, publications and genomic analyses.
            </p>

            <p>Unique identifiers utilised by other resources such as Research Resource Identifiers (RRID),
                the Catalogue of Somatic Mutations in Cancer (COSMIC) and Cancer Cell Line Encyclopedia
                (CCLE) have been associated with the cell models facilitating integration.

            </p>
            <br />
            <h5>Model Curation</h5>
            <p>
                Model annotation is compiled from cell banks, academic collaborators, external databases and
                publications. In order to present model data most effectively a constrained vocabulary was developed
                to consolidate model descriptors (see <a href="/documentation/models/glossary">Glossary of Terms</a>).
            </p><p>  Model relationships are constructed by combining information obtained during annotation with data
                obtained from model identification resources such as International Cell Line Authentication Committee
            (<a href="http://iclac.org/">ICLAC</a>) and additional publications. In addition STR profiles obtained during model curation or
                internally at the Sanger Institute are compared to inform model-patient associations. The Cell Model
                Passports conservatively assigns models sharing common ancestry to separate samples from a patient
                unless evidence indicates otherwise.

            </p>
            <br />
        </Fragment>
    )
}