import React, {Fragment} from 'react';

export default function(){
    return(
        <Fragment>
            <h1>General</h1>
            <p>
               Multiple datasets exist within the Cell Model Passport covering a variety of genomic characterisations that will be expanded to in the future. The website provides processed data accessible through the downloads or via the API, in addition links to the raw data enable users to independently analyse each dataset.
            </p>
            <p>
              Table of data sets and key information.
            </p>
            <table border="1" className="general">
            <tbody>
              <tr><th>Models</th><th align="centre">Dataset</th><th>Type</th><th>Details</th><th>Data/Link</th></tr>
              <tr><td>Cell Lines</td><td>Whole Exome Sequencing</td><td>BAM</td><td>Illumina HiSeq 2000</td><td><a href="https://www.ebi.ac.uk/ega/studies/EGAS00001000978">EGAS00001000978</a></td></tr>
              <tr><td>Cell Lines</td><td>Copy Number Variation</td><td></td><td>Affymetrix SNP6</td><td><a href="https://www.ebi.ac.uk/ega/studies/EGAS00001000978">EGAS00001000978</a></td></tr>         
              <tr><td>Cell Lines</td><td>Expression</td><td>CEL</td><td>Affymetrix Human Genome U219 Array</td><td><a href="https://www.ebi.ac.uk/arrayexpress/experiments/E-MTAB-3610/">E-MTAB-3610</a></td></tr>
               <tr><td>Cell Lines</td><td>Methylation</td><td>TAR (of IDAT)</td><td>Illumina Human Methylation 450 BeadChip</td><td><a href="http://www.ncbi.nlm.nih.gov/geo/query/acc.cgi?acc=GSE68379">GSE68379</a></td></tr>
             </tbody>
            </table>
            <p></p>
            <p>
                The cell line raw genomic data is available from the listed databases and is described in Iorio et al. A Landscape of Pharmacogenomic
                Interactions in Cancer. Cell, 2016.

            </p>
        </Fragment>
    )
}