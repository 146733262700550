import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import {Card, CardBody} from "reactstrap";

import ModelListItem from "./modelListItem";
import ErrorBoundary from "../errorBoundary";
import Loading from '../dumb/loading'


class ModelList extends Component{


    render(){
        return(
            <Fragment>
                <Card className="model-list">
                    <CardBody className="pt-2">
                        {this.props.loading?(
                            <Loading subtitle="Loading model list" size="big"/>
                        ):(
                            (this.props.models.length > 0)? (
                                _.map(this.props.models, (model) => {
                                    return(
                                        <ErrorBoundary key={model.id}>
                                            <ModelListItem key={model.id} model={model} />
                                        </ErrorBoundary>
                                    )

                                })):
                                (<p>No cell models found.</p>)
                            )

                        }
                    </CardBody>
                </Card>

            </Fragment>
        )
    }
}


export default ModelList
