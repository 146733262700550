import React, { Component } from 'react';
import _ from 'lodash';
import qs from "qs";
import {withRouter} from "react-router-dom";
import {Card, CardBody, CardHeader, Collapse} from "reactstrap";

import GeneticFilterAutocomplete from '../dumb/geneticFilter/geneticFilterAutocomplete';
import {removeValueFromArray} from "../../utils";
import CollapseButton from '../dumb/collapseButton';

class GeneticFilter extends Component {

    constructor(props){
        super(props);
        this.renderActiveGeneFilters = this.renderActiveGeneFilters.bind(this);
        this.renderGeneSelect = this.renderGeneSelect.bind(this);
        this.handleGeneSelect = this.handleGeneSelect.bind(this);
        this.handleGeneRemove = this.handleGeneRemove.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);

        this.props.registerRequest(this.updateAPIURL);

        this.state = {
            value: '',
            selectedGenes: [],
            isOpen: false,
            activeCount: 0
        }

    }

    static getDerivedStateFromProps(newprops){
        let new_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        let selectedGenes = ('mutated' in new_qs)? _.split(new_qs.mutated, '_'):[];
        return {
            selectedGenes: selectedGenes,
            activeCount: selectedGenes.length
        }

    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    updateAPIURL(api_qs, newlistprops){

        let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
        let mutatedFilterGenes = ('mutated' in new_qs)? _.split(new_qs.mutated, '_'):[];


        if(mutatedFilterGenes.length > 0){
            for(let gene of mutatedFilterGenes){

                let filter_qs = {"name":"mutations","op":"any","val":{"and":[
                        {"name":"gene","op":"has","val":{"name":"symbol","op":"eq","val":gene}},
                        {"name":"cancer_driver","op":"eq","val":true}

                    ]}}
                if('filter' in api_qs){
                    api_qs.filter.push(filter_qs)
                } else {
                    api_qs['filter'] = [filter_qs]
                }
            }

        }

        return api_qs

    }


    handleGeneSelect(gene){

        let geneSymbol = gene.symbol

        let newGeneList = this.state.selectedGenes.concat(geneSymbol);

        this.updateURLWithNewGeneList(newGeneList)

    }

    handleGeneRemove(geneLink){
        let geneSymbol = geneLink.target.dataset.symbol;

        let newGeneList = removeValueFromArray(geneSymbol, this.state.selectedGenes);

        this.updateURLWithNewGeneList(newGeneList)

    }

    updateURLWithNewGeneList(newGeneList){

        let newQueryString = this.getQueryStringWithUpdatedGeneList(newGeneList);
        this.updateURLWithNewQueryString(newQueryString)
    }

    getQueryStringWithUpdatedGeneList(newlist){
        let current_qs = this.getCurrentQueryStringObject();

        return this.updateMutatedGenesInQueryString(newlist, current_qs);
    }

    getCurrentQueryStringObject(){
        return qs.parse(window.location.search, { ignoreQueryPrefix: true });
    }

    updateMutatedGenesInQueryString(newlist, querystring){
        if(newlist.length > 0) {
            querystring.mutated = _.join(newlist.sort(), '_');
        } else {
            querystring.mutated = undefined;
        }

        return querystring;
    }

    updateURLWithNewQueryString(querystring){
        this.props.history.push(window.location.pathname + qs.stringify(querystring, { addQueryPrefix: true }));
    }

    renderGeneSelect(){
        return(
            <GeneticFilterAutocomplete api_base_url={this.props.api_base_url} onSelect={this.handleGeneSelect} />
        )


    }

    renderActiveGeneFilters(){

        return(
                <div className="mt-3">
                    {this.state.selectedGenes.length === 1 && (
                        <span className="small">Only show models with a driver mutation in</span> )}
                    {this.state.selectedGenes.length > 1 && (
                        <span className="small">Showing models with a mutation <em>all</em> of these genes:</span> )}
                    {_.map(this.state.selectedGenes, (gene) => {
                        return <div key={gene}>{gene} <span onClick={this.handleGeneRemove} data-symbol={gene} className="small gene remove">remove</span></div>
                        }
                    )}
                </div>
        )


    }

    render(){
        return(
            <Card className="genetic-filters mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline w-50" >Mutations</div>
                    <div className="d-inline-block">
                        {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        { this.renderGeneSelect() }
                        { this.renderActiveGeneFilters() }
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(GeneticFilter)
