import React, { Component } from 'react';
import {Breadcrumb, BreadcrumbItem, Container, Row} from "reactstrap";
import ModelInfo from './sub_components/ModelInfo';
import ModelIdentifier from './sub_components/ModelIdentifier';
import RelatedModels from './sub_components/RelatedModels';
import ModelDataset from './sub_components/ModelDataset';
import ModelCancerDrivers from './sub_components/ModelCancerDrivers';
import ModelMutations from './sub_components/ModelMutations';
import ModelCrisprKO from './sub_components/ModelCrisprKO';
import DrugData from './sub_components/DrugData';
import ModelRNASeq from './sub_components/ModelRNASeq';
import ModelProteomics from './sub_components/ModelProteomics';

import {api_base_url} from "../../settings";
import _ from 'lodash';
import Deserialiser from 'deserialise-jsonapi'
import Loading from '../dumb/loading'
import ModelFusions from "./sub_components/ModelFusions";
import {Link} from "react-router-dom";

const des = new Deserialiser();

class CellModelPage extends Component {
    constructor(props){
        super(props);
        this.state = {
            model: {}
        };
        this.updateModel = this.updateModel.bind(this)
        this.setDrugLink = this.setDrugLink.bind(this)
    }

    updateModel(){
        const modelId = this.props.match.params.modelId;
        const hcmiId = this.props.match.params.hcmiId;
        let url = api_base_url;
        if(modelId !== undefined){
            url = api_base_url + '/models/' + modelId + '?include=sample.patient,sample.tissue,sample.cancer_type,sample.ncit,files.dataset,identifiers.source,model_msi_status,growth_rates';
        }
        console.log(url);
        if(hcmiId !== undefined){
            url = api_base_url + '/models/HCMI/' + hcmiId + '?include=sample.patient,sample.tissue,sample.cancer_type,sample.ncit,files.dataset,identifiers.source,model_msi_status,growth_rates';
        }

        fetch(url)
            .then(response => response.json())
            .then(response_obj => {
               if(response_obj.data){
                   return des.deserialise(response_obj)
               }else{
                   return
               }
            })
            .then(model => {
                if(model){
                    this.setState({
                        model: model,
                        patient_id: model.sample.patient.id,
                        muts_avail: model.mutations_available,
                        load_complete: 1
                    });
                    this.props.history.replace({ pathname: `/passports/${this.state.model.id}`})
                    }else{
                        this.setState({
                            load_complete: 1
                        });
                    }
            })
            .catch(function (ex) {
                console.log('parsing failed', ex);
            });
    }

    componentDidUpdate() {
        if(this.props.match.params.modelId && this.props.match.params.modelId !== _.get(this.state, 'model.id')){
            this.updateModel()
        }
    }
    componentDidMount(){
        this.updateModel()
    }

    setDrugLink(drug_link){
        this.setState({drug_link: drug_link})
    }

    render() {
       if(!this.state.load_complete ){
            return (<div className="py-5" > <Loading subtitle="Loading model" /> </div>)
        }else if(this.state.load_complete && this.state.model && _.isEmpty(this.state.model) && _.isEmpty(this.state.patient_id) ){
            return (<div className="py-5 b_msg" >No such model </div>)
        }
        return (
            <Container className="bg-faded pt-2 pb-5" fluid>
                <Breadcrumb className="ml-3 pl-1 mt-1">
                    <span>You are here: </span>
                    <BreadcrumbItem className="pl-1" ><Link to="/">Home</Link></BreadcrumbItem>
                    <BreadcrumbItem><Link to="/passports">Browse Models</Link></BreadcrumbItem>
                    <BreadcrumbItem active>{this.state.model.names[0]}</BreadcrumbItem>
                  </Breadcrumb>

                <Row>
                    <ModelInfo model={this.state.model}/>
                    <ModelCancerDrivers modelId={this.state.model.id} mutsAvail={this.state.model.mutations_available} />
                    <ModelMutations model={this.state.model}/>
                    <ModelCrisprKO model={this.state.model} />
                    <DrugData setDrugLink={this.setDrugLink} model={this.state.model} />
                    <ModelRNASeq model={this.state.model}/>
                    <ModelProteomics model={this.state.model}/>
                    <ModelFusions model={this.state.model}/>
                    <ModelDataset model={this.state.model} drug_link={this.state.drug_link}/>
                    <RelatedModels model={this.state.model} modelId={this.props.match.params.modelId} patientId={this.state.patient_id}/>
                    <ModelIdentifier model={this.state.model} suppliers={false} header="Identifiers" />
                    <ModelIdentifier model={this.state.model} suppliers={true} header="Suppliers" />
                </Row>
            </Container>
        );
    }
}

export default CellModelPage;
