import React, {Component} from 'react';
import qs from 'qs'

import Filters from '../filters/filters';
import ClinicalFilters from '../filters/clinicalFilters';
import CellModelList from '../model-list/modelList';
import ModelListHeader from '../model-list/modelListHeader';
import ModelListFooter from '../model-list/modelListFooter';
import {Col, Container, Row} from "reactstrap";
import {api_base_url} from "../../settings";
import {alphabetically, api_fetch} from "../../utils"

import Deserialiser from 'deserialise-jsonapi'
import {Link} from "react-router-dom";

const des = new Deserialiser();

class Passports extends Component {

    constructor(props){
      super(props);
      this.fetchModels.bind(this);
      this.registerRequestCallback = this.registerRequestCallback.bind(this);
      this.registerResponseCallback = this.registerResponseCallback.bind(this);
      this.unregisterRequestCallback = this.unregisterRequestCallback.bind(this);
      this.unregisterResponseCallback = this.unregisterResponseCallback.bind(this);
      this.state = {
          requestCallbacks: [],
          responseCallbacks: []
      };
      this.requestCallbacks = [];
      this.responseCallbacks = [];
    }

    // Static (class) methods
    // flow: change in props (url) -> change state -> rerender

    static getDerivedStateFromProps(props, state){
      return Passports.updatedStateFromURL(props, state);
    }

    static defaultState = {
        models: [],
        requestCallbacks: [],
        responseCallbacks: []
    };

    static updatedStateFromURL(newprops, oldstate) {

      // Take the existing state of the passports page,
      // update it based on the new props.
      // if the api_url has changed, the component will re-fetch

      // if the old state is an empty object, use the defaultState as oldstate
      if(Object.keys(oldstate).length === 0){
        oldstate = Passports.defaultState;
      }

      // Build a new state from the old state + parsing the url
      let newstate = {
          models: oldstate.models || [],
          requestCallbacks: oldstate.requestCallbacks,
          responseCallbacks: oldstate.responseCallbacks
      };

      // store the url in the new state
      newstate.apiurl = Passports.generateAPIURLFromState(newprops, newstate)
      console.log(newstate.apiurl);
      // store a flag whether a reload is required
      newstate.load_models = oldstate.apiurl !== newstate.apiurl

      return newstate

    }

    static generateAPIURLFromState(newprops, newstate){
      let api_qs = {};
      api_qs.include = "sample.patient,sample.tissue,sample.cancer_type,sample.ncit,model_msi_status,identifiers.source"

      api_qs["fields[model]"]='names,sample,model_type,mutations_available,cnv_available,drugs_available,methylation_available,expression_available,crispr_ko_available,fusions_available,proteomics_available,ploidy,ploidy_wgs,ploidy_wes,mutations_per_mb,model_msi_status,commercial_available,identifiers'

      api_qs["fields[sample]"]='tissue,cancer_type,ncit,tissue_status,age_at_sampling,patient,sample_site,tnm_integrated,metastasis_at_diagnosis,prior_treatment,sample_treatment'
      api_qs["fields[patient]"]='ethnicity,gender,alcohol_exposure_intensity,smoking_status,prior_malignancies'
      api_qs["fields[tissue]"]='name'
      api_qs["fields[model_msi_status]"]='msi_status,current'
      api_qs["fields[cancer_type]"]='name'

      newstate.requestCallbacks.forEach((fun) => {
          if (typeof fun === 'function') {
              api_qs = fun(api_qs, newprops, newstate)
          }
      });


      let new_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
      let query = ('q' in new_qs)? new_qs.q: "";
      api_qs['q'] = (query.length > 0)? query: undefined;


      api_qs.agg = api_qs.agg? JSON.stringify(api_qs.agg): undefined;
      api_qs.filter = api_qs.filter? JSON.stringify(api_qs.filter): undefined;
      api_qs.sort = api_qs.sort || "names";
      let api_url = api_base_url + "/models"
      console.log(api_url + qs.stringify(api_qs, { depth: 0, addQueryPrefix: true, sort: alphabetically }))
      return api_url + qs.stringify(api_qs, { depth: 0, addQueryPrefix: true, sort: alphabetically })
    }

    registerRequestCallback = (requestCallback) => {
        this.requestCallbacks.push(requestCallback)

    };
    unregisterRequestCallback = (requestCallback) => {

        let ix = this.requestCallbacks.indexOf(requestCallback);
        if (ix > -1) {
            this.requestCallbacks.splice(ix, 1);
        }

    };

    registerResponseCallback = (responseCallback) => {
        this.responseCallbacks.push(responseCallback)

    };

    unregisterResponseCallback = (responseCallback) => {
        let ix = this.responseCallbacks.indexOf(responseCallback);
        if (ix > -1) {
            this.responseCallbacks.splice(ix, 1);
        }

    };

    fetchModels(){

        let api_res = api_fetch(this.state.apiurl)
            .then(response => response.json())
            .then(data => {
                if(data.meta.count > 0){
                this.state.responseCallbacks.forEach(fun => fun(data));
                }
                return des.deserialise(data)
            })
            .then(models => {

            // build a new state using the api response

                this.setState({
                    models: models,
                    load_models: false
                })
            })
            .catch((err) => {
                this.setState({
                    models: [],
                    load_models: false
                })
            })

      return api_res

    }

    componentDidUpdate(prevProps) {
      if (this.state.load_models){
        this.fetchModels()
      }
    }

    componentDidMount(){
        this.setState({
            requestCallbacks: this.requestCallbacks,
            responseCallbacks: this.responseCallbacks
        })

        // Avoid premature loading. Uncomment if this causes bugs
        // this.fetchModels()
    }

    componentWillUnmount(){
        this.setState({
            requestCallbacks: [],
            responseCallbacks: []
        })
    }


    render(){
        return(
            <Container className="bg-faded z0" fluid>
                <Row className="">
                    <Col md="12" lg="3" xl="3" className="bg-white filter-sidebar py-5">
                        <div className="sticky-top">
                            <div className="d-flex flex-row justify-content-between">
                                <h4 className="bold ml-3 pl-1">Model list filters</h4>
                                <Link to="/passports" className="small mr-3 pr-1">reset all</Link>
                            </div>
                            <Filters
                                registerRequest={this.registerRequestCallback}
                                registerResponse={this.registerResponseCallback}
                                unregisterRequest={this.unregisterRequestCallback}
                                unregisterResponse={this.unregisterResponseCallback}/>
                                <br/><br/>
                            <div className="d-flex flex-row justify-content-between">
                                <h4 className="bold ml-3 pl-1">Clinical data filters</h4>
                            </div>
                            <ClinicalFilters
                                registerRequest={this.registerRequestCallback}
                                registerResponse={this.registerResponseCallback}
                                unregisterRequest={this.unregisterRequestCallback}
                                unregisterResponse={this.unregisterResponseCallback}/>
                        </div>
                    </Col>
                    <Col className="py-4">
                        <ModelListHeader
                            registerRequest={this.registerRequestCallback}
                            registerResponse={this.registerResponseCallback}
                            unregisterRequest={this.unregisterRequestCallback}
                            unregisterResponse={this.unregisterResponseCallback}
                            models={this.state.models.length}
                        />
                        <CellModelList models={this.state.models} loading={this.state.load_models} />

                        <ModelListFooter
                            registerRequest={this.registerRequestCallback}
                            registerResponse={this.registerResponseCallback}
                            unregisterRequest={this.unregisterRequestCallback}
                            unregisterResponse={this.unregisterResponseCallback}
                        />

                    </Col>
                </Row>
            </Container>
            )
    }

}

export default Passports
