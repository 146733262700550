import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Button } from "reactstrap";

import {flatten} from 'flat';

const downloadIcon = <span className="fa-wrapper mr-2 pt-1"><FontAwesomeIcon icon={faFileExcel}/></span>

export default (props) => {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const sheetName = props.sheetName || 'data'


    const exportToXLSX = (tableData, filename) => {
        const to_save = tableData.map((row) => flatten(row, {delimiter: "_"}));
        const ws = XLSX.utils.json_to_sheet(to_save);
        const wb = { Sheets: { 'data': ws }, SheetNames: [sheetName] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], {type: fileType});
        FileSaver.saveAs(data, filename + fileExtension);
    }


    return (
        <Button className="btn-link secondary" onClick={(e) => exportToXLSX(props.data, props.filename)} >
            Export XLSX {downloadIcon}
        </Button>
    )
}
