import React, {Component} from 'react';
import {
    Col,
    Card,
    CardBody,
    CardHeader, Table,
} from "reactstrap";
import {api_base_url} from "../../../settings";
import Deserialiser from 'deserialise-jsonapi'
import Loading from '../../dumb/loading'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import filterFactory, {
    selectFilter,
    textFilter,
} from 'react-bootstrap-table2-filter';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

import _ from 'lodash'


import ExportCSVButton from '../../dumb/fancyTable/ExportCSVButton'
import {paginationOptions} from '../../dumb/fancyTable/Pagination'
import {ColumnToggle} from "../../dumb/fancyTable/columnToggle";
import ColumnHeader from "../../dumb/fancyTable/columnHeader";
import ExportXLSXButton from "../../dumb/fancyTable/ExportXLSXButton";

const des = new Deserialiser();

class ModelFusions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColumns: 5,
            model: props.model
        };

        this.wideBoxThreshold = 5;

        this.loadFusions = this.loadFusions.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.onColumnToggleHandler = this.onColumnToggleHandler.bind(this);
    }


    onColumnToggleHandler(activeColumns) {
        this.setState({activeColumns});
    }

    loadFusions() {
        const model_id = this.state.model.id;

        const url = `${api_base_url}/models/${model_id}/datasets/fusions?include=gene_3prime,gene_5prime&page[size]=0&fields[gene]=symbol,tumour_suppressor&sort=-in_cosmic_fusions,-in_patient`;

        fetch(url)
            .then(response => response.json())
            .then(resp_obj => des.deserialise(resp_obj))
            .then(fusions => {

                    let new_fusions = _.reduce(fusions, (res, value) => {

                        let key = `${value.gene_5prime.id}_${value.gene_3prime.id}`

                        let breakpoints = _.get(res, `${key}.breakpoints`, []);
                        breakpoints.push(_.pick(value, ['bp_pos_5prime', 'bp_pos_3prime', 'in_frame', 'num_splitting_reads', 'pcr_validation']));

                        let fusion_obj = _.pick(value, ['id', 'chr_3prime', 'chr_5prime', 'in_cosmic_fusions', 'in_patient', 'gene_5prime', 'gene_3prime']);

                        fusion_obj.breakpoints = breakpoints

                        res[key] = fusion_obj;

                        return res
                    }, {})

                    this.setState({
                        fusions: _.values(new_fusions),
                        load_complete: 1
                    })
                }
            ).catch((ex) => {
            console.log('parsing failed', ex);
        });
    }

    renderTable() {
        const booleanOptions = {
            true: 'Yes',
            false: 'No',
        };

        const columns = [
            {
                dataField: 'gene_5prime.symbol',
                text: "5' gene symbol",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Gene at 5' end of fusion"
            }, {
                dataField: 'chr_5prime',
                text: "5' chromosome",
                hidden: true,
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Chromosome of 5' gene"
            }, {
                dataField: 'gene_5prime.tumour_suppressor',
                text: "5' TSG",
                hidden: true,
                formatter: cell => booleanOptions[cell],
                filter: selectFilter({placeholder: ' ', options: booleanOptions}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "5' gene is tumour suppressor"
            }, {
                dataField: 'gene_3prime.symbol',
                text: "3' gene symbol",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Gene at 3' end of fusion"
            }, {
                dataField: 'chr_3prime',
                text: "3' chromosome",
                hidden: true,
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Chromosome of 3' gene"
            }, {
                dataField: 'gene_3prime.tumour_suppressor',
                text: "3' TSG",
                hidden: true,
                formatter: cell => booleanOptions[cell],
                filter: selectFilter({placeholder: ' ', options: booleanOptions}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "3' gene is tumour suppressor"
            }, {
                dataField: 'in_cosmic_fusions',
                text: 'COSMIC',
                formatter: cell => booleanOptions[cell],
                filter: selectFilter({
                    placeholder: ' ',
                    options: booleanOptions
                }),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Fusion is present in COSMIC fusion list",
            }, {
                dataField: 'in_patient',
                text: 'Patients',
                formatter: cell => booleanOptions[cell],
                filter: selectFilter({
                    placeholder: ' ',
                    options: booleanOptions
                }),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Fusion is found in patient samples (Gao et al., 2018)"
            },
        ];

        const expandRow = {
            renderer: (row, thing, thing2) => {
                console.log(row, thing, thing2)

                return (
                    <div>
                        <p>
                            Fusion {row.gene_5prime.symbol} - {row.gene_3prime.symbol} has <strong>{row.breakpoints.length} predicted breakpoint{(row.breakpoints.length > 1)?"s":""}</strong>
                        </p>
                        <Table bordered size={'sm'} ><thead><tr className="bold"><td>5' Position</td><td> 3' Position</td><td>In frame</td><td> Number of splitting reads</td><td>PCR Validated</td></tr></thead>
                            <tbody>
                                {_.map(row.breakpoints, (bp) => {
                                    return (<tr key={`${bp.bp_pos_5prime}_${bp.bp_pos_3prime}`} >
                                        <td>chr{row.chr_5prime}:{bp.bp_pos_5prime}</td>
                                        <td>chr{row.chr_3prime}:{bp.bp_pos_3prime}</td>
                                        <td>{bp.in_frame}</td>
                                        <td>{bp.num_splitting_reads}</td>
                                        <td>{bp.pcr_validation}</td>
                                    </tr>)
                                })}
                            </tbody>
                        </Table>
                    </div>
                )
            }

        }

        return (

            <ToolkitProvider
                keyField="id"
                data={this.state.fusions}
                columns={columns}
                columnToggle
                exportCSV={{fileName: `CMP_${this.state.model.names[0]}_fusions.csv`}}
            >
                {
                    props => (
                        <div>
                            <div className="d-flex justify-content-between">

                                <ColumnToggle {...props.columnToggleProps}
                                              columnToggleCallback={this.onColumnToggleHandler}/>
                                <div className="d-flex justifiy-content-end">
                                    <ExportCSVButton {...props.csvProps} />
                                    <ExportXLSXButton data={this.state.fusions} filename={`CMP_${this.state.model.names[0]}_fusions`} />
                                </div>
                            </div>
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                hover
                                keyField='id'
                                bordered={false}
                                pagination={this.state.fusions.length > 5 ? paginationFactory(paginationOptions(5, this.state.fusions.length)) : null}
                                filter={filterFactory()}
                                tableHeaderClass="border-top-none"
                                expandRow={ expandRow }
                            />
                        </div>
                    )
                }
            </ToolkitProvider>


        )
    }

    componentDidMount() {
        this.loadFusions()
    }

    componentDidUpdate() {
        if (this.props.model.id !== this.state.model.id) {
            this.setState({model: this.props.model, load_complete: false}, this.loadFusions)
        }
    }

    render() {
        if (!this.state.model.fusions_available) return null;

        let content = null;


        if (!this.state.load_complete) {
            content = (
                <div className="py-5"><Loading subtitle="Loading Gene Fusions"/></div>);
        } else {
            content = this.renderTable()
        }
        return (
            <Col className="my-3" lg={(this.state.activeColumns > this.wideBoxThreshold) ? 12 : 6}
                 xl={(this.state.activeColumns > this.wideBoxThreshold) ? 12 : 6}>
                <Card className="flex-fill models fusions">
                    <CardHeader>Gene Fusions</CardHeader>
                    <CardBody>
                        {content}
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default ModelFusions;

