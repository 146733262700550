import React, {Fragment} from 'react';
import pi_organoid from "../../../../assets/img/OrganoidModels.jpg";
import drugs from "../../../../assets/img/GDSC-right.jpg";
import {Button, Card, CardBody, CardDeck, CardHeader, CardImg} from "reactstrap";
import depmap_bg from '../../../../assets/img/depmap_background.png';
import {Col, Row} from "reactstrap";
import DatasetAvailabilityChart from "../../../charts/datasetAvailabilityChart";

export default function(){
    return(
        <Fragment>
            <h1>About</h1>
            <p>
                The Cell Model Passports provides manual and programmatic access to a cancer cell model database containing curated patient,
                sample and model relationship information as well as genomic and functional datasets.
            </p>
            <p>Over 1200 cancer cell models have been incorporated including those utilised by:
            </p>
            <CardDeck className="p-2 p-md-0 mb-5">
                <Card className="sanger">
                    <CardHeader>
                        Cancer Dependency Map @ Sanger
                    </CardHeader>
                        <CardImg top width="100%" src={depmap_bg} />
                    <CardBody className="pb-3">
                        <p>
                            Aims to identify all vulnernerabilities in every cancer cell.
                        </p>
                        <Button
                          href="https://depmap.sanger.ac.uk"
                          className="z1 hover"
                          target="_blank">
                            View Sanger DepMap
                        </Button>
                    </CardBody>
                </Card>
                <Card className="drugs">
                    <CardHeader>Genomics of Drug Sensitivity in Cancer</CardHeader>
                    <CardImg top width="100%" src={drugs} />
                    <CardBody className="pb-3">
                        <p>
                            Identifying molecular features of cancers to predict drug response.<br/>
                        </p>
                        <Button
                          href="https://www.cancerrxgene.org/"
                          className="z1 hover"
                          target="_blank" rel="noopener noreferrer">
                            Go To CancerRxGene.org
                        </Button>
                    </CardBody>
                </Card>
                <Card className="models">
                    <CardHeader>Cancer Cell Model Hub</CardHeader>
                    <div className="credit-wrapper">
                        <CardImg top width="100%" src={pi_organoid} alt="Wellcome Images (CC BY 4.0)" />
                        <p className="credit">image credit: Khuloud et al. <a href="https://wellcomeimages.org/">Wellcome Images</a></p>
                    </div>
                    <CardBody className="pb-3">
                        <p>
                            Deriving ~1000 cancer cell models with genomic and clinical data.
                        </p>
                        <Button
                          href="http://www.sanger.ac.uk/science/collaboration/human-cancer-model-initiative-hcmi"
                          className="z1 hover"
                          target="_blank" rel="noopener noreferrer">
                            Go To HCMI
                        </Button>
                    </CardBody>
                </Card>
            </CardDeck>

            <p>Large scale genomic datasets exist for the majority of models in the database with over
                85% having associated somatic nucleotide variant, gene expression, copy number variation
                or methylation data. Links to raw data are provided and direct downloads of processed
                genomic data enable and improve accessibility for non-computational, wet-lab scientists.
            </p>
            <br />
            <Row className="my-4">
                <Col>
                    <h5>Dataset availability</h5>
                    <DatasetAvailabilityChart />
                </Col>
            </Row>
            <p>The Cell Model Passports exploits these genomic and functional datasets to provide users
                with additional model information. Genomic characteristics such as microsatellite
                stability status, ploidy, mutational burden are reported in addition to top drug
                sensitivities determined from the GDSC project.
            </p>
            <p>
                As an evolving platform the Cell Model Passports is designed to incorporate new datasets and models.
                This will include organoids derived at the Sanger as part of the Human Cancer Model Initiative and
                datasets such as whole-genome CRISPR-knockout screens generated for the Sanger Cancer Dependency Map.

            </p>


            <p>
                <strong>Citation:</strong> van der Meer, D; Barthorpe, S; Yang, W; Lightfoot, H; Hall, C; Gilbert, G, Francies, HE;
                Garnett, MJ  Cell Model Passports—a hub for clinical, genetic and functional datasets of preclinical cancer models,
                <em>Nucleic Acids Research</em>, gky872, <a href="https://doi.org/10.1093/nar/gky872">https://doi.org/10.1093/nar/gky872</a>
            </p>
            <br />
        </Fragment>
    )
}