import React, {Component} from 'react';
import {Button, Col, Row} from "reactstrap";
import {CNVIcon, DrugIcon, ExomeIcon, RNASeqIcon, MetIcon, CrisprKOIcon, FusionIcon, ProteomicsIcon} from "../dumb/icons/icons";
import DatasetList from '../dumb/model-list/datasetsList';
import {Link} from "react-router-dom";
import _ from 'lodash';

class ModelListItem extends Component {

    constructor(props){
        super(props);
        this.state = {isOpen: false};
        this.toggle = this.toggle.bind(this)
    }

    toggle = () => this.setState({isOpen: !this.state.isOpen});

    renderClosedRow(){
        return (
            <Row className="model closed" onClick={this.toggle}>
                <Col className="name">
                    <strong>{this.props.model.names[0]}</strong>
                </Col>
                <Col sm="2" className="tissue">
                    <span>{_.get(this, 'props.model.sample.tissue.name')}</span>
                </Col>
                <Col sm="2" className="cancer-type">
                    <span>{_.get(this, 'props.model.sample.cancer_type.name')}</span>
                </Col>
                <Col className="model-type">
                    <span>{_.get(this, 'props.model.model_type')}</span>
                </Col>
                <Col sm="2" className="tissue-status">
                    <span>{_.get(this, 'props.model.sample.tissue_status')}</span>
                </Col>
                <Col sm="3" className="icons d-inline-flex">
                    <ExomeIcon active={_.get(this, 'props.model.mutations_available')} displayTooltip />
                    <CNVIcon active={_.get(this, 'props.model.cnv_available')} displayTooltip />
                    <RNASeqIcon active={_.get(this, 'props.model.expression_available')} displayTooltip />
                    <MetIcon active={_.get(this, 'props.model.methylation_available')} displayTooltip />
                    <CrisprKOIcon active={_.get(this, 'props.model.crispr_ko_available')} displayTooltip />
                    <DrugIcon active={_.get(this, 'props.model.drugs_available')} displayTooltip />
                    <FusionIcon active={_.get(this, 'props.model.fusions_available')} displayTooltip />
                    <ProteomicsIcon style={{height: "30px", marginTop: "5px"}} active={_.get(this, 'props.model.proteomics_available')} displayTooltip />
                </Col>
            </Row>
        )

    }

    renderOpenedRow(){
        let m = this.props.model;
        let synonymString = _.join(m.names.splice(1), ", ");
        let patientString = "";
        if(m.sample.age_at_sampling ||
                m.sample.patient.ethnicity !== "Unknown" ||
                m.sample.patient.gender !== "Unknown"){
            patientString =
                (m.sample.age_at_sampling? m.sample.age_at_sampling + " year old ": "") +
                (m.sample.patient.ethnicity && m.sample.patient.ethnicity !== "Unknown"? m.sample.patient.ethnicity + ' ': "") +
                m.sample.patient.gender
                }
        else {
            patientString = "Unknown"
        }
        let sample_treatment = m.sample.sample_treatment;
        if (sample_treatment && sample_treatment.length > 1) {
            sample_treatment = sample_treatment.join(' & ');
        }
        let msi_status = 'Unknown';
        if(m.model_msi_status[0].msi_status){
            let msi = _.find(m.model_msi_status, s => s.current === true);
            msi_status = msi.msi_status;
        }
        let ids = _.filter(m.identifiers, id => id.source.public === true && id.source.is_supplier === true  && ( id.source.name == 'DSMZ' ||
                                                              id.source.name == 'ATCC' ||
                                                              id.source.name == 'NCI' ||
                                                              id.source.name == 'KCLB' ||
                                                              id.source.name == 'RIKEN' ||
                                                              id.source.name == 'JCRB' ||
                                                              id.source.name == 'Rockland' ||
                                                              id.source.name == 'ECACC' ||
                                                              id.source.name == 'ICLC' ||
                                                              id.source.name == 'CLS' ||
                                                              id.source.name == 'Ximbio' ||
                                                              id.source.name == 'CellBank Australia' ||
                                                              id.source.name == 'Lonza' ||
                                                              id.source.name == 'BioIVT' ||
                                                              id.source.name == 'AddexBio'
                                                            ));
        return (
            <Row className="model open mt-3 mb-5">
                <Col sm="12" className="d-flex">
                    <div className="flex-column me-3">
                        <Button class="btn-close" color="close" onClick={this.toggle}></Button>
                    </div>
                    <div className="model-wrapper d-flex" style={{flexGrow: 1}}>
                        <Col lg="12" className="open-model-wrapper row ml-1 pt-3 z5">
                            <Col lg="4" className="name" style={{minHeight:'50px'}}>

                                <h2>{m.names[0]}</h2>
                                <p className="lead mb-3">
                                    <span className="tissue list-value">{m.sample.tissue.name}</span>
                                    {(m.sample.tissue.name.length + m.sample.cancer_type.name.length > 30)? <br />:" - "}
                                    <span className="cancer_type list-value"> {m.sample.cancer_type.name} </span>
                                </p>
                                <div className="subheader d-flex flex-row">
                                    <h3>Model information</h3>
                                </div>
                                {synonymString !== "" && (
                                    <p>
                                        <span className="list-key">Synonyms</span><br />
                                        <span className="list-value">
                                                {synonymString}
                                            </span>
                                    </p>
                                )}

                                {(m.sample.ncit && m.sample.ncit.ncit_id && m.sample.ncit.ncit_description) && (
                                    <p>
                                        <span className="list-key">Cancer type detail</span><br />
                                        <span className="list-value">
                                                {m.sample.ncit.ncit_description}&nbsp;
                                            (NCIT&nbsp;
                                            <a href={"https://ncit.nci.nih.gov/ncitbrowser/ConceptReport.jsp?dictionary=NCI_Thesaurus&code=" + m.sample.ncit.ncit_id}>
                                                    {m.sample.ncit.ncit_id}
                                                </a>)
                                            </span>
                                    </p>
                                )}
                                <p>
                                    <span className="list-key">Model Type</span><br />
                                    <span className="list-value">
                                        {m.model_type}
                                    </span>
                                </p>
                                {(ids[0]) && (
                                <p>
                                    <span className="list-key">Commerical Supplier</span><br />
                                    <span className="list-value">
                                        {ids[0].source.name}&nbsp;
                                        <a href={ids[0].source.url_format && sprintf(ids[0].source.url_format, ids[0].identifier)}
                                               target="_blank"
                                               rel="noopener noreferrer"
                                            >
                                                {ids[0].identifier}
                                            </a>
                                    </span>
                                </p>)}
                            </Col>

                            <Col lg="8">
                                <Row>
                                    <Col xs="4" className="mt-4">
                                        <div className="patient-info">
                                            <div className="subheader d-flex flex-row">
                                                <h3>Model background</h3>
                                            </div>
                                            <p>
                                                <span className="list-key">Patient: </span> 
                                                <span className="list-value">
                                                    {patientString.charAt(0).toUpperCase() + patientString.substr(1)}
                                                </span>
                                            </p>
                                            {m.sample.sample_site && (
                                                <p>
                                                    <span className="list-key">Sample Site: </span>
                                                    <span className="list-value">
                                                        {m.sample.sample_site}
                                                    </span>
                                                </p>
                                            )}
                                            <p>
                                                <span className="list-key">Tissue Status: </span>
                                                <span className="list-value">
                                                    {m.sample.tissue_status? m.sample.tissue_status: "Unknown"}
                                                </span>
                                            </p>
                                            {(sample_treatment && m.model_type !== 'Cell Line') && (
                                            <p>
                                                <span className="list-key">Prior Treatment: </span>
                                                <span className="list-value">
                                                    {sample_treatment}
                                                </span>
                                            </p>)}
                                            {(m.sample.tnm_integrated) && (
                                            <p>
                                                <span className="list-key">Tumour Stage: </span>
                                                <span className="list-value">
                                                    {m.sample.tnm_integrated}
                                                </span>
                                            </p>)}
                                            {(m.sample.patient.prior_malignancies && m.model_type !== 'Cell Line') && (
                                            <p>
                                                <span className="list-key">Prior Malignancies: </span>
                                                <span className="list-value">
                                                    {m.sample.patient.prior_malignancies}
                                                </span>
                                            </p>)}
                                        </div>
                                    </Col>
                                    <Col xs="4" className="mt-4">
                                        <div className="subheader d-flex flex-row">
                                            <h3>Genetic Status</h3>
                                        </div>
                                        <div>
                                            <p>
                                                <span className="list-key">Microsatellite: </span>
                                                <span className="list-value">{msi_status? msi_status: "Unknown"}</span>
                                            </p>
                                            {(m.ploidy) && (
                                            <p>
                                                <span className="list-key">Ploidy: </span>
                                                <span className="list-value">{m.ploidy? Math.round(m.ploidy * 1000) / 1000: "Unknown"}</span>
                                            </p>)}
                                            {(m.ploidy_wgs) && (
                                            <p>
                                                <span className="list-key">Ploidy (WGS): </span>
                                                <span className="list-value">{m.ploidy_wgs? Math.round(m.ploidy_wgs * 1000) / 1000: "Unknown"}</span>
                                            </p>)}
                                            {(m.ploidy_wes) && (
                                            <p>
                                                <span className="list-key">Ploidy (WES): </span>
                                                <span className="list-value">{m.ploidy_wes? Math.round(m.ploidy_wes * 1000) / 1000: "Unknown"}</span>
                                            </p>)}
                                            <p>
                                                <span className="list-key">Mutational Burden: </span>
                                                <span className="list-value">{m.mutations_per_mb? Math.round(m.mutations_per_mb * 1000) / 1000 + " mut/Mb": "Unknown"}</span>
                                            </p>
                                        </div>
                                    </Col>
                                    <DatasetList model={this.props.model} />
                                    <Col lg="12" style={{minHeight: '50px'}} className="text-end">
                                        <Link to={"passports/" + this.props.model.id} className="view-model-btn strong-hover z1">
                                            View {this.props.model.names[0]} >>
                                        </Link>
                                    </Col>
                                </Row>
                            </Col>
                        </Col>
                    </div>
                </Col>
            </Row>
        )

    }

    render(){

        return(
            <div>
                {this.state.isOpen ? (this.renderOpenedRow()): (this.renderClosedRow())}
            </div>
        )
    }
}

export default ModelListItem;
