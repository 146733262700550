import React, {Component} from 'react';
import 'animate.css/animate.css';
import {Col, Container, Row} from "reactstrap";
import {Link} from "react-router-dom";
import sangerLogo from '../../assets/img/Garnett_Full_Colour.png';

class Footer extends Component {

    render(){
        return(
                <Container fluid className="bg-primary-footer footer">
                    <Container>
                        <Row className="pt-3 pb-5">
                            <Col sm="3">
                                <a target="_blank" rel="noopener noreferrer" href="https://www.sanger.ac.uk/group/garnett-group/"><img src={sangerLogo} className="d-inline-block align-top w-75" alt=""/></a>
                            </Col>
                            <Col sm="3">
                                <h4>Cell Model Passports</h4>
                                <ul className="list-unstyled">

                                    <li><span>UI Version: 1.13.3</span></li>
                                    <li><span>API Version: 1.14.1</span></li>
                                    <li><span>Data Version: 2.11.0</span></li>

                                </ul>
                            </Col>
                            <Col sm="3">
                                <h4>Websites</h4>
                                <ul className="list-unstyled">
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://depmap.sanger.ac.uk/">Sanger DepMap</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://dataminer.depmap.sanger.ac.uk/explorer">Data Miner</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://www.cancerrxgene.org/">GDSC</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://score.depmap.sanger.ac.uk/">Project Score</a></li>
                                    <li><a target="_blank" rel="noopener noreferrer" href="https://gdsc-combinations.depmap.sanger.ac.uk/">GDSC^2</a></li>
                                </ul>
                            </Col>
                            <Col sm="3" className="text-right">
                                <h4>Contact</h4>
                                <p>
                                    depmap@sanger.ac.uk <br />
                                    <br />
                                    Wellcome Sanger Institute <br />
                                    Wellcome Genome Campus <br />
                                    Hinxton, Cambridgeshire <br />
                                    CB10 1SA, UK
                                </p>
                            </Col>
                        </Row>
                            <hr />
                        <Row className="pt-3 pb-5">
                        <Col sm="4">
                            This site is hosted by the <a target="_blank" rel="noopener noreferrer" href="http://www.sanger.ac.uk">Wellcome Sanger Institute</a>
                            </Col>
                          
                            <Col sm="4">
                            </Col>
                            <Col sm="4" className="text-end">
                                <a target="_blank" rel="noopener noreferrer" href="http://www.sanger.ac.uk/legal/">Terms &amp; Conditions</a>
                            </Col>
                        </Row>
                    </Container>
                </Container>)
    }

}

export default Footer;
