import React from "react";
import Multiselect from "multiselect-react-dropdown";
import PropTypes from 'prop-types';
import filterFactory, { FILTER_TYPES, customFilter } from 'react-bootstrap-table2-filter';

class DriverFilter extends React.Component {
  static propTypes = {
    column: PropTypes.object.isRequired,
    onFilter: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.filter = this.filter.bind(this);
  }
  
  filter(selectedList, selectedItem) {
    this.props.onFilter(
      selectedList.map(x => [x.value])
    );
  }
  

render(){
    return <Multiselect options={selectOptions} 
            displayValue="label"
            showCheckbox
            style={style}
            closeOnSelect={false}
            onSelect={this.filter}
            onRemove={this.filter}/>;
  }

}
const selectOptions = [
  {value: 'Loss', label: 'Loss'},
  {value: 'Gain', label: 'Gain'},
  {value: 'Deletion', label: 'Deletion'},
  {value: 'Amplification', label: 'Amplification'},
  {value: 'DRV', label: 'DRV'},
  {value: 'CPV', label: 'CPV'},
  //{value: 'DRV & CPV', label: 'DRV & CPV'}
];

const style = {
  chips: {
    background: "purple"
  },
  option: { // To change css for dropdown options
    display: "flex",
    gap: "20px",
    fontSize: "15px"
  }
};

export default DriverFilter;