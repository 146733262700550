import React from 'react';
import _ from 'lodash';

import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label } from "reactstrap";
import qs from "qs";
import {withRouter} from "react-router-dom";
import CollapseButton from '../dumb/collapseButton';
import baseFilter from "./baseFilter";

class TreatmentFilter extends baseFilter {

    constructor(props){
        super(props);
        this.renderTreatmentCheckboxes = this.renderTreatmentCheckboxes.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.updateAPIURL = this.updateAPIURL.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);

        this.state = {
            filters: {},
            isOpen: false,
            activeCount: 0
        };
        this.aggregates = {'sample.prior_treatment': 'count'}
    }

    static getDerivedStateFromProps(newprops, oldstate){
        let current_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        let newfilters = TreatmentFilter.getFiltersFromQS(current_qs, oldstate.filters);

        return {
          filters: newfilters,
          activeCount: _.filter(newfilters, 'active').length
      }
    }


    static getActiveFiltersFromQS(qs_obj, field, defaultValue = [], sep="_"){
        return (field in qs_obj)? _.split(_.get(qs_obj, field), sep):defaultValue;
    }

    static getFiltersFromQS(qs, oldfilters) {
      let qs_treatment = ('treatment' in qs)? _.split(qs.treatment, '_'):[];

      let newfilters = _.reduce(oldfilters,
        (res, filterProps, treatment) => {
          res[treatment] = filterProps;
          res[treatment].active = _.indexOf(qs_treatment, treatment) >= 0;
          return res
        }, {});

      return newfilters
    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    updateAPIURL(api_qs, newlistprops){

        let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
        let activeTreatmentFilters = TreatmentFilter.getActiveFiltersFromQS(new_qs, 'treatment');

        let filter_qs = {"name":"sample","op":"has","val":{"name":"prior_treatment","op":"in","val":activeTreatmentFilters}};

        if(activeTreatmentFilters.length > 0){
            api_qs.filter = _.get(api_qs, 'filter', []).concat(filter_qs)
        }

        return _.merge(api_qs, {agg: this.aggregates})
    }

    processResponse(response){
        let resp_agg = this.get_agg_values(response);
        let agg_field = _.keys(this.aggregates)[0];

        if(! resp_agg){
            return
        }

        let newfilters = _.reduce(resp_agg[agg_field][this.aggregates[agg_field]],
            (res, count, treatment) => {
                if(treatment in this.state.filters){
                    res[treatment] = this.state.filters[treatment];
                    res[treatment].count = count
                } else {
                    if (treatment !== 'None') {
                        res[treatment] = {'count': count, active: false}
                    }
                }
                return res;
            }, {});
        this.setState({
            filters: newfilters
        })
    }

    toggleCheckbox(event){
        let treatment = event.target.name;
        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let treatmentFilter = ('treatment' in current_qs)? _.split(current_qs.treatment, '_'):[];

        if(event.target.checked){
            treatmentFilter.push(treatment)
        } else {
            treatmentFilter = treatmentFilter.filter((item) => item !== treatment)
        }

        current_qs.treatment = (treatmentFilter.length > 0)? _.join(treatmentFilter.sort(), '_'): undefined;
        current_qs.page = undefined;
        this.props.history.push(window.location.pathname + qs.stringify(current_qs, { addQueryPrefix: true }));
    }

    renderTreatmentCheckboxes(){
        return (
            _.map(_.toPairs(this.state.filters), ([treatment, details]) => {
                return (
                    <FormGroup check key={treatment}>
                        <Input type="checkbox" name={treatment} onChange={this.toggleCheckbox} checked={_.get(details, 'active')}/>{' '}
                        <Label className="ml-4 mt-1" check>
                            {treatment} <span className="secondary">({_.get(details, 'count')})</span>
                        </Label>
                    </FormGroup>
                )}
            )
        )

    }

    componentDidMount() {
        this.props.registerResponse(this.processResponse);
        this.props.registerRequest(this.updateAPIURL);
    };

    componentWillUnmount() {
        this.props.unregisterResponse(this.processResponse);
        this.props.unregisterRequest(this.updateAPIURL);
    };

    render(){
        return(
            <Card className="treatment-filters mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline w-50">Prior Treatment</div>
                    <div className="d-inline-block">
                        {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        {this.renderTreatmentCheckboxes()}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(TreatmentFilter)
