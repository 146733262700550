import React, {Fragment} from 'react';

export default function(){
    return(
        <Fragment>
            <h1>Gene Expression Data</h1>
            <p>
               
            </p>
            <p>
              
            </p>
        </Fragment>
    )
}