import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {
    return (
            <BaseIcon
                {...props}
                tooltipText="Fusion data"
            >

                <rect x="200.946" y="247.3696" width="290.582" height="95.3359" fill="white" strokeWidth="18"/>
                <rect x="0.52771" y="238.3696" width="192.729" height="113.336" />
                <line x1="210.311" y1="200.244142" x2="210.311" y2="403.12" strokeWidth="24"/>
            </BaseIcon>
    )

}