import React, {Component} from 'react';
import {NavItem, NavLink, Nav, TabContent, TabPane, Col, Card, CardHeader, CardBody, ListGroupItem} from "reactstrap";
import _ from 'lodash';
import DatasetListItem from "./datasetsListItem";
import { DrugIcon} from "../../dumb/icons/icons";
import {sort_order} from "../../../utils";
import classnames from 'classnames';

class ModelDataset extends Component {
    constructor(props) {
        super(props);

        this.state = {
            datasetlist: [],
            activeTab: 0,
            currentModelId: null
        };

        this.toggle = this.toggle.bind(this);
        this.updateDatasetList = this.updateDatasetList.bind(this)
    }

    toggle(tab) {
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab.i
            });
        }
    }

    componentDidMount() {
        if (this.props.model) {
            this.updateDatasetList();
        }
    }

    componentDidUpdate() {
        if (this.props.model && this.props.model.id !== this.state.currentModelId) {
            this.updateDatasetList();
        }
    }

    updateDatasetList(){
        let datasetList = this.processModelFiles(this.props.model.files);

        this.setState({
            datasetlist: datasetList,
            currentModelId: this.props.model.id,
        });
    }

    processModelFiles(files) {
        if(files) {
            //  construct the data structure for render the dataset list
            let files_sorted = _.sortBy(files, (file) => sort_order(file.meta.data_type_abbr));
            let datasetList = _.reduce(files_sorted, (dataTypeLists, file) => {
                // find if there is one with the same type exists
                let d = this.typeInDatasetList(file.meta.data_type, dataTypeLists);

                if (!d) {
                    d = {
                        type: file.meta.data_type,
                        header: file.meta.data_type,
                        datasets: []
                    };
                    dataTypeLists.push(d)
                }
                d.datasets.push(file);
                return dataTypeLists;
            }, []);

            return datasetList;
        }
    }

    typeInDatasetList(type, list) {
        return _.find(list, o => o.type === type);
    }

    render() {
        if(_.isEmpty(this.state.datasetlist  ) && !this.props.drug_link){
            return  null;
        }
        const dslength = this.state.datasetlist.length;
        return (
             <Col className="d-flex my-3 model-datasets" md={12} xl={12}>
                <Card className="flex-fill models">
                    <CardHeader>Dataset Availability</CardHeader>
                       <CardBody className="card-body">
                       <Nav tabs>
                    {this.state.datasetlist.map((ds, i) =>
                        <NavItem key={i}>
                            <NavLink className={classnames({ active: this.state.activeTab === {i} })} onClick={() => { this.toggle({i}); }}>
                                {ds.header}
                            </NavLink>
                        </NavItem>
                    )}
                    { this.props.drug_link && this.props.drug_link.includes(this.props.model.names[0]) && (<NavItem>
                            <NavLink className={classnames({ active: this.state.activeTab === {dslength} })} onClick={() => { this.toggle({dslength}); }}>
                                <span>Drug Sensitivity Data</span>
                            </NavLink>
                        </NavItem>)}
                </Nav>
         
                       <TabContent activeTab={this.state.activeTab}><br />
                           {this.state.datasetlist.map((ds, i) => {
                               return (
                                   <TabPane tabId={i} key={i}>
                                       <DatasetListItem key={i} {...ds} />
                                   </TabPane>
                               )
                            })}<TabPane>
                              { this.props.drug_link && (
                              <ListGroupItem>
                                  <div className={"d-flex flex-column"}>
                                      <div className={"d-flex flex-row"}>
                                          <DrugIcon style={{height: "30px"}}/>
                                             <div>
                                                <span>Drug Sensitivity Data</span>
                                             </div>
                                      </div>
                                      <div className="dataset-list-item">
                                          <a href={this.props.drug_link}>Link</a> to Genomics of Drug Sensitivity in Cancer
                                      </div>
                                  </div>
                              </ListGroupItem> )}</TabPane>
                         
                         </TabContent>
                       </CardBody>
                </Card>
            </Col>
        );
    }
}

export default ModelDataset;
