export const alphabetically = (a, b) => {
    let na = a.toLowerCase();
    let nb = b.toLowerCase();

    if (na < nb) return -1;
    else if (na > nb) return 1;
    else return 0;
};

export const capitalise = (word) => {
    return word.charAt(0).toUpperCase() + word.slice(1)
};

export const DATASET_FIELDS = ['exome', 'snp6', 'methylation', 'expression'];

export const datasetLabels = {
    "SNP6": "Copy Number Alterations",
    "EXOME": "Mutations",
    "EXPRESSION": "Gene Expression",
    "METHYLATION": "Methylation"
};


export const urlify = (word) => {
    return word.replace(/ /g, "").toLowerCase();
};

export const removeValueFromArray = (value, array) => {
    let index = array.indexOf(value);
    if (index > -1) {
        array.splice(index, 1);
    }
    return array
};

export const debug = true;


export const humanFileSize = (size) => {
    let i = Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
}; // source: https://stackoverflow.com/a/20732091

export const api_fetch = (url, options, n = 3) => fetch(url, options).catch(function(error) {
    if (n === 1) throw error;
    return api_fetch(url, options, n - 1);
}); //source: https://dev.to/ycmjason/javascript-fetch-retry-upon-failure-3p6g

export const sort_order = (label) => {
    let datasets = [
        'mutations;wes;tgs;wgs;whole exome sequencing;whole genome sequencing;targeted gene sequencing',
        'expression;expr;rnaseq;rna;gene expression;microarray;Microarray Gene Expression;mge',
        'cnv;cna;copy number;copy number alteration;copy number variation',
        'met;meth;methylation;dna methylation;',
        'crispr;crispr_ko;crispr knock-out;crispr knock out;CRISPR; CRISPR KO']

    let lclabel = label.toLowerCase();
    for (let i = 0; i < datasets.length; i++){
        if (datasets[i].includes(lclabel)) return i
    }
    return 100
}

export const debounce = (fn, time) => {
    let timeout;

    return function() {
        const functionCall = () => fn.apply(this, arguments);

        clearTimeout(timeout);
        timeout = setTimeout(functionCall, time);
    }
}