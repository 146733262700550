import React, {Component} from 'react';
import _ from 'lodash';
import {Col, Card, CardBody, CardHeader, Table} from "reactstrap";
import {api_base_url} from "../../../settings";
import Deserialiser from 'deserialise-jsonapi'
import Loading from '../../dumb/loading'

const des = new Deserialiser();

class ModelCrisprKO extends Component {
    constructor(props) {
        super(props);
        this.state = {model: props.model};
        this.loadCrisprKO = this.loadCrisprKO.bind(this)
        this.renderTable = this.renderTable.bind(this)
    }

    loadCrisprKO() {
        const model_id = this.state.model.id;

        let adm_tissue = null;
        let model_tissue = this.state.model.sample.tissue.name;

        switch (model_tissue) {
            case "Central Nervous System":
                adm_tissue = 'cns';
                break;
            case "Peripheral Nervous System":
                adm_tissue = 'pns';
                break;
            case "Haematopoietic and Lymphoid":
                adm_tissue = 'haem_lymph';
                break;
            case "Head and Neck":
                adm_tissue = 'head_neck';
                break;
            case "Bone":
            case "Breast":
            case "Colon":
            case "Endometrium":
            case "Lung":
            case "Ovary":
            case "Pancreas":
            case "Stomach":
                adm_tissue = model_tissue.toLowerCase();
                break;
            default:
                adm_tissue = null;
        }

        // Always filter for non-core-essential genes
        let conditions = [
            {name: "core_fitness_pancan", op: "eq", val: false},
            {name: "known_dna_replication", op: "eq", val: false},
            {name: "known_histones", op: "eq", val: false},
            {name: "known_proteasome", op: "eq", val: false},
            {name: "known_ribosomal", op: "eq", val: false},
            {name: "known_rna_polymerase", op: "eq", val: false},
            {name: "known_spliceosome", op: "eq", val: false},
        ];
        // Filter for non-tissue-essential genes if tissue has been analysed
        if (adm_tissue) {
            conditions.push({name: `adm_status_${adm_tissue}`, op: "eq", val: "CS"})
        }

        let api_url_filter = [
            {
                name: "gene",
                op: "has",
                val: {
                    name: "essentiality_profiles",
                    op: "any",
                    val: {
                        and: conditions
                    }
                }
            }
        ];

        const url = `${api_base_url}/models/${model_id}/datasets/crispr_ko?merged=true&filter=${JSON.stringify(api_url_filter)}&page[size]=5&include=gene&sort=fc_clean_qn`;

        fetch(url)
            .then(response => response.json())
            .then(resp_obj => {
                return des.deserialise(resp_obj)
            })
            .then(essentialities => {

                    this.setState({
                        essentialities: essentialities,
                        load_complete: 1
                    })
                }
            ).catch(function (ex) {
            console.log('parsing failed', ex);
        });
    }

    renderTable() {
        return (
            <div>
                <p>
                    <a href={"https://score.depmap.sanger.ac.uk/model/" + this.state.model.id} className="bold" target="_blank" rel="noopener noreferrer"> View {this.state.model.names[0]} on the Project SCORE website</a>
                </p>
                <p>
                    Top-5 genes with highest model-specific effect size (excluding prior-known and predicted core fitness genes)
                </p>
                <Table>
                    <thead>
                    <tr>
                        <th className='w-50'>Gene <br /><small>(Link to Project SCORE)</small></th>
                        <th className='w-25'>Corrected log fold change<br /><small>&nbsp;</small></th>
			<th className='w-25'>Loss of fitness score<br /><small>&nbsp;</small></th>
                    </tr>
                    </thead>
                    <tbody>
                    {_.map(this.state.essentialities, ess => {
                        return (
                            <tr key={ess.gene.id}>
                                <td><a
                                    href={'https://score.depmap.sanger.ac.uk/gene/' + ess.gene.id} target="_blank" rel="noopener noreferrer">{ess.gene.symbol} {(ess.gene.tumour_suppressor) && "*"}</a>
                                </td>
                                <td>{ess.fc_clean_qn}</td>
				<td>{ess.bf_scaled}</td>
                            </tr>)
                    })}
                    </tbody>
                </Table>
            </div>
        )
    }

    componentDidMount() {
        this.loadCrisprKO()
    }

    componentDidUpdate() {
        if (this.state.model.id !== this.props.model.id) {
            this.setState({model: this.props.model, load_complete: false}, this.loadCrisprKO)
        }
    }

    render() {
        if (!this.state.model.crispr_ko_available) return null;

        let content = null;


        if (!this.state.load_complete) {
            content = (
                <div className="py-5"><Loading subtitle="Loading Crispr KO" color="crispr" /></div>);
        } else {
            content = this.renderTable()
        }
        return (
            <Col className="my-3">

	    <Card className="flex-fill genes" style={{height: "550px", width: "100%"}}>
                    <CardHeader>Crispr KO - highlights from <a href='https://score.depmap.sanger.ac.uk' target="_blank" rel="noopener noreferrer">Project SCORE</a></CardHeader>
                    <CardBody>
                        {content}
                    </CardBody>
		</Card>
            </Col>
        );
    }
}

export default ModelCrisprKO;
