import React, {Component} from 'react';
import 'animate.css/animate.css';
import Reveal from 'react-reveal';
import {
    Button, CardDeck, Col, Container, Jumbotron,
    Row
} from "reactstrap";
import {Link} from "react-router-dom";
import ProgrammeCard from '../dumb/programmeCard';

import Sunburst from '../charts/sunburst';
import MutationsChart from '../charts/mutationsChart'
import DatasetAvailabilityChart from '../charts/datasetAvailabilityChart';
import HomeSearch from '../dumb/homeSearch';

import genes from '../../assets/img/CRISPR.jpg';
import drugs from '../../assets/img/GDSC-right.jpg';
import analytics from '../../assets/img/Tools1.jpg';

class Home extends Component {

    render(){
        return(
            <div>
                <Jumbotron className="bg-faded mb-5">
                    <Reveal effect="animated fadeInUp">
                        <div className="text-center">
                            <h1 className="display-1">Cell Model Passports</h1>
                            <p className="lead">A Hub for Preclinical Cancer Models - Annotation, Genomics & Functional Datasets</p>
                        </div>
                    </Reveal>
                    <Row>
                        <HomeSearch sm={{size:10, offset: 1}} md={{size:6, offset: 3}} xl={{size:4, offset: 4}} className="my-5" />
                    </Row>
                </Jumbotron>

                <Container fluid>
                    <Container>
                        <Row>
                            <Col sm="6">
                                <Reveal effect="animated fadeInLeft">
                                    <h1 className="display-4 mb-4">Models</h1>
                                    <p className="lead">
                                        Over 2000 cancer cell line and organoid models catalogued.
                                    </p>
                                    <ul className="mb-3">
                                        <li><span>Detailed annotations including Tissue and Cancer Type</span></li>
                                        <li><span>Related and mis-identified models described</span></li>
                                        <li><span>Advanced filters support model selection</span></li>
                                        <li><span>Models mapped to external databases and resources</span></li>
                                    </ul>

                                    <Button tag={Link} to="/passports" className="mt-4 mb-3 z1 hover" color="primary" size="lg">Browse all Models</Button>{' '}

                                </Reveal>
                            </Col>
                            <Col sm="6">
                                <Reveal effect="animated fadeInRight">
                                    <Sunburst/>
                                </Reveal>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                <Container fluid className="bg-faded py-5">
                    <Container>
                        <Row className="mt-5">
                            <Col lg="6">
                                <Reveal effect="animated rollIn">
                                    <DatasetAvailabilityChart />
                                </Reveal>
                            </Col>
                            <Col lg="6">
                                <Reveal effect="animated fadeInLeft">
                                    <h1 className="display-3">Datasets</h1>
                                    <p className="lead">
                                        Access genomic and functional datasets with website or API.
                                    </p>
                                    <p>

                                    </p>
                                    <ul>
                                        <li>High quality processed dataset downloads</li>
                                        <li>Links to raw data provided</li>
                                        <li>Flexible REST-API facilitates access</li>
                                        <li>Top drug sensitivities from the GDSC project</li>
                                        <li>CRISPR KO highlights from Project SCORE</li>
                                    </ul>

                                    <Button tag={Link} to="/downloads" className="mt-2 mb-4 z1 hover" color="primary" size="lg">Downloads & Datasets</Button>{' '}<br />

                                </Reveal>
                            </Col>
                        </Row>
                    </Container>
                </Container>

                <Container fluid className="py-5">
                    <Container>
                        <Row className="mt-5">

                            <Col sm="6">
                                <Reveal effect="animated fadeInRight">
                                    <h1 className="display-3">Genetic Features</h1>
                                    <p className="lead">
                                        Models are annotated with known cancer driver mutations and other molecular characteristics.
                                    </p>
                                    <ul>

                                        <li>Find models with specific mutations</li>
                                        <li>Microsatellite Status</li>
                                        <li>Ploidy and Mutational Burden</li>
                                    </ul>
                                    <p><em>Select a gene to view models with mutation</em></p>
                                </Reveal>
                            </Col>
                            <Col sm="6">
                                <Reveal effect="animated fadeInUp">
                                    <MutationsChart />
                                </Reveal>
                            </Col>
                        </Row>
                    </Container>
                </Container>



                <Container fluid className="bg-faded py-5">
                    <Container>
                        <Row className="mt-5 mb-2">
                            <Col className="text-center">
                                <h2 className="display-3">DepMap Programmes at Sanger</h2>
                                <p className="lead">The Cell Model Passports is an integral part of the <a href="http://depmap.sanger.ac.uk">Cancer Dependency Map at Sanger</a></p>
                            </Col>
                        </Row>
                        <Row className="mt-5 mb-5">
                            <Reveal effect="animated fadeInUp">
                                <CardDeck className="p-2 p-md-0">
                                    <ProgrammeCard
                                        className="mb-2 mb-md-0"
                                        img={genes}
                                        project="Genes"
                                        header="The CRISPR Project"
                                        link="http://depmap.sanger.ac.uk/programmes#genes">
                                        Genetic screens to identify cancer dependencies
                                    </ProgrammeCard>
                                    <ProgrammeCard
                                        className="mb-2 mb-md-0"
                                        img={drugs}
                                        project="Drugs"
                                        header="Genomics of Drug Sensitivity"
                                        link="http://depmap.sanger.ac.uk/programmes#drugs">
                                        Association of drug response with genetic features in hundreds of cancer cell models
                                    </ProgrammeCard>
                                    <ProgrammeCard
                                        className="mb-2 mb-md-0"
                                        img={analytics}
                                        project="Analytics"
                                        header="DepMap Analytics"
                                        link="http://depmap.sanger.ac.uk/programmes#analytics">
                                        Software and tools for analysing DepMap data
                                    </ProgrammeCard>
                                </CardDeck>
                            </Reveal>
                        </Row>
                    </Container>
                </Container>
            </div>)
    }

}

export default Home;