import React, { Component } from 'react';
import _ from 'lodash';
import qs from "qs";
import {withRouter} from "react-router-dom";
import {Card, CardBody, CardHeader, Collapse} from "reactstrap";
import ReactSlider from 'react-slider'

import CollapseButton from '../dumb/collapseButton';
import styled from 'styled-components';

const StyledSlider = styled(ReactSlider)`
    width: 100%;
    height: 25px;
`;

const StyledThumb = styled.div`
    height: 25px;
    line-height: 25px;
    width: 35px;
    text-align: center;
    background-color: #000;
    color: #fff;
    border-radius: 50%;
    cursor: grab;
`;

const Thumb = (props, state) => <StyledThumb {...props}>{state.valueNow}</StyledThumb>;

const StyledTrack = styled.div`
    top: 0;
    bottom: 0;
    background: ${props => (props.index === 2 ? '#f00' : props.index === 1 ? '#0f0' : '#ddd')};
    border-radius: 999px;
`;

const Track = (props, state) => <StyledTrack {...props} index={state.index} />;

class AgeFilter extends Component {

    constructor(props){
        super(props);
        this.renderAgeSlider = this.renderAgeSlider.bind(this);
        this.handleAgeChange = this.handleAgeChange.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);

        this.props.registerRequest(this.updateAPIURL);

        this.state = {
            value: '',
            minAge: [],
            maxAge: [],
            isOpen: false
        }
        console.log(this.state)
    }

    static getDerivedStateFromProps(newprops){
        let new_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        let minAge = ('min_age' in new_qs)? _.split(new_qs.min_age, '_'):[];
        let maxAge = ('max_age' in new_qs)? _.split(new_qs.max_age, '_'):[];
        return {
            minAge: minAge,
            maxAge: maxAge
        }

    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    updateAPIURL(api_qs, newlistprops){

        let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
        let minAgeFilter = ('min_age' in new_qs)? _.split(new_qs.min_age, '_'):[];
        let maxAgeFilter = ('max_age' in new_qs)? _.split(new_qs.max_age, '_'):[];

        if(minAgeFilter.length > 0){

                let filter_qs = {"name":"sample","op":"has","val":{"and":[
                        {"name":"age_at_sampling","op":"ge","val":minAgeFilter[0]},
                        {"name":"age_at_sampling","op":"le","val":maxAgeFilter[0]}]}}


                if('filter' in api_qs){
                    api_qs.filter.push(filter_qs)
                } else {
                    api_qs['filter'] = [filter_qs]
                }

        }

        return api_qs

    }


    handleAgeChange(event){
    
        let min_age = event[0];
        let max_age = event[1];
        
        console.log(min_age)
        console.log(max_age)
        
        if(min_age >= 0 && max_age <= 100){
        
            let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        
            current_qs.min_age = min_age;
            current_qs.max_age = max_age;
            current_qs.page = undefined;
            this.props.history.push(window.location.pathname + qs.stringify(current_qs, { addQueryPrefix: true }));
        }
    }

    renderAgeSlider(){
        return(
   
            <StyledSlider defaultValue={[0, 100]} renderTrack={Track} renderThumb={Thumb} onAfterChange={this.handleAgeChange}/>
     
        )
    }

    render(){
        return(
            <Card className="genetic-filters mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline w-50" >Age</div>
                    <div className="d-inline-block">
                        {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        { this.renderAgeSlider() }
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(AgeFilter)
