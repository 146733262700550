import React from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';

export default class API extends React.Component {
    constructor(props) {
        super(props);

        this.toggleEndpoints = this.toggleEndpoints.bind(this);
        this.state = {
            activeEndpointTab: 'model_list',
            activeModifierTab: 'pagination'
        };
    }

    toggleEndpoints(tab) {
        if (this.state.activeEndpointTab !== tab) {
            this.setState({
                activeEndpointTab: tab
            });
        }
    }
    toggleModifiers(tab) {
        if (this.state.activeModifierTab !== tab) {
            this.setState({
                activeModifierTab: tab
            });
        }
    }
    render() {
        return (
            <div className="api-documentation pb-5">
                <h1>API</h1>
                <p className="lead mb-5">
                    The Cell Model Passports API provides access cell model information and associated datasets.
                </p>

                <h3>Introduction</h3>
                <p>
                    The API for the cell model passports is a HTTP REST-API that returns information based on the URL of GET requests.
                    It strictly follows the <a href="http://jsonapi.org" target="_blank" rel="noopener noreferrer">JSONAPI v1.0</a> standards to allow consistent,
                    predictable and discoverable access to all available resources. Therefore, all responses will be in JSON-format and will
                    carry the 'application/vnd.api+json' Content-Type header.
                </p>
                <p>
                    The base-url for the API is <strong>https://api.cellmodelpassports.sanger.ac.uk</strong>
                </p>

                <h3 className="mt-5">Endpoints</h3>

                <p>
                    The base-url needs to be supplemented with a suffix (path) to the resource. The base url and path together
                    form the endpoint. All Cell Model Passport API endpoints are listed below.
                </p>
                <p>
                    Please note that the root url ("/") is not a valid endpoint and will thus result in a 404 (Not Found) error.
                </p>
                <Nav tabs className="mt-4">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeEndpointTab === 'model_list' })}
                            onClick={() => { this.toggleEndpoints('model_list'); }}
                        >
                            Cell Model Lists
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeEndpointTab === 'model_single' })}
                            onClick={() => { this.toggleEndpoints('model_single'); }}
                        >
                            Cell Model & Files
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeEndpointTab === 'genes' })}
                            onClick={() => { this.toggleEndpoints('genes'); }}
                        >
                            Genes
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeEndpointTab === 'mutations' })}
                            onClick={() => { this.toggleEndpoints('mutations'); }}
                        >
                            Mutations & Cancer Drivers
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeEndpointTab === 'cnv' })}
                            onClick={() => { this.toggleEndpoints('cnv'); }}
                        >
                            Copy Number Variations
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={this.state.activeEndpointTab}>
                    <TabPane tabId="model_list">
                        <Row>
                            <Col sm="12" className="p-5">
                                <h4>Model list endpoints</h4>
                                <p>
                                    Endpoints that result in a list of models
                                </p>
                                <dl>
                                    <dt>
                                        /models
                                    </dt>
                                    <dd>
                                        List of all models
                                    </dd>

                                    <dt>
                                        /samples/&lt;sample_id&gt;/models
                                    </dt>
                                    <dd>
                                        A list of models that originate from a specific sample<br />
                                        Example: <em>/samples/SIDS00002/models</em>
                                    </dd>

                                    <dt>
                                        /patients/&lt;patient_id&gt;/models
                                    </dt>
                                    <dd>
                                        A list of models from a particular patient <br />
                                        Example: <em>/patients/SIDP00003/models</em>
                                    </dd>

                                    <dt>
                                        /models/by_&lt;mut_type&gt;/&lt;gene_identifier&gt;
                                    </dt>
                                    <dd>
                                        A list of models that have a mutation type in a specific gene. <br />
                                        Valid &lt;mut_type&gt;s are 'frameshift', 'snp', 'insertion', 'deletion',
                                        'splice_variant' or 'mutation'. Use 'mutation' to list models with any kind of
                                        mutation in the specified gene.<br />
                                        Example: <em>/models/by_snp/BRAF</em><br />
                                        Example: <em>/models/by_frameshift/TP53</em>
                                    </dd>

                                    <dt>/models/by_&lt;mut_type&gt;/&lt;gene_identifier&gt;/&lt;variant&gt;</dt>
                                    <dd>
                                        A list of models that have a mutation type in a specific protein location in
                                        a specific gene.<br />
                                        Example: <em>/models/by_snp/BRAF/V600</em> - for any model with a BRAF mutation at the V600 location <br />
                                        Example: <em>/models/by_snp/BRAF/V600E</em> -  for any model with a BRAF V660E mutation
                                    </dd>
                                </dl>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="model_single">
                        <Row>
                            <Col sm="12" className="p-5">
                                <h4>Single model endpoints</h4>
                                <p>
                                    Endpoints that result in a single model
                                </p>
                                <dl>
                                    <dt>/models/&lt;id&gt;</dt>
                                    <dd>Example <em>/models/SIDM00003</em></dd>

                                    <dt>/models/&lt;source&gt;/&lt;source_id&gt;</dt>
                                    <dd>
                                        Use any identifier that is annotated <br />
                                        The identifier source is case-insensitive, but the identifier is case-sensitive.<br />
                                        Example: <em>/models/cosmic_id/909751</em><br />
                                        Example: <em>/models/CCLE_ID/769P_KIDNEY</em>
                                    </dd>

                                </dl>
                                <dl>
                                    <dt>/models/&lt;model_id&gt;/files</dt>
                                    <dt>/models/&lt;source&gt;/&lt;source_id&gt;/files</dt>
                                    <dd>View a list of all files attached to a model</dd>
                                </dl>

                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="genes">
                        <Row>
                            <Col sm="12" className="p-5">
                                <h4>Gene List endpoints</h4>
                                <p>
                                    Endpoints that show a list genes
                                </p>
                                <dl>
                                    <dt>/genes</dt>
                                    <dd>List of all genes in database</dd>

                                </dl>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="mutations">
                        <Row>
                            <Col sm="12" className="p-5">
                                <dl>
                                    <dt>/models/&lt;model_id&gt;/datasets/mutations</dt>
                                    <dt>/models/&lt;source&gt;/&lt;source_id&gt;/datasets/mutations</dt>
                                    <dd>
                                        All mutations for a specific model <br />
                                        Example: /models/SIDM00003/datasets/mutations <br />
                                        Example: /models/COSMIC_ID/905975/datasets/mutations
                                    </dd>

                                    <dt>/models/&lt;model_id&gt;/datasets/cancer_drivers</dt>
                                    <dt>/models/&lt;source&gt;/&lt;source_id&gt;/datasets/cancer_drivers</dt>
                                    <dd>
                                        Cancer driver mutations for a specific model
                                    </dd>

                                    <dt>/models/&lt;model_id&gt;/datasets/cancer_drivers/by_&lt;category&gt;</dt>
                                    <dt>/models/&lt;source&gt;/&lt;source_id&gt;/datasets/cancer_drivers/by_&lt;category&gt;</dt>
                                    <dd>
                                        Cancer drivers of a specific model, ranked by how often they occur in a specific
                                        &lt;category&gt;. <br />
                                        Example: <em>/models/SIDM00002/datasets/cancer_drivers/by_tissue</em> - shows
                                        all drivers for model SIDM00002, ranked by how often they occur in its
                                        tissue.
                                    </dd>

                                </dl>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="cnv">
                        <Row>
                            <Col sm="12" className="p-5">
                                <dl>

                                    <dt>/models/&lt;model_id&gt;/datasets/genecnv</dt>
                                    <dt>/models/&lt;source&gt;/&lt;source_id&gt;/datasets/genecnv</dt>
                                    <dd>Gene level CNV of a specific model</dd>


                                </dl>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>


                <h3 className="mt-5">Modifiers</h3>

                <p>
                    There are query parameters that can be appended to any of the endpoint URLs to modify the response.
                    These modifiers apply mainly to list endpoints, and allow control of sorting, filtering, pagination,
                    specifying which fields to return and the inclusion of related resources.
                </p>
                <p>
                    All modifiers can be combined in a single query to request exactly the desired data
                </p>

                <Nav tabs className="mt-4">
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeModifierTab === 'pagination' })}
                            onClick={() => { this.toggleModifiers('pagination'); }}
                        >
                            Pagination
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeModifierTab === 'include' })}
                            onClick={() => { this.toggleModifiers('include'); }}
                        >
                            Inclusion
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeModifierTab === 'sorting' })}
                            onClick={() => { this.toggleModifiers('sorting'); }}
                        >
                            Sorting
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeModifierTab === 'fields' })}
                            onClick={() => { this.toggleModifiers('fields'); }}
                        >
                            Sparse Fieldsets
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={classnames({ active: this.state.activeModifierTab === 'filtering' })}
                            onClick={() => { this.toggleModifiers('filtering'); }}
                        >
                            Filtering
                        </NavLink>
                    </NavItem>

                </Nav>


                <TabContent activeTab={this.state.activeModifierTab}>
                    <TabPane tabId="pagination">
                        <Row>
                            <Col sm="12" className="p-5">
                                <h4>Pagination</h4>
                                <p>
                                    Applies to: list endpoints
                                </p>
                                <p>
                                    Use the <span className="text-monospace highlight">page[size]</span> parameter to change the number of items
                                    in a list, use <span className="text-monospace highlight">page[number]</span> to specify the page.
                                    The default page size is 30. Use <span className="text-monospace highlight">page[size]=0</span>
                                    to disable pagination.

                                </p>
                                <h5 className="mt-3">
                                    Examples
                                </h5>
                                <dl>
                                    <dt>/models?page[size]=100</dt>
                                    <dd>List of models, paginated per 100</dd>
                                    <dt>/genes?page[size]=1000&page[number]=5</dt>
                                    <dd>List of genes in default order, showing gene 4001 to 5000</dd>
                                </dl>
                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="include">
                        <Row>
                            <Col sm="12" className="p-5">
                                <h4>Inclusion of related properties</h4>

                                <p>
                                    Applies to: list and single endpoints
                                </p>
                                <p>
                                    With the <span className="text-monospace highlight">include</span> query parameter, related
                                    resources can be included in a response. Provide a comma-separated list of relations to include.
                                </p>
                                <p>
                                    In addition to the data, links, and jsonapi-fields of a normal response, an additional field 'included'
                                    is added to the response, which contains an array or related resources.
                                    The relationships-field in the data parameter of the main object(s) now includes a type and id parameter
                                    that refers to each item’s related resources.
                                    It is possible to include relations of related resources with the .-notation.
                                </p>
                                <p>
                                    Note: included related resources do not benefit from pagination or other modifiers.
                                </p>

                                <h5 className="mt-3">
                                    Examples
                                </h5>
                                <dl>
                                    <dt>/models?include=files</dt>
                                    <dd>List of models and include the files</dd>
                                    <dt>/models/SIDM00003?include=sample.tissue</dt>
                                    <dd>Include the sample and the sample's tissue for model SIDM00003</dd>
                                </dl>

                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="sorting">
                        <Row>
                            <Col sm="12" className="p-5">
                                <h4>Sorting</h4>
                                <p>
                                    Use the <span className="text-monospace highlight">sort</span>-parameter to change the sorting order of a list. Specify a
                                    comma-separated list on the fields to sort by. Default sorting is ascending,
                                    use a minus-sign (-) to sort descending on that field.
                                </p>
                                <p>
                                    Sorting can be used on text-fields (alphabetically) or numerical fields, but not on properties of related properties.
                                </p>
                                <h5 className="mt-3">
                                    Examples
                                </h5>
                                <dl>
                                    <dt>/models/SIDM00003/datasets/genecnv?sort=-abs_copy_number,gistic_score</dt>
                                    <dd>List all CNV for model SIDM00003, ordered descending by absolute copy number and then ascending by gistic score </dd>
                                    <dt>/samples?sort=sampling_year</dt>
                                    <dd>List all samples sorted by sampling year</dd>
                                </dl>


                            </Col>
                        </Row>
                    </TabPane>
                    <TabPane tabId="fields">
                        <Row>
                            <Col sm="12" className="p-5">
                                <h4>Sparse fields selection</h4>
                                <p>
                                    Applies to: single and list endpoints
                                </p>
                                <p>
                                    Use the <span className="text-monospace highlight">fields[resource]</span> parameter to view a limited list of fields.
                                    Provide a comma separated list of fields to include. Selecting only the required fields can
                                    significantly reduce the size of the response and increase the speed.
                                </p>

                                <h5 className="mt-3">
                                    Examples
                                </h5>
                                <dl>
                                    <dt>/models?fields[model]=names</dt>
                                    <dd>List all models, but only show the 'names'-property</dd>
                                    <dt>/models?include=sample.tissue&fields[sample]=id,tissue&fields[tissue]=name</dt>
                                    <dd>List all models, include its sample and the samples tissue, but only include the tissue name</dd>
                                </dl>
                            </Col>
                        </Row>
                    </TabPane>

                    <TabPane tabId="filtering">
                        <Row>
                            <Col sm="12" className="p-5">
                                <h4>Filtering</h4>
                                <p>
                                    Applies to: list endpoints
                                </p>
                                <p>
                                    Filtering allows the list endpoint to be filtered on the server.
                                    Filtering is very powerful and flexible, but has a verbose syntax.
                                    The basic query parameter is as follows: <span className="text-monospace highlight">filter=[list of filters]</span><br />
                                    A single filter in this list is a json object with three fields: <br />
                                    <span className="text-monospace highlight">
                                        {'{"name": <field to filter on>,"op": <operator for filtering>,"val": <value for filtering>}'}

                                    </span>
                                </p>
                                <p>
                                    The ‘name’ is the name of the attribute that is being filtered on. <br />
                                    The ‘op’ is the operator. There is a fixed list of valid operators, but availability depends on the
                                    field type of the field being filtered on:
                                </p>
                                <p>
                                    <strong>Comperators</strong><br/>
                                    <strong>eq</strong>: check if field is equal to something<br/>
                                    <strong>ne</strong>: check if field is not equal to something<br/>
                                    <strong>gt</strong>: check if field is greater than to something<br/>
                                    <strong>ge</strong>: check if field is greater than or equal to something<br/>
                                    <strong>lt</strong>: check if field is less than to something<br/>
                                    <strong>le</strong>: check if field is less than or equal to something<br/>
                                    <strong>contains</strong>: check if field contains a string<br />
                                    <strong>like</strong>: check if field contains a string<br />
                                    <strong>notlike</strong>: check if field does not contains a string<br />
                                    <strong>ilike</strong>: check if field contains a string (case insensitive)<br />
                                    <strong>notilike</strong>: check if field does not contains a string (case insensitive)<br />
                                    <strong>startswith</strong>: check if field starts with a string<br />
                                    <strong>endswith</strong>: check if field ends with a string<br />
                                    <strong>in_</strong>: check if field is in a list of values<br />
                                    <strong>notin_</strong>: check if field is not in a list of values<br />
                                    <strong>any</strong>: used to filter on to many relationships<br />
                                    <strong>has</strong>: used to filter on to one relationships<br />
                                </p>

                                <p>
                                    Multiple filters can be applied with the 'and', 'or' and 'not' logic combinators.
                                </p>
                            </Col>
                        </Row>
                    </TabPane>
                </TabContent>

                <h3 className="mt-5">Usage</h3>
                <p>
                    All data in the Cell Model Passports is publicly available and individual users are free to use the
                    API for non-commercial use without login or registration. Throttling of requests may be applied to ensure
                    service reliability and fair use.
                </p>
                <p>
                    <strong>Third Party Websites or Applications</strong><br />
                    Administrators of third-party websites that are interested in using the Cell Model Passports API
                    should contact the Passports via depmap@sanger.ac.uk. Direct use of the API in third party websites without prior permission is not permitted.
                </p>
                <p>
                    <strong>Commercial Use</strong><br />
                    Commercial use of the Cell Model Passports API and/or data is not permitted without prior consent. Please contact
                    depmap@sanger.ac.uk to for enquiries.
                </p>
                <p>
                    <strong>Educational Use</strong><br />
                    Use of the Cell Model Passports API for educational purposes is permitted. Educators interested in using
                    the Cell Model Passports API in their teachings are encouraged to contact depmap@sanger.ac.uk - especially if
                    this could result in a high volume of traffic.
                </p>
            </div>
        );
    }
}