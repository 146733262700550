import React from 'react';
import _ from 'lodash';

import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label } from "reactstrap";
import qs from "qs";
import {withRouter} from "react-router-dom";
import {urlify} from "../../utils";
import CollapseButton from '../dumb/collapseButton';
import baseFilter from "./baseFilter";

class ModelTypeFilter extends baseFilter {

    constructor(props){
        super(props);
        this.renderModelTypeCheckboxes = this.renderModelTypeCheckboxes.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.updateAPIURL = this.updateAPIURL.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);

        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });

        this.state = {
            filters: ModelTypeFilter.getActiveFiltersFromQS(current_qs, {
                "Organoid": {filter_name: "organoid", active: false},
                "Cell Line": {filter_name: "cell_line", active: false}}),
            isOpen: false,
            activeCount: 0
        }
        this.aggregates = {'model_type': 'count'}
    }

    static getDerivedStateFromProps(newprops, oldstate){
        let current_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        let newfilters = ModelTypeFilter.getActiveFiltersFromQS(current_qs, oldstate.filters);

        return {
            filters: newfilters,
            activeCount: _.filter(newfilters, 'active').length
        }
    }


    static getActiveFiltersFromQS(qs, oldfilters){
        let qs_typeFilter = ('model_type' in qs)? _.split(qs.model_type, '_'):[];

        return _.reduce(oldfilters,
            (res, filterProps, type) => {
                res[type] = filterProps;
                res[type].active = _.indexOf(qs_typeFilter, urlify(type)) >= 0;
                return res
            }, {})

    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    updateAPIURL(api_qs, newlistprops){

        let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
        let activeModelTypeFilters = ModelTypeFilter.getActiveFiltersFromQS(new_qs, this.state.filters);

        let apiModelTypeFilters = _.map(
            _.filter(
                _.toPairs(activeModelTypeFilters),
                ([tissue, filterProps]) => {
                    return filterProps.active
                }), ([tissue, filterProps]) => {
                return filterProps.filter_name
            }
        );

        if(apiModelTypeFilters.length > 0) {
            let filter_qs = {"name": "model_type", "op": "in", "val": apiModelTypeFilters};

            if ('filter' in api_qs) {
                api_qs.filter.push(filter_qs)
            } else {
                api_qs['filter'] = [filter_qs]
            }
        }

        return _.merge(api_qs, {agg: this.aggregates})
    }

    processResponse(response){

        let resp_agg = this.get_agg_values(response);
        let agg_field = _.keys(this.aggregates)[0];
        if(! resp_agg){
            return
        }

        let newfilters = _.reduce(resp_agg[agg_field][this.aggregates[agg_field]],
            (res, count, model_type) => {
                if(model_type in this.state.filters){
                    res[model_type] = this.state.filters[model_type];
                    res[model_type].count = count
                } else {
                    res[model_type] = {'count': count, active: false}
                }
                return res;
            }, {});

        this.setState({
            filters: newfilters
        })
    }

    toggleCheckbox(event){
        let model_type = urlify(event.target.name);
        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let typeFilter = ('model_type' in current_qs)? _.split(current_qs.model_type, '_'):[];

        if(event.target.checked){
            typeFilter.push(model_type)
        } else {
            typeFilter = typeFilter.filter((item) => urlify(item) !== model_type)
        }
        current_qs.model_type = (typeFilter.length > 0)? _.join(typeFilter.sort(), '_'): undefined;
        current_qs.page = undefined;
        this.props.history.push(window.location.pathname + qs.stringify(current_qs, { addQueryPrefix: true }));
    }

    renderModelTypeCheckboxes(){
        return (
            _.map(_.toPairs(this.state.filters), ([model_type, details]) => {
                return (
                    <FormGroup check key={model_type}>
                        <Input type="checkbox" name={model_type} onChange={this.toggleCheckbox} checked={_.get(details, 'active')}/>{' '}
                        <Label className="ml-4 mt-1" check>
                            {model_type} <span className="secondary">({_.get(details, 'count')})</span>
                        </Label>
                    </FormGroup>
                )}
            )
        )

    }

    componentDidMount() {
        this.props.registerResponse(this.processResponse);
        this.props.registerRequest(this.updateAPIURL);
    };

    componentWillUnmount() {
        this.props.unregisterResponse(this.processResponse);
        this.props.unregisterRequest(this.updateAPIURL);
    };

    render(){
        return(
            <Card className="genetic-filters mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline w-50">Model Types</div>
                    <div className="d-inline-block">
                        {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        {this.renderModelTypeCheckboxes()}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(ModelTypeFilter)
