import React from 'react';
import _ from 'lodash';

import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label } from "reactstrap";
import qs from "qs";
import {withRouter} from "react-router-dom";
import CollapseButton from '../dumb/collapseButton';
import baseFilter from "./baseFilter";

class MalignancyFilter extends baseFilter {

    constructor(props){
        super(props);
        this.renderMalignancyCheckboxes = this.renderMalignancyCheckboxes.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.updateAPIURL = this.updateAPIURL.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);

        this.state = {
            filters: {},
            isOpen: false,
            activeCount: 0
        };
        this.aggregates = {'sample.patient.prior_malignancies': 'count'}
    }

    static getDerivedStateFromProps(newprops, oldstate){
        let current_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        let newfilters = MalignancyFilter.getFiltersFromQS(current_qs, oldstate.filters);

        return {
          filters: newfilters,
          activeCount: _.filter(newfilters, 'active').length
      }
    }


    static getActiveFiltersFromQS(qs_obj, field, defaultValue = [], sep="_"){
        return (field in qs_obj)? _.split(_.get(qs_obj, field), sep):defaultValue;
    }

    static getFiltersFromQS(qs, oldfilters) {
      let qs_malignancy = ('malignancy' in qs)? _.split(qs.malignancy, '_'):[];

      let newfilters = _.reduce(oldfilters,
        (res, filterProps, malignancy) => {
          res[malignancy] = filterProps;
          res[malignancy].active = _.indexOf(qs_malignancy, malignancy) >= 0;
          return res
        }, {});

      return newfilters
    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    updateAPIURL(api_qs, newlistprops){

        let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
        let activeMalignancyFilters = MalignancyFilter.getActiveFiltersFromQS(new_qs, 'malignancy');

        let filter_qs = {"name":"sample","op":"has","val":{"name":"patient","op":"has","val":{"name":"prior_malignancies","op":"in","val":activeMalignancyFilters}}};

        if(activeMalignancyFilters.length > 0){
            api_qs.filter = _.get(api_qs, 'filter', []).concat(filter_qs)
        }

        return _.merge(api_qs, {agg: this.aggregates})


    }

    processResponse(response){

        let resp_agg = this.get_agg_values(response);
        let agg_field = _.keys(this.aggregates)[0];
        if(! resp_agg){
            return
        }

        let newfilters = _.reduce(resp_agg[agg_field][this.aggregates[agg_field]],
            (res, count, malignancy) => {
                if(malignancy in this.state.filters){
                    res[malignancy] = this.state.filters[malignancy];
                    res[malignancy].count = count
                } else {
                    if (malignancy !== 'None') {
                        res[malignancy] = {'count': count, active: false}
                    }
                }
                return res;
            }, {});

        this.setState({
            filters: newfilters
        })
    }

    toggleCheckbox(event){
        let malignancy = event.target.name;
        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let malignancyFilter = ('prior_malignancies' in current_qs)? _.split(current_qs.malignancy, '_'):[];

        if(event.target.checked){
            malignancyFilter.push(malignancy)
        } else {
            malignancyFilter = malignancyFilter.filter((item) => item !== malignancy)
        }
        current_qs.malignancy = (malignancyFilter.length > 0)? _.join(malignancyFilter.sort(), '_'): undefined;
        current_qs.page = undefined;
        this.props.history.push(window.location.pathname + qs.stringify(current_qs, { addQueryPrefix: true }));
    }

    renderMalignancyCheckboxes(){
        return (
            _.map(_.toPairs(this.state.filters), ([malignancy, details]) => {
                return (
                    <FormGroup check key={malignancy}>
                        <Input type="checkbox" name={malignancy} onChange={this.toggleCheckbox} checked={_.get(details, 'active')}/>{' '}
                        <Label className="ml-4 mt-1" check>
                            {malignancy} <span className="secondary">({_.get(details, 'count')})</span>
                        </Label>
                    </FormGroup>
                )}
            )
        )

    }

    componentDidMount() {
        this.props.registerResponse(this.processResponse);
        this.props.registerRequest(this.updateAPIURL);
    };

    componentWillUnmount() {
        this.props.unregisterResponse(this.processResponse);
        this.props.unregisterRequest(this.updateAPIURL);
    };

    render(){
        return(
            <Card className="malignancy-filters mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline w-50">Prior Malignancies</div>
                    <div className="d-inline-block">
                        {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        {this.renderMalignancyCheckboxes()}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(MalignancyFilter)
