import {Component} from 'react';
import _ from "lodash";

class baseFilter extends Component {

    get_agg_values(response){
        let resp_agg = {}
        for (var agg_field in this.aggregates){
            let api_agg = _.get(response, 'meta.agg["' + agg_field + '"]');
            if(this.aggregates[agg_field] in api_agg){
                resp_agg[agg_field] = api_agg
            } else{
                resp_agg[agg_field] = {}
                resp_agg[agg_field][this.aggregates[agg_field]] = api_agg
            }
        }
        return resp_agg
    }
}

export default baseFilter;