import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {
        return (
            <BaseIcon
                {...props}
                tooltipText="Mutation Data"
            >

                <path d="M505.6,209.2c0,0-10.3-17.2-11.6-13.8c-5.1,13.9-10.1,25.9-14.9,36c-42.5,89.2-76.1,42.4-148.6,18.9
                    c-3.6-1.2-7.4-2.3-11.5-3.3c0.2-18.6,1.4-35.1,5.8-49.5l23.4,40.5l30.1-17.4l-32.9-56.9c8.9-8.3,20.6-15.5,36.2-21.7l53.6,92.9
                    l30.1-17.4l-49.6-86c7-1.7,14.5-3.2,22.6-4.7L421,106.1c-132.1-16.9-137.3,65-136.7,134.7c-78.4-8.7-190.2,12.8-196.4,142.7
                    l17.4,20.7c37.8-102.4,105-139.5,179.3-135.6c0,3.7,0,7.4-0.1,10.9c-1.1,40.1,1.8,73.5-1.3,101l-23.7-41.1l-30.1,17.4l39.1,67.8
                    c-7.8,11.2-19.2,20.9-35.9,29l-54-93.5l-30.1,17.4l51,88.3c-10.2,2.8-21.5,5.4-34.2,7.7c-3.6,0.6,11.6,13.8,11.6,13.8
                    s2.1,6.4,5.8,6.9c157.9,20.2,134.5-101.3,136.5-173.3c0.5-17.1,0.2-33,0-47.7c13.3,2.8,26.8,6.7,40.3,11.7
                    c68.1,25.1,144.5,90.4,152-68.7C511.6,212.4,505.6,209.2,505.6,209.2z"/>
            </BaseIcon>
    )

}