import React , { Component } from 'react';
import {Row, Col,Card, CardBody, CardHeader, Table} from "reactstrap";
import {CNVIcon, ExomeIcon, RNASeqIcon, MetIcon,DrugIcon,CrisprKOIcon,FusionIcon,ProteomicsIcon} from "../../dumb/icons/icons";
import _ from 'lodash';
import {Link} from "react-router-dom";
import {api_base_url} from "../../../settings";
import Deserialiser from 'deserialise-jsonapi'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledTooltip } from 'reactstrap';

const infoIcon = <span className="fa-wrapper mr-2 pt-1"><FontAwesomeIcon icon={faInfoCircle} style={{height: "2rem",width: "1rem", verticalAlign: "middle", color: "#000000"}}/></span>

const des = new Deserialiser();

class RelatedModels extends Component {
    constructor(props){
        super(props);
        this.state = {}    
    }

    retrieveRelatedModels(){
        let patientId = this.props.patientId;
        let parent_id;
        let children;
        
        if(this.props.model.parent){
            parent_id = this.props.model.parent.id;
        }
        
        if(this.props.model.children){
            children = _.reduce(this.props.model.children, (res, c) => {
                res.push(c.id);
                return res;
                },[]);
        }
        
        const url = api_base_url + '/patients/' + patientId +'/models?include=sample.cancer_type,sample.tissue';

        if(!("models" in this.state)){
            console.log(this.props)
            fetch(url)
                .then(response => response.json())
                .then(models => des.deserialise(models))
                .then(models => {
                    let relatedModels = _.reduce(models, (res, model ) => {
                        if(model.id !== this.props.modelId){
                            if(model.id === parent_id){
                                model.is_parent = 1
                            }
                            
                            if(children.includes(model.id, 0) ){
                                model.is_child = 1
                            }
                           res.push(model)
                        }
                        return res;
                        },[]);

                    this.setState({
                        modelId: this.props.modelId,
                        relatedModels: relatedModels
                    });

                })
                .catch(ex => console.log('parsing failed',ex));
        }
    }

    componentDidUpdate() {
        this.retrieveRelatedModels()
    }
    componentDidMount() {
        this.retrieveRelatedModels()
    }

    shouldComponentUpdate(){
        return this.state.modelId !== this.props.modelId
    }

     render() {
        let object = {};
        if( _.isEmpty(this.state.relatedModels) ){
          return null;
        }else{
          var options = this.props.model.sample.patient.model_relations_comment.split('\n')
          var data = options.filter((val) => val.match(this.props.model.names[0]));
          object[this.props.model.names[0]] = data
          return (
                <Col md={12} className="d-flex my-3">
                  <Card className="model-info models flex-fill">
                    <CardHeader>Models Derived from the Same Patient</CardHeader>
                          <CardBody>
                          <div className="d-flex justify-content-start">
                                <b>Note:&nbsp;</b>This page's active model is highlighted, related models are displayed below.
                            </div>
                            <br/>
                              <div className="table-responsive">
                              <Table className="w-100 col-md-auto me-0">
                                  <thead>
                                  <tr>
                                      <th>Model name</th>
                                      <th>Tissue</th>
                                      <th>Cancer Type</th>
                                      <th>Age</th>
                                      <th>Tissue Status</th>
                                      <th>Sample site</th>
                                      <th>Model Relationships</th>
                                      <th className="d-none d-xl-table-cell">Datasets</th>
                                  </tr>
                                  </thead>
                                  <tbody>
                                  <tr>
                                      <td style={{backgroundColor:"#F6F2F6"}}><Link to={"/passports/" + this.props.model.id}>{this.props.model.names[0]}</Link></td>
                                      <td style={{backgroundColor:"#F6F2F6"}}>{this.props.model.sample.tissue.name}</td>
                                      <td style={{backgroundColor:"#F6F2F6"}}>{this.props.model.sample.cancer_type.name}</td>
                                      <td style={{backgroundColor:"#F6F2F6"}}>{this.props.model.sample.age_at_sampling}</td>
                                      <td style={{backgroundColor:"#F6F2F6"}}>{this.props.model.sample.tissue_status}</td>
                                      <td style={{backgroundColor:"#F6F2F6"}}>{this.props.model.sample.sample_site}</td>
                                      <td style={{backgroundColor:"#F6F2F6"}}><span
                                            href="#"
                                            id={this.props.model.id}
                                            style={{color: 'blue', textDecoration: 'underline'}}>
                                                <center>{infoIcon}</center>
                                            </span>
                                            <UncontrolledTooltip placement="right" target={this.props.model.id}>
                                                {object[this.props.model.names[0]]}
                                            </UncontrolledTooltip>
                                        </td>
                                      <td style={{backgroundColor:"#F6F2F6"}} className="icons d-none d-xl-inline-flex w-100">
                                          <ExomeIcon active={this.props.model.mutations_available} displayTooltip/>
                                          <CNVIcon active={this.props.model.cnv_available} displayTooltip/>
                                          <RNASeqIcon active={this.props.model.expression_available} displayTooltip/>
                                          <MetIcon active={this.props.model.methylation_available} displayTooltip/>
                                          <CrisprKOIcon active={this.props.model.crispr_ko_available} displayTooltip />
                                          <DrugIcon active={this.props.model.drugs_available} displayTooltip/>
                                          <FusionIcon active={this.props.model.fusions_available} displayTooltip />
                                          <ProteomicsIcon style={{height: "30px", marginTop: "5px"}} active={this.props.model.proteomics_available}  displayTooltip />
                                        </td>
                                  </tr>
                                  { _.map(this.state.relatedModels, model => {
                                  var options = this.props.model.sample.patient.model_relations_comment.split('\n')
                                  var data = options.filter((val) => val.match(model.names[0]));
                                  object[model.names[0]] = data
                                      return (
                                          <tr key={model.id}>
                                              <td> <Link to={"/passports/" + model.id}>{model.names[0]}</Link>{model.is_parent && (<span>  ( Parent model )</span>)}{model.is_child && (<span>  ( Child model )</span>)}</td>
                                              <td>{model.sample.tissue.name}</td>
                                              <td>{model.sample.cancer_type.name}</td>
                                              <td>{model.sample.age_at_sampling}</td>
                                              <td>{model.sample.tissue_status}</td>
                                              <td>{model.sample.sample_site}</td>
                                              <td><span
                                                   href="#"
                                                   id={model.id}
                                                   style={{color: 'blue', textDecoration: 'underline'}}>
                                                       <center>{infoIcon}</center>
                                                   </span>
                                                   <UncontrolledTooltip placement="right" target={model.id}>
                                                     {object[model.names[0]]}
                                                   </UncontrolledTooltip>
                                              </td>
                                              <td className="icons d-none d-xl-inline-flex">
                                                  <ExomeIcon active={model.mutations_available} displayTooltip/>
                                                  <CNVIcon active={model.cnv_available} displayTooltip/>
                                                  <RNASeqIcon active={model.expression_available} displayTooltip/>
                                                  <MetIcon active={model.methylation_available} displayTooltip/>
                                                  <CrisprKOIcon active={this.props.model.crispr_ko_available} displayTooltip />
                                                  <DrugIcon active={model.drugs_available} displayTooltip/>
                                                  <FusionIcon active={this.props.model.fusions_available} displayTooltip />
                                                  <ProteomicsIcon style={{height: "30px", marginTop: "5px"}} active={this.props.model.proteomics_available}  displayTooltip />
                                              </td>
                                          </tr>
                                      )
                                  })}
                                  </tbody>
                              </Table>
                              </div>
                          </CardBody>
                      </Card>
                     </Col>
            );
        }
     }

    mapModelToTbl = (models) => {
        return (
            _.map(models, (model) => {
                var options = this.props.model.sample.patient.model_relations_comment.split('\n')
                var data = options.filter((val) => val.match(model.names[0]));
                object[model.names[0]] = data
                return (
                    <Row key={model.id}>
                        <Col>
                            <p>
                            <span className="list-value">
                                <Link to={"/passports/" + model.id}>{model.names[0]}</Link>{model.is_parent && (<span>  ( Parent model )</span>)}{model.is_child && (<span>  ( Child model )</span>)}
                            </span>
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <span className="list-value">{model.sample.tissue.name}</span>
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <span className="list-value">{model.sample.cancer_type.name}</span>
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <span className="list-value">{model.sample.age_at_sampling}</span>
                            </p>
                        </Col>

                        <Col>
                            <p>
                                <span className="list-value">{model.sample.tissue_status}</span>
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <span className="list-value">{model.sample.sample_site}</span>
                            </p>
                        </Col>
                        <Col>
                            <p>
                                <span className="list-value">{object[model.names[0]]}</span>
                            </p>
                        </Col>

                        <Col className="icons d-inline-flex">
                            <ExomeIcon active={model.mutations_available} displayTooltip/>
                            <CNVIcon active={model.cnv_available} displayTooltip/>
                            <RNASeqIcon active={model.expression_available} displayTooltip/>
                            <MetIcon active={model.methylation_available} displayTooltip/>
                            <CrisprKOIcon active={this.props.model.crispr_ko_available} displayTooltip />
                            <DrugIcon active={model.drugs_available} displayTooltip/>
                            <FusionIcon active={this.props.model.fusions_available} displayTooltip />
                            <ProteomicsIcon style={{height: "30px", marginTop: "5px"}} active={this.props.model.proteomics_available}  displayTooltip />
                        </Col>
                    </Row>
                )
            })
        )
    }
}

export default RelatedModels;
