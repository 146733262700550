import React, { Component } from 'react';
import {ListGroupItem} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import {
    CNVIcon,
    DrugIcon,
    ExomeIcon,
    RNASeqIcon,
    MetIcon,
    PatientIcon,
    CrisprKOIcon,
    FusionIcon,
    ProteomicsIcon
} from "../icons/icons";

import _get from 'lodash/get';

const check = <span className="fa-wrapper mr-2 pt-1"><FontAwesomeIcon icon={faCheck} className="text-success" /></span>
const cross = <span className="fa-wrapper mr-2 pt-1"><FontAwesomeIcon icon={faTimes} className="text-danger" /></span>

class DatasetListItem extends Component {
    constructor(props) {
        super(props);
        this.toggle = this.toggle.bind(this);
        this.state = { collapse: true };
    }

    toggle() {
        this.setState({ collapse: !this.state.collapse });
    }

    getIcon(ds_type){
        switch(ds_type){
            case "drugs":
                return <DrugIcon style={{height: "26px"}}/>;
            case "mutations":
                return <ExomeIcon style={{height: "26px"}}/>;
            case "expression":
                return <RNASeqIcon style={{height: "26px"}}/>;
            case "cnv":
                return <CNVIcon style={{height: "26px"}}/>;
            case "methylation":
                return <MetIcon style={{height: "26px"}}/>;
            case "patient":
                return <PatientIcon style={{height: "26px"}}/>;
            case "crispr_ko":
                return <CrisprKOIcon style={{height: "26px"}}/>;
            case "fusions":
                return <FusionIcon style={{height: "26px"}}/>;
            case "proteomics":
                return <ProteomicsIcon style={{height: "22px", marginRight: "4px", marginTop: "5px"}}/>;
            default:
                return "";
        }
    }

    getFieldName(ds_type){
        switch(ds_type){
            case "drugs":
                return "Drug data";
            case "mutations":
                return "Mutation data";
            case "expression":
                return "RNA-Seq data";
            case "cnv":
                return "CNV data";
            case "methylation":
                return "Methylation data";
            case "patient":
                return "Clinical information";
            case "crispr_ko":
                return "CRISPR data";
            case "fusions":
                return "Fusion data";
            case "proteomics":
                return "Proteomics data";
            default:
                return "";
        }
    }

    render() {
        let icon = (_get(this.props.model, this.props.field + '_available'))?check:cross;
        return (
            <ListGroupItem>
                <div className={"d-flex flex-column"}>
                    <div className={"d-flex flex-row"}>
                        {icon} {this.getIcon(this.props.field)} <span className="dataset-title">{this.getFieldName(this.props.field)}</span>
                    </div>

                </div>
            </ListGroupItem>
        );
    }
}

export default DatasetListItem;