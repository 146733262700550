import React, {Component} from 'react';
import _ from 'lodash';
import {Col, Card, CardBody,CardHeader} from "reactstrap";
import {api_base_url} from "../../../settings";
import Deserialiser from 'deserialise-jsonapi'
import ReactWordCloud from 'react-wordcloud';
import Loading from '../../dumb/loading';
const des = new Deserialiser();

class ModelCancerDrivers extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.loadIdentifiers = this.loadIdentifiers.bind(this)
    }

    loadIdentifiers(){
        const model_id = this.props.modelId;
        const url = `${api_base_url}/models/${model_id}/datasets/mutation_cnv_drivers/by_cancer_type_id?include=gene&page[size]=0`;

        fetch(url)
            .then(response => response.json())
            .then(resp_obj => {
                return des.deserialise(resp_obj)
            })
            .then(mutations => {
                // combine the mutation by gene symbol
                let combined_genes = _.reduce(mutations,(genes, g)=>{
                        let exist_gene = _.find(genes, d => {
                           return d.gene.symbol === g.gene.symbol;
                        });
                        if(!exist_gene){
                            // copy the object and push to array
                            genes.push(Object.assign({},g));
                        }else{
                            if(g.type !== 'CNA' && g.aa_mut != null) {
                                exist_gene.aa_mut = exist_gene.aa_mut + ": " + g.aa_mut;
                            }
                            if(g.type === 'CNA' && g.total_copy_number !== null) {
                                exist_gene.total_copy_number = exist_gene.total_copy_number + ": " + g.total_copy_number;
                            }
                        }
                        return genes;
                    },[]);
                    this.setState({
                        currentModelId: this.props.modelId,
                        mutations: combined_genes,
                        load_complete: 1
                    })
                }
            ).catch(function (ex) {
            console.log('parsing failed', ex);
        });
    }

    componentDidMount() {
        this.loadIdentifiers()
    }

    componentDidUpdate() {
        if(this.props.modelId !== this.state.currentModelId) {
            this.loadIdentifiers()
        }
    }

    render() {
        if (!this.state.load_complete ) {
             return (<div className="py-5" > <Loading subtitle="Loading cancer drivers" /> </div>)
        }else if ( this.state.load_complete && _.isEmpty(this.state.mutations) && this.props.mutsAvail) {
            return (
                 <Col className="my-3" md={6} xl={6}>
                    <Card className="flex-fill models">
                        <CardHeader>Cancer Drivers</CardHeader>
                           <CardBody>
                            <div style={{height: "550px", width: "100%"}}>
                                <br /><br /><br /><br /><br /><br /><br /><br /><center>No mutated cancer driver genes available for this model.</center>
                            </div>
                            <div className="msg" style={{height: "220px", width: "100%"}}>
                                See <a href="https://depmap.sanger.ac.uk/documentation/datasets/gene-annotation-mapping/" target="_blank" rel="noopener noreferrer">cancer driver list documentation</a> for annotation details.
                            </div>
                        </CardBody>
                     </Card>
                 </Col>
            );
        } else if (this.props.mutsAvail) {
            return (
                 <Col className="my-3" md={6} xl={6}>
                    <Card className="flex-fill models d-inline-block">
                        <CardHeader>Cancer Drivers</CardHeader>
                           <CardBody>
                            <div style={{height: "540px", width: "100%"}}>
                                <ReactWordCloud
                                    words={this.state.mutations}
                                    wordCountKey='model_count'
                                    wordKey='gene.symbol'
                                    scale='sqrt'
                                    minFontSize={20}
                                    maxFontSize={110}
                                    enableTooltip={true}
                                    onSetTooltip={(mut) => {
                                        if(mut.type === 'CNA' && mut.total_copy_number !== 0){
                                            return mut.gene.symbol + (mut.total_copy_number? ": " + mut.total_copy_number: "")
                                        }else if(mut.type === 'CNA' && mut.total_copy_number === 0){
                                            return mut.gene.symbol + (": " + mut.total_copy_number)
                                        }else{
                                            return mut.gene.symbol + (mut.aa_mut? ": " + mut.aa_mut: "")
                                        }
                                        }}

                                    colorScale={(mut, index) =>{
					                    
					                    if (mut.gene.method_of_action === 'Act' && mut.type !== 'CNA') {
					                        return ("#3dae4b") 
					                    }else if (mut.gene.method_of_action === 'LoF' && mut.type !== 'CNA') {
                                            return ("#0094b5")
                                        }else if (mut.gene.method_of_action === 'ambiguous' && mut.type !== 'CNA') {
                                            return ("#f5681f")
				                        }else if (mut.cn_category === 'Amplification') {
                                            return ("#8fd698")
                                        }else if (mut.cn_category === 'Deletion') {
                                            return ("#73c4d6")
					                    }

                                    }}


                                />

                            </div>
                            <div className="msg_s" style={{height: "240px", width: "100%"}}>
                                <strong>Note: </strong>Somatic driver and germline predisposition variants are shown. The mutations colour coding is used when copy number alterations are also present in the same gene. The frequency of the mutation within this model's cancer type sets the font size. In models with many driver variants the least frequently mutated genes may not be displayed.<br /><br />
                                    Mutations (Driver Gene Method of Action)<br/>
                                    <span className="green-dot"></span> Gain of function<br />
                                    <span className="blue-dot"></span> Loss of function<br />
                                    <span className="orange-dot"></span> Ambiguous function<br /><br />
				                    Copy Number Alterations (CNA Call)<br/>
                                    <span className="light-green-dot"></span> Amplification of a driver gene<br />
                                    <span className="light-blue-dot"></span> Deletion of a driver gene<br /><br />
                                See <a href="https://depmap.sanger.ac.uk/documentation/datasets/gene-annotation-mapping/" target="_blank" rel="noopener noreferrer">cancer driver list documentation</a> and <a href="https://depmap.sanger.ac.uk/documentation/datasets/copy-number/" target="_blank" rel="noopener noreferrer">copy number documentation</a> for annotation details.
                            </div>
                        </CardBody>
                     </Card>
                 </Col>
            );
        } else {
            return null;
        }

    }
}

export default ModelCancerDrivers;

