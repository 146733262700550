import React from "react";
import { CSVExport} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTable } from '@fortawesome/free-solid-svg-icons';

const downloadIcon = <span className="fa-wrapper mr-2 pt-1"><FontAwesomeIcon icon={faTable}/></span>

const {ExportCSVButton} = CSVExport;

export default (props) => {
    return (
        <ExportCSVButton {...props} className="btn btn-link secondary" >
            Export CSV {downloadIcon}
        </ExportCSVButton>
    )
}
