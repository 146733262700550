import React, { Component } from 'react';
import {ListGroupItem} from 'reactstrap';
import Dataset from './datasetsListDataset';
import {CNVIcon, DrugIcon, ExomeIcon, ExprIcon, RNASeqIcon, MetIcon, PatientIcon, FusionIcon, ProteomicsIcon} from "../../dumb/icons/icons";

class DatasetListItem extends Component {

    getIcon(ds_type){
        switch(ds_type){
            case "drug":
                return <DrugIcon style={{height: "30px"}}/>;
            case "mutation":
            case "Whole Genome Sequencing":
            case "Whole Exome Sequencing":
            case "Targeted Gene Sequencing":
                return <ExomeIcon style={{height: "30px"}}/>;
            case "expression":
            case "Microarray Gene Expression":
                return <ExprIcon style={{height: "30px"}} />;
            case "RNASeq":
                return <RNASeqIcon style={{height: "30px"}} />;
            case "cna":
            case "Copy Number Variation":
                return <CNVIcon style={{height: "30px"}}/>;
            case "methylation":
            case "DNA Methylation":
                return <MetIcon style={{height: "30px"}}/>;
            case "patient":
                return <PatientIcon style={{height: "30px"}}/>;
            case "Fusion data":
                return <FusionIcon style={{height: "30px", marginTop: "-3px"}}/>;
            case "Proteomics":
                return <ProteomicsIcon style={{height: "24px", marginRight: "4px"}}/>;
            default:
                return "";
        }
    }

    render() {
        return (
            <ListGroupItem>
                <div className={"d-flex flex-column"}>
                    <div className={"d-flex flex-row"}>
                        {this.getIcon(this.props.type)}
                        <div>
                            <span>{this.props.header}</span>
                        </div>
                    </div>

                    <div className="dataset-list">
                        {this.props.datasets.map(ds => {

                            return <Dataset key={ds.id} file={ds} />
                        })}
                    </div>
                </div>
            </ListGroupItem>
        );
    }
}

export default DatasetListItem;