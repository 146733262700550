import React, {Fragment} from 'react';
import _ from "lodash";

import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label } from "reactstrap";

import qs from 'qs';
import {withRouter} from 'react-router-dom';
import {urlify} from "../../utils";
import CollapseButton from '../dumb/collapseButton';
import baseFilter from './baseFilter';


class CancerTypeFilter extends baseFilter {
    constructor(props){
        super(props);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.updateAPIURL = this.updateAPIURL.bind(this);

        this.state = {
            isOpen: false,
            filters: {}
        };
        this.aggregates = {'sample.cancer_type.name': 'count'}
    }

    updateAPIURL(current_api_qs, newlistprops){
        let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
        let qs_cancerFilter = ('cancer_type' in new_qs)? _.split(new_qs.cancer_type, '_'):[];

        let api_cancer_filters = _.map(
            _.filter(
                _.toPairs(this.state.filters),
                ([cancer_type, filterProps]) => {
                    return qs_cancerFilter.indexOf(urlify(cancer_type)) >= 0
                }), ([cancer_type, filterProps]) => {
                return cancer_type
            }
        );

        let filter_qs = {"name":"sample","op":"has","val":{"name":"cancer_type","op":"has","val":{"name":"name","op":"in","val":api_cancer_filters}}};

        if(api_cancer_filters.length > 0){
            if('filter' in current_api_qs){
                current_api_qs.filter.push(filter_qs)
            } else {
                current_api_qs['filter'] = [filter_qs]
            }
        }

        return _.merge(current_api_qs, {agg: this.aggregates})
    }

    processResponse(response){
        let resp_agg = this.get_agg_values(response);
        let agg_field = _.keys(this.aggregates)[0];
        if(! resp_agg){
            return
        }

        let newfilters = _.reduce(resp_agg[agg_field][this.aggregates[agg_field]],
            (res, count, cancer_type) => {
                if(cancer_type in this.state.filters){
                    res[cancer_type] = this.state.filters[cancer_type];
                    res[cancer_type].count = count
                } else {
                    res[cancer_type] = {'count': count, active: false}
                }
                return res
            }, {});

        this.setState({
            filters: newfilters
        })
    }

    static getFiltersFromQS(qs, oldfilters) {
        let qs_cancer_type_Filter = ('cancer_type' in qs)? _.split(qs.cancer_type, '_'):[];

        return _.reduce(oldfilters,
            (res, filterProps, cancer_type) => {
                res[cancer_type] = filterProps;
                res[cancer_type].active = _.indexOf(qs_cancer_type_Filter, urlify(cancer_type)) >= 0;
                return res
            }, {});
    }


    static getDerivedStateFromProps(newprops, oldstate){
        let current_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        let newfilters = CancerTypeFilter.getFiltersFromQS(current_qs, oldstate.filters);

        return {
            filters: newfilters,
            activeCount: _.filter(newfilters, 'active').length
        }
    }

    toggleCheckbox(event){
        let cancer_type = urlify(event.target.name);
        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let cancerFilter = ('cancer_type' in current_qs)? _.split(current_qs.cancer_type, '_'):[];

        if(event.target.checked){
            cancerFilter.push(cancer_type)
        } else {
            cancerFilter = cancerFilter.filter((item) => urlify(item) !== cancer_type)
        }
        current_qs.cancer_type = (cancerFilter.length > 0)? _.join(cancerFilter.sort(), '_'): undefined;
        current_qs.page = undefined;
        this.props.history.push(window.location.pathname + qs.stringify(current_qs, { addQueryPrefix: true }))

    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    renderCancerTypeCheckboxes(){
        if(! _.isUndefined(this.state.filters)) {

            let active = [],
                inactive = [];

            _.map(_.toPairs(this.state.filters), ([key, filterProps]) => {
                let cb = (
                    <FormGroup check key={key}>
                        <Input type="checkbox" name={key} onChange={this.toggleCheckbox} checked={filterProps.active}/>{' '}
                        <Label className="ml-4 mt-1" check>
                            {key} {filterProps.count > 0 && (<span className="secondary">({filterProps.count})</span>)}
                        </Label>
                    </FormGroup>);
                if (filterProps.active) {
                    active.push(cb)
                } else {
                    inactive.push(cb)

                }
            });

            active = _.sortBy(active, 'key');
            inactive = _.sortBy(inactive, 'key');

            if(active.length < 5 && active.length + inactive.length > 0) {
                active.push(inactive.splice(0, 5 - active.length ))
            }


            return (
                <Fragment>
                    {active}
                    {inactive}
                </Fragment>)
        }

    }

    componentDidMount () {
        this.props.registerResponse(this.processResponse);
        this.props.registerRequest(this.updateAPIURL);
    };

    componentWillUnmount () {
        this.props.unregisterResponse(this.processResponse);
        this.props.unregisterRequest(this.updateAPIURL);
    };

    render() {
        return (
            <Card className="mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline-block w-50">
                        Cancer Types
                    </div>
                    <div className="d-inline-block">
                         {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        {this.renderCancerTypeCheckboxes()}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(CancerTypeFilter);