import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {

    return (
        <BaseIcon
            {...props}
            tooltipText="Gene Expression Data"
        >
                <polygon points="129,471 129,111 111,111 111,489 119,489 129,489 488,489 488,471 "/>
                <rect x="240" y="293" width="67" height="67"/>
                <rect x="240" y="111" width="67" height="67"/>
                <rect x="240" y="386" width="67" height="67"/>
                <rect x="330" y="203" width="67" height="67"/>
                <rect x="330" y="293" width="67" height="67"/>
                <rect x="330" y="111" width="67" height="67"/>
                <rect x="330" y="386" width="67" height="67"/>
                <rect x="422" y="203" width="67" height="67"/>
                <rect x="422" y="111" width="67" height="67"/>
                <rect x="422" y="386" width="67" height="67"/>
                <rect x="147" y="203" width="67" height="67"/>
                <rect x="147" y="293" width="67" height="67"/>
                <rect x="147" y="111" width="67" height="67"/>
        </BaseIcon>
    )

}