import React, {Fragment} from 'react';
import _ from "lodash"
import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label } from "reactstrap";
import qs from 'qs'
import { withRouter } from 'react-router-dom'

import {urlify} from "../../utils";
import CollapseButton from '../dumb/collapseButton';
import baseFilter from "./baseFilter";

class TissueFilter extends baseFilter{
    constructor(props){
        super(props);
        this.state = {
          isOpen: false,
          filters: {}
         };
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.updateAPIURL = this.updateAPIURL.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);
        this.aggregates = {'sample.tissue.name': 'count'}
    }

    updateAPIURL(current_api_qs, newlistprops){

      let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
      let qs_tissueFilter = ('tissue' in new_qs)? _.split(new_qs.tissue, '_'):[];

      let api_tissue_filters = _.map(
        _.filter(
          _.toPairs(this.state.filters),
          ([tissue, filterProps]) => {
            return qs_tissueFilter.indexOf(urlify(tissue)) >= 0
        }), ([tissue, filterProps]) => {
          return tissue
        }
      );

      let filter_qs = {"name":"sample","op":"has","val":{"name":"tissue","op":"has","val":{"name":"name","op":"in","val":api_tissue_filters}}};

      if(api_tissue_filters.length > 0){
        if('filter' in current_api_qs){
          current_api_qs.filter.push(filter_qs)
        } else {
          current_api_qs['filter'] = [filter_qs]
        }
      }

      return _.merge(current_api_qs, {agg: this.aggregates})
    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    processResponse(response){
        let resp_agg = this.get_agg_values(response);
        let agg_field = _.keys(this.aggregates)[0];
        if(! resp_agg){
            return
        }

        let newfilters = _.reduce(resp_agg[agg_field][this.aggregates[agg_field]],
            (res, count, tissue) => {
              if(tissue in this.state.filters){
                res[tissue] = this.state.filters[tissue];
                res[tissue].count = count
              } else {
                res[tissue] = {'count': count, active: false}
              }
              return res
            }, {});

        this.setState({
            filters: newfilters
        })
    }

    static getFiltersFromQS(qs, oldfilters) {
      let qs_tissueFilter = ('tissue' in qs)? _.split(qs.tissue, '_'):[];

      let newfilters = _.reduce(oldfilters,
        (res, filterProps, tissue) => {
          res[tissue] = filterProps;
          res[tissue].active = _.indexOf(qs_tissueFilter, urlify(tissue)) >= 0;
          return res
        }, {});

      return newfilters
    }


    static getDerivedStateFromProps(newprops, oldstate){
      let current_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
      let newfilters = TissueFilter.getFiltersFromQS(current_qs, oldstate.filters);

      return {
          filters: newfilters,
          activeCount: _.filter(newfilters, 'active').length
      }
    }

    toggleCheckbox(event){
        let tissue = urlify(event.target.name);
        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let tissueFilter = ('tissue' in current_qs)? _.split(current_qs.tissue, '_'):[];

        if(event.target.checked){
          tissueFilter.push(tissue)
        } else {
          tissueFilter = tissueFilter.filter((item) => urlify(item) !== tissue)
        }
        current_qs.tissue = (tissueFilter.length > 0)? _.join(tissueFilter.sort(), '_'): undefined;
        current_qs.page = undefined;
        this.props.history.push(window.location.pathname + qs.stringify(current_qs, { addQueryPrefix: true }))

    }

    renderTissueCheckboxes(){
        if(! _.isUndefined(this.state.filters)) {

            let active = [],
                inactive = [];

            _.map(_.toPairs(this.state.filters), ([key, filterProps]) => {
                let cb = (
                            <FormGroup check key={key}>
                                <Input type="checkbox" name={key} onChange={this.toggleCheckbox} checked={filterProps.active}/>{' '}
                                <Label className="ml-4 mt-1" check>
                                    {key} {filterProps.count > 0 && (<span className="secondary">({filterProps.count})</span>)}
                                </Label>
                            </FormGroup>);
                if (filterProps.active) {
                    active.push(cb)
                } else {
                    inactive.push(cb)

                }
            });

            active = _.sortBy(active, 'key');
            inactive = _.sortBy(inactive, 'key');

            if(active.length < 5 && active.length + inactive.length > 0) {
                active.push(inactive.splice(0, 5 - active.length ))
            }


            return (
                <Fragment>
                    {active}
                    {inactive}
                </Fragment>)
        }

    }

    componentDidMount () {
        this.props.registerResponse(this.processResponse);
        this.props.registerRequest(this.updateAPIURL);
    };

    componentWillUnmount () {
        this.props.unregisterResponse(this.processResponse);
        this.props.unregisterRequest(this.updateAPIURL);
    };

    render() {
        return (
            <Card className="mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline w-50">Tissues</div>
                    <div className="d-inline-block">
                        {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        {this.renderTissueCheckboxes()}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(TissueFilter)
