import React from 'react'
import {Button, Card, CardBody, CardFooter, CardImg, CardText, CardTitle} from "reactstrap";
import { Link } from "react-router-dom";


export default (props) => {

    let cls = props.className + " " + props.project.toLowerCase() || props.project.toLowerCase();

    let title_text = ""
    if (props.titleText) {
        title_text = props.titleText
    } else if (props.notInDepmap){
        title_text = props.project
    } else {
        title_text = <span>DepMap <span className={"col-" + cls}> | </span> {props.project}</span>
    }

    let buttonText = props.buttonText || 'View programme';

    return(
        <Card className={cls + " z1"}>
            {props.img && (<CardImg top width="100%" src={props.img} alt={props.project} />)}
            <CardBody className="card-body">
                <CardTitle><Link to={props.link}>{title_text}</Link></CardTitle>
                <CardText className="secondary">{props.children}</CardText>
            </CardBody>
            <CardFooter className="text-right">
                <Button tag="a" href={props.link} className="z0 hover" target="_blank">
                    {buttonText}
                </Button>
            </CardFooter>
        </Card>
    )
}