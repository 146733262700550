import React, { Component } from 'react';
import TissueFilter from "./tissueFilter";
import GeneticFilter from "./geneticFilter";
import DatasetFilter from "./datasetFilter";
import ModelTypeFilter from "./modelTypeFilter"
import TissueStatusFilter from "./tissueStatusFilter";
import CancerTypeFilter from "./cancerTypeFilter";
import MSIStatusFilter from "./MSIFilter";
import CommercialFilter from "./commercialFilter";
import CpvFilter from "./cpvFilter";

class Filters extends Component{
    render() {
        return (
            <div className="filter-wrapper">
                <TissueFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <CancerTypeFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <ModelTypeFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <MSIStatusFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <TissueStatusFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <GeneticFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <CpvFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <DatasetFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <CommercialFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>

            </div>
        )
    }
}

export default Filters;
