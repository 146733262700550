import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';

import Wrapper from './wrapper';

class App extends Component {

  render() {
    return (
        <BrowserRouter>
            <Wrapper/>
        </BrowserRouter>
    );
  }
}


export default App;
