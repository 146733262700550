import React, {Component} from 'react';
import PageTitle from '../dumb/pageTitle';

class Links extends Component {

    render(){
        return(<PageTitle title="Links"/>)
    }

}

export default Links;