import React, { Component } from 'react';
import {withRouter} from "react-router-dom";
import _ from 'lodash'

import 'react-vis/dist/style.css'
import {
    XYPlot,
    XAxis,
    YAxis,
    HorizontalGridLines,
    VerticalGridLines,
    VerticalBarSeries,
} from 'react-vis';

import {api_base_url} from "../../settings";
import {sort_order} from "../../utils";


class DatasetAvailabilityChart extends Component{

    state = {};

    settings = {
        width: 700,
        height: 400,
        title: "Dataset Availability Overview",
        chartSeries: [{field: 'count', name: 'Count'}],
        x: 'label',
        y: 'count',
        label: 'label',
        dataset: 'datatype',
        xScale: 'ordinal',
        xLabel: 'Datasets',
        yLabel: 'Number of Models',
    };

    componentDidMount(){
        fetch(api_base_url + "/availability_data")
            .then(resp => resp.json())
            .then(items => _.sortBy(items, (item) => sort_order(item.label)))
            .then(resp => {
                let result = resp.map((item, i) => {
                    return {
                        x: item[this.settings.x],
                        y: item[this.settings.y],
                        label: item[this.settings.label],
                        dataset: item[this.settings.dataset],
                        color: i + ""
                    }
                });
                this.setState({data: result})
            })
            .catch(error => console.error(error))

    }

    handleClick = (source) =>{
        this.props.history.push("/passports?datasets=" + source.dataset)
        window.scrollTo(0,0)
    };
    handleMouseOver = (source) => {
        this.setState({hovered: source.x})
    };

    render() {

        if(!this.state.data){
            return null
        };
        return(
            <XYPlot
                xType="ordinal"
                width={450}
                height={350}
                colorRange={["#6A2E71", "#C8B3CB"]}
                style={{padding: "0 0 0 25px"}}
            >
                <VerticalGridLines />
                <HorizontalGridLines />
                <XAxis />
                <YAxis title="Number of Models"
                       style={{title: {transform: "rotate(-45deg) translate(-52px, -10px)", fontSize: "1rem", fontWeight: 400}}}/>
                <VerticalBarSeries
                    data={this.state.data}
                    onValueClick={this.handleClick}
                    onValueMouseOver={this.handleMouseOver}
                    onValueMouseOut={() => this.setState({hovered: null})}
                    className="dataset-bar"
                />

            </XYPlot>
        )
    }

}

export default withRouter(DatasetAvailabilityChart);