import React, { useState } from 'react';
import {
    CNVIcon,
    ExomeIcon,
    RNASeqIcon,
    CrisprKOIcon,
    FusionIcon,
    DrugIcon,
    MetIcon,
    PatientIcon,
    ModelIcon,
    GeneIcon,
    ProteomicsIcon
    
} from '../icons/icons';

import {Collapse, Button, CardBody, Card} from "reactstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

import _ from 'lodash'

import DownloadGroup from './downloadGroup';

const infoIcon = <span className="fa-wrapper mr-2 pt-1"><FontAwesomeIcon icon={faInfoCircle} style={{height: "2.5rem",width: "1.5rem", verticalAlign: "middle", color: "#000000"}}/></span>
const infoDocs = <a href="https://depmap.sanger.ac.uk/documentation/" target="_blank" rel="noopener noreferrer">Documentation</a>

let getIcon = (icon) => {
    switch(icon){
        case "drugs":
            return <DrugIcon style={{height: "2.5rem", verticalAlign: "middle"}}/>;
        case "model":
            return <ModelIcon style={{marginTop: '0.8rem', height: "2.5rem", verticalAlign: "middle"}}/>;
        case "gene":
            return <GeneIcon style={{marginTop: '1.4rem', height: "2.5rem", verticalAlign: "middle"}}/>;
        case "mutation":
        case "mutations":
            return <ExomeIcon style={{height: "2.5rem", verticalAlign: "middle"}}/>;
        case "expression":
            return <RNASeqIcon style={{height: "2.5rem", verticalAlign: "middle"}}/>;
        case "cnv":
            return <CNVIcon style={{height: "2.5rem", verticalAlign: "middle"}}/>;
        case "methylation":
            return <MetIcon style={{height: "2.5rem", verticalAlign: "middle"}}/>;
        case "patient":
            return <PatientIcon style={{height: "2.5rem", verticalAlign: "middle"}}/>;
        case "crispr_ko":
            return <CrisprKOIcon style={{height: "2.5rem", verticalAlign: "middle"}}/>;
        case "fusion":
        case "fusions":
            return <FusionIcon style={{height: "2.5rem", verticalAlign: "middle", stroke: "#212529"}}/>;
        case "proteomics":
            return <ProteomicsIcon style={{height: "2.5rem", verticalAlign: "middle"}}/>;
        default:
            return "";
    }
};

let getDocs = (icon) => {
    switch(icon){
        case "drugs":
            return <a href="https://depmap.sanger.ac.uk/documentation/gdsc" target="_blank" rel="noopener noreferrer">Documentation</a>;
        case "mutation":
        case "mutations":
            return <a href="https://depmap.sanger.ac.uk/documentation/datasets/mutation" target="_blank" rel="noopener noreferrer">Documentation</a>;
        case "expression":
            return <a href="https://depmap.sanger.ac.uk/documentation/datasets/expression" target="_blank" rel="noopener noreferrer">Documentation</a>;
        case "cnv":
            return <a href="https://depmap.sanger.ac.uk/documentation/datasets/copy-number" target="_blank" rel="noopener noreferrer">Documentation</a>;
        case "methylation":
            return <a href="https://depmap.sanger.ac.uk/documentation/datasets/methylation" target="_blank" rel="noopener noreferrer">Documentation</a>;
        case "patient":
            return <a href="https://depmap.sanger.ac.uk/documentation/datasets" target="_blank" rel="noopener noreferrer">Documentation</a>;
        case "crispr_ko":
            return <a href="https://depmap.sanger.ac.uk/documentation/datasets/wg-crispr-knockout" target="_blank" rel="noopener noreferrer">Documentation</a>;
        case "fusion":
        case "fusions":
            return <a href="https://depmap.sanger.ac.uk/documentation/datasets/fusions" target="_blank" rel="noopener noreferrer">Documentation</a>;
        case "proteomics":
            return <a href="https://depmap.sanger.ac.uk/documentation/datasets/proteomics" target="_blank" rel="noopener noreferrer">Documentation</a>;
        case "multiomics":
            return <a href="https://doi.org/10.1101/2024.06.26.600742" target="_blank" rel="noopener noreferrer">Documentation</a>;
        default:
            return "";
    }
};

export default function(props){

    let c = props.category;
    
    let icon = null;
    let docs = null;

    if(c.icon){
        icon = (
            <div className="d-inline-block">
                {getIcon(c.icon)}
            </div>
        )
        docs = getDocs(c.icon)

    }else{
        icon = (<div className="d-inline-block">{infoIcon}</div>)
        docs = infoDocs
    }

    const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);
    
    return(
        <div>
      <Button color="primary" onClick={toggle} style={{ marginBottom: '1rem', height: '5rem', width: '50rem', textAlign:'left'}}>{icon} {c.name}</Button>
      <Collapse isOpen={isOpen}>
        <Card>
          <CardBody>
          <div class="d-flex justify-content-end">{docs}</div>
          {_.sortBy(c.groups, 'order').map(g => <DownloadGroup group={g} key={g.id} />)}
          </CardBody>
        </Card>
      </Collapse>
    </div>
    )
}