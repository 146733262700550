import React from 'react';
import {UncontrolledTooltip} from 'reactstrap';

export default function (props) {
    if(!props.column){
        return null
    }
    let iden = `column_${props.column.dataField.replace(".", "_")}`;
    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <span id={iden}>{props.column.text}</span>
            {props.filterElement}
            {props.sortElement}
            <UncontrolledTooltip placement="top"
                                 target={iden}>
                {props.column.tooltipText}
            </UncontrolledTooltip>
        </div>
    );
}