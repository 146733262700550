import React, { Component } from 'react';
import StageFilter from "./stageFilter";
import MetastasisFilter from "./metastasisFilter";
import AlcoholFilter from "./alcoholFilter";
import SmokingFilter from "./smokingFilter";
import GenderFilter from "./genderFilter";
import TreatmentFilter from "./treatmentFilter";
import MalignancyFilter from "./malignancyFilter";
import AgeFilter from "./ageFilter";

class ClinicalFilters extends Component{
    render() {
        return (
            <div className="filter-wrapper">
                <TreatmentFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <MetastasisFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <StageFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <MalignancyFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <AlcoholFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <SmokingFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <AgeFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
                <GenderFilter
                    registerRequest={this.props.registerRequest}
                    registerResponse={this.props.registerResponse}
                    unregisterRequest={this.props.unregisterRequest}
                    unregisterResponse={this.props.unregisterRequest}/>
            </div>
        )
    }
}

export default ClinicalFilters;
