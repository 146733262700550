import React from 'react';
import {Col, ListGroup} from "reactstrap";
import DatasetListItem from "./datasetsListItem";
import {sort_order} from "../../../utils";
import _ from 'lodash';

export default (props) => {

    let fields = ["mutations", "cnv", "expression", "methylation", "drugs", "crispr_ko", "fusions", "proteomics"]

    let sorted = _.sortBy(fields, sort_order)

    return (
        <Col lg="4" className="datasets mt-4" style={{minHeight: '100px'}}>
            <div className="subheader d-flex flex-row">
                <h3>Datasets</h3>
            </div>
            <ListGroup>

                {sorted.map((ds, i) => {return <DatasetListItem key={i} field={ds} model={props.model} />})}

            </ListGroup>
        </Col>
    )
}
