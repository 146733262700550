import React, {Component, Fragment} from 'react';
import {Col, Container, Row} from "reactstrap";
import DownloadCategory from '../dumb/downloads/downloadCategory';
import {api_base_url} from "../../settings";

import Deserialiser from 'deserialise-jsonapi'
import Loading from "../dumb/loading";
import _ from 'lodash';
import {api_fetch} from "../../utils";

const des = new Deserialiser();


class Downloads extends Component {

    constructor(props){
        super(props);
        this.state = {
            downloads: [],
            load_complete: 0
        };
        this.getDownloads = this.getDownloads.bind(this)
    }

    getDownloads(){
        const url = api_base_url + '/download_files?include=groups.files';

        let self = this

        api_fetch(url)
            .then(response => response.json())
            .then(response_obj => des.deserialise(response_obj))
            .then(downloads => {

                self.setState({
                    downloads: _.sortBy(downloads, ['order', 'title']),
                    load_complete: 1
                });
            })
            .catch(function (ex) {
                console.log('parsing failed', ex);
                self.setState({load_complete: 1})
            });
    }

    componentDidMount(){
        this.getDownloads()
    }

    render(){

        let content = null;

         if(!this.state.load_complete ) {
             content = (<div className="py-5"><Loading subtitle="Loading download links"/></div>)
         } else{

             if(this.state.downloads.length === 0) {
                 content =
                     <p className="text-warning" >There was a problem getting the download files. Please try again
                         later.</p>
             } else {

                 content = (
                     <Fragment>
                         {_.map(this.state.downloads, d => <DownloadCategory
                             category={d} key={d.id}/>)}
                     </Fragment>

                 )
             }
         }

        return(
            <Container>
                <br/><br/><Row className="my-2">
                    <Col>
                        <h1>Downloads</h1><br/>
                        <p>
                            Direct downloads of each dataset available from the Cell Model Passports.
                        </p>
                        <p>
                            Information about each dataset and how they have been processed can be found <a href='https://depmap.sanger.ac.uk/documentation/datasets/'>here</a>.
                        </p>
                    </Col>
                </Row>

                <Row className="my-2">
                    {content}
                </Row>

            </Container>
        )
    }

}

export default Downloads;