import React, { Component } from 'react';
import {withRouter} from "react-router-dom";

import {select, partition, hierarchy, arc, scaleOrdinal } from 'd3';
import {selectAll} from "d3-selection";
import _ from 'lodash';

import {urlify} from "../../utils";
import {api_base_url} from "../../settings";

let swatches = ['#7096D1','#50A240','#B88932','#DBABD6','#6ED0D8','#85933B','#DEB2A6','#988D67','#9D87D1','#CB79CC','#69D38B','#B3BFD6','#D474A1','#599C6D','#C6B843','#DDBB78','#CA855D','#5AA9CD','#9C8595','#9EC552','#ADC888','#64D2B5','#D07D80','#B5C3AB','#639692']

class Sunburst extends Component {

    constructor(props){
        super(props);
        this.createSunburst = this.createSunburst.bind(this);
        this.state={}
    }

    componentDidMount() {
        fetch(api_base_url + "/sunburst_data")
            .then(resp => resp.json())
            .then(resp => this.setState({modelHierarchy: resp}));
    }
    componentDidUpdate() {
        this.createSunburst()
    }

    createSunburst(){

        if(_.isEmpty(this.state.modelHierarchy)){
            return
        }

        let width = 400;
        let height = 400;
        let radius = Math.min(width, height) / 2;
        let innerRadiusResizer = 20;
        let color = scaleOrdinal(swatches);

        const node = this.node;

        let g = select(node)
            .append('g')
            .attr('transform', `translate(${width / 2}, ${height / 2})`);

        let prt = partition()
            .size([(2 * Math.PI) - 1, radius - innerRadiusResizer]);

        let root = hierarchy(this.state.modelHierarchy)
           .sum((d) => d.size);

        prt(root);


        let partarc = arc()
            .startAngle(d => d.x0 + 0.5 )
            .endAngle(d => d.x1 + 0.5 )
            .innerRadius(d => d.y0 + innerRadiusResizer )
            .outerRadius(d => d.y1 + innerRadiusResizer );

        let setExplanation = d => {
            let h = "";

            h += '<span class="size">' + d.value + ' Models</span><br />';
            h += '<span class="type">' + d.data.name + '</span><br />';

            return(h)
        };

        let resetExplanation = () => {
            return '<span class="reset">View models based on lineage</span>'
        };

        let mouseover = (d, e, f) => {
            select('#explanation')
                .style("visibility", "")
                .html(setExplanation(d));

            selectAll(f)
                .classed("inactive", true);
            g.selectAll("text")
                .classed("inactive", true);
            select(f[e])
                .classed("active", true)
                .classed("inactive", false)
                .select("text")
                .classed("inactive", false);


            if(d.children) {
                g.selectAll("path")
                    .filter((node) => {
                        return d.children.indexOf(node) >= 0;
                    })
                    .classed("parent-active", true)
            }

            if(d.parent){
                g.selectAll("path")
                    .filter((node) => {
                        return d.parent === node;
                    })
                    .classed("child-active", true)
            }
        };

        let mouseleave = (d, e, f) => {
            select("#explanation")
                .html(resetExplanation());
            selectAll(f)
                .classed("inactive", false);
            g.selectAll("path")
                .classed("parent-active", false)
            g.selectAll("path")
                .classed("child-active", false)
            select(f[e])
                .classed("active", false);
            g.selectAll("text")
                .classed("inactive", false);
        };

        let onClick = d => {

            if (d.depth === 2) {
                this.props.history.push("/passports?cancer_type=" + urlify(d.data.name));
            } else {
                this.props.history.push("/passports?tissue=" + urlify(d.data.name));
            }

            window.scrollTo(0,0);

        };

        let hexToRgb = (hex) => {
            let bigint = parseInt(hex.slice(1), 16);
            let r = (bigint >> 16) & 255;
            let g = (bigint >> 8) & 255;
            let b = bigint & 255;

            return r + "," + g + "," + b;
        }

        g.selectAll('g')
            .data(root.descendants())
            .enter()
            .append('g')
            .classed("segment", true)
            .on("mouseover", mouseover)
            .on("mouseleave", mouseleave)
            .on("click", onClick)
            .append('path')
                .attr("display", function (d) { return d.depth ? null : "none"; })
                .attr("d", partarc)
                .style('stroke', '#fff')
                .style("fill", (d) => {
                    let base_color =  color((d.children ? d : d.parent).data.name);
                    return (d.children? base_color: "rgba(" + hexToRgb(base_color) + ", " + ((Math.random() * 0.15) + 0.45) + ")")
                });
                //.style("fill", color);


        g.append('text')
            .html(() => {return '<tspan dy="-10">Cancer Types</tspan>'})
            .style("transform", "translate(0, -146px)");

        g.append('text')
            .html(() => {return '<tspan dy="-10">Tissues</tspan>'})
            .style("transform", "translate(0, -104px)")


    }

    render(){
        return(
            <div className="mt-4 me-7">
                <div id="explanation">
                    <span className="reset">View models based on lineage</span>
                </div>
                <svg className="sunburst" ref={node => this.node = node} width={500} height={500}></svg>

            </div>
        )
    }
}

export default withRouter(Sunburst);