import React from 'react';
import _ from 'lodash';

import { Card, CardBody, CardHeader, Collapse, FormGroup, Input, Label } from "reactstrap";
import qs from "qs";
import {withRouter} from "react-router-dom";
import CollapseButton from '../dumb/collapseButton';
import baseFilter from "./baseFilter";

class MSIStatusFilter extends baseFilter {

    constructor(props){
        super(props);
        this.renderMSIStatusCheckboxes = this.renderMSIStatusCheckboxes.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.updateAPIURL = this.updateAPIURL.bind(this);
        this.processResponse = this.processResponse.bind(this);
        this.toggleCollapse = this.toggleCollapse.bind(this);

        this.state = {
            filters: {},
            isOpen: false,
            activeCount: 0
        };
        this.aggregates = {'model_msi_status.msi_status': 'count'}
    }

    static getDerivedStateFromProps(newprops, oldstate){
        let current_qs = qs.parse(newprops.location.search, { ignoreQueryPrefix: true });
        let newfilters = MSIStatusFilter.getFiltersFromQS(current_qs, oldstate.filters);

        return {
          filters: newfilters,
          activeCount: _.filter(newfilters, 'active').length
      }
    }


    static getActiveFiltersFromQS(qs_obj, field, defaultValue = [], sep="_"){
        return (field in qs_obj)? _.split(_.get(qs_obj, field), sep):defaultValue;
    }

    static getFiltersFromQS(qs, oldfilters) {
      let qs_msistatus = ('msi_status' in qs)? _.split(qs.msi_status, '_'):[];

      let newfilters = _.reduce(oldfilters,
        (res, filterProps, msi_status) => {
          res[msi_status] = filterProps;
          res[msi_status].active = _.indexOf(qs_msistatus, msi_status) >= 0;
          return res
        }, {});

      return newfilters
    }

    toggleCollapse(){
        this.setState({isOpen: !this.state.isOpen})
    }

    updateAPIURL(api_qs, newlistprops){

        let new_qs = qs.parse(newlistprops.location.search, { ignoreQueryPrefix: true });
        let activeMSIStatusFilters = MSIStatusFilter.getActiveFiltersFromQS(new_qs, 'msi_status');

        let filter_qs = {"name":"model_msi_status","op":"any","val":{"name":"msi_status","op":"in","val":activeMSIStatusFilters}};

        if(activeMSIStatusFilters.length > 0){
            api_qs.filter = _.get(api_qs, 'filter', []).concat(filter_qs)
        }

        return _.merge(api_qs, {agg: this.aggregates})


    }

    processResponse(response){

        let resp_agg = this.get_agg_values(response);
        let agg_field = _.keys(this.aggregates)[0];
        if(! resp_agg){
            return
        }

        let newfilters = _.reduce(resp_agg[agg_field][this.aggregates[agg_field]],
            (res, count, msi_status) => {
                if(msi_status in this.state.filters){
                    res[msi_status] = this.state.filters[msi_status];
                    res[msi_status].count = count
                } else {
                    if (msi_status !== 'None') {
                        res[msi_status] = {'count': count, active: false}
                    }
                }
                return res;
            }, {});

        this.setState({
            filters: newfilters
        })
    }

    toggleCheckbox(event){
        let msi_status = event.target.name;
        let current_qs = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        let msiFilter = ('msi_status' in current_qs)? _.split(current_qs.msi_status, '_'):[];

        if(event.target.checked){
            msiFilter.push(msi_status)
        } else {
            msiFilter = msiFilter.filter((item) => item !== msi_status)
        }
        current_qs.msi_status = (msiFilter.length > 0)? _.join(msiFilter.sort(), '_'): undefined;
        current_qs.page = undefined;
        this.props.history.push(window.location.pathname + qs.stringify(current_qs, { addQueryPrefix: true }));
    }

    renderMSIStatusCheckboxes(){
        return (
            _.map(_.toPairs(this.state.filters), ([msi_status, details]) => {
                return (
                    <FormGroup check key={msi_status}>
                        <Input type="checkbox" name={msi_status} onChange={this.toggleCheckbox} checked={_.get(details, 'active')}/>{' '}
                        <Label className="ml-4 mt-1" check>
                            {msi_status} <span className="secondary">({_.get(details, 'count')})</span>
                        </Label>
                    </FormGroup>
                )}
            )
        )

    }

    componentDidMount() {
        this.props.registerResponse(this.processResponse);
        this.props.registerRequest(this.updateAPIURL);
    };

    componentWillUnmount() {
        this.props.unregisterResponse(this.processResponse);
        this.props.unregisterRequest(this.updateAPIURL);
    };

    render(){
        return(
            <Card className="msi-filters mb-2 z0 hover">
                <CardHeader className="justify-content-between d-flex" onClick={this.toggleCollapse} >
                    <div className="d-inline w-50">MSI Status</div>
                    <div className="d-inline-block">
                        {this.state.activeCount > 0 && (<strong>{this.state.activeCount + ' active'}</strong>)}
                    </div>
                    <CollapseButton isOpen={this.state.isOpen} onClick={this.toggleCollapse} />
                </CardHeader>
                <Collapse isOpen={this.state.isOpen}>
                    <CardBody>
                        {this.renderMSIStatusCheckboxes()}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }
}

export default withRouter(MSIStatusFilter)
