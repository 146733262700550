import React from 'react';

export default function(props){
    return(
      <div className={(props.size || "") + " loading-wrapper d-flex justify-content-center flex-row " + (props.color || "")}>
        <div className="d-flex flex-column justify-content-center" >
          <div className="d-flex flex-row justify-content-center" >
            <div className="loader"></div>
            </div>
          {props.subtitle && (
            <div className="d-flex flex-row justify-content-center mt-2" >
              <div className="loading-subtitle">{props.subtitle}</div>
              </div>
            )}
        </div>
      </div>
    )
}
