import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'

export default function(props){

    return(
        <span className={'mt-1'}>
            {props.isOpen?  <FontAwesomeIcon className={'mt-1'} icon={faChevronUp} />: <FontAwesomeIcon icon={faChevronDown} />}
        </span>
    );
}