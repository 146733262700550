import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {
    return (
            <BaseIcon
                {...props}
                tooltipText="Drug Response Data"
            >

                <path d="M463,132.8c-16-16-37.1-24.3-61.4-24.3c-28.9,0.1-59.9,12.7-79.2,32.1c-36.9,37.3-61.7,62.3-85.6,86.2l-1,1
        c-1.8,1.8-3.3,3.3-5.1,5.1c-63.2,63.1-79,78.7-90.5,90c-3.1,3-5.9,5.8-9.2,9.1c-30.4,30.1-36.7,89.5,2.2,130.2
        c19.4,20.3,40.3,30.7,62,31c0.3,0,0.7,0,1,0c22,0,44.4-10.7,65-30.9c61.6-60.6,101-100.3,139-138.7c19-19.2,38.7-39,60.8-61.2
        c16.7-16.7,27-39.3,28.3-62.1C490.9,175.3,481.5,151.3,463,132.8z M247.2,448.2c-11.7,11.5-30,25.1-50.9,25.1c-0.2,0-0.5,0-0.7,0
        c-16.2-0.2-32.3-8.6-47.8-24.9c-18.7-19.6-22-41.1-21.5-55.6c0.7-18,7.7-35.4,18.9-46.5l0,0c3.3-3.3,6.1-6.1,9.2-9.1
        c11.4-11.2,27-26.6,88.5-88l102,102C317.8,378.3,287.2,408.9,247.2,448.2z"/>
            </BaseIcon>
    )

}