import React, {Fragment} from 'react';

export default function(){
    return(
        <Fragment>
            <h1>Model Authentication</h1>
            <p>
                New models added to the passports are cross referenced using either STR or SNP against existing models to
                ensure they are attributed to the correct patient identifiers.

            </p>
            <p>
               STR: Models are tested using a panel of 16 STRs this includes the 9 currently used by most of the cell line repositories (ATCC, Riken, JCRB and DSMZ). 
            </p>
            <p>
                SNP: Models are compared using a panel of 95 SNPs assayed using the 96.96 Dynamic Array IFC, Fluidigm.
            </p>
        </Fragment>
    )
}