import React, { useState } from 'react';
import {Row, Col, CardBody, Card, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane} from "reactstrap";

import _ from 'lodash';

const ModelInfo = (props) => {
    const [activeTab, setActiveTab] = useState('1');
    let model = props.model;
    let model_type = _.get(model, 'model_type', '-');
    let tissue = _.get(model, 'sample.tissue.name', '-');
    let cancer_type = _.get(model, 'sample.cancer_type.name', '-');
    let tissue_status = _.get(model, 'sample.tissue_status', '-');
    let age = _.get(model, 'sample.age_at_sampling', '-');
    let gender = _.get(model, 'sample.patient.gender', '-');
    let ethnicity = _.get(model, 'sample.patient.ethnicity', '-');
    let ncit_description = _.get(model, 'sample.ncit.ncit_description', '-');
    let ncit_id = _.get(model, 'sample.ncit.ncit_id', '-');
    let sample_site = _.get(model, 'sample.sample_site') || "Unknown";
    let growth_properties = _.get(model, 'growth_properties', '-');
    let pmed = _.get(model, 'pmed');
    let sampling_year = _.get(model, 'sample.sampling_year', '-');
    let sample_treatment = _.get(model, 'sample.sample_treatment', '-');
    let model_treatment = _.get(model, 'model_treatment', '-');
    let comment = _.get(model, 'comment');
    let msi_status = '-';
    if(model.model_msi_status[0].msi_status){
        let msi = _.find(model.model_msi_status, s => s.current === true);
        msi_status = msi.msi_status;
    }
    let tnm_t = _.get(model, 'sample.tnm_t');
    let tnm_n = _.get(model, 'sample.tnm_n');
    let tnm_m = _.get(model, 'sample.tnm_m');
    let tumour_grade = _.get(model, 'sample.tumour_grade');
    let tnm_integraded = _.get(model, 'sample.tnm_integrated');
    let smoker_status = _.get(model, 'sample.patient.smoking_status');
    let growth_rates = _.get(model, 'growth_rates');
    
    let growth_rate = '-';
    let num_replicates = 0;
    
    for (let i = 0; i < growth_rates.length; i++) {
      if (growth_rates[i].replicates > num_replicates) {
          growth_rate = (Math.round(growth_rates[i].day4_day1_ratio * 100) / 100).toFixed(2) + " +/- " + (Math.round(growth_rates[i].st_dev * 100) / 100).toFixed(2) + " (" + growth_rates[i].replicates + " replicates)";
          num_replicates = growth_rates[i].replicates
      }
    }
    
    let family_history_of_cancer = _.get(model, 'sample.patient.family_history_of_cancer');
    let prior_same_malignancy = _.get(model, 'sample.patient.prior_same_malignancy');
    let prior_other_malignancy = _.get(model, 'sample.patient.prior_other_malignancy');
    let clinical_stage_group = _.get(model, 'sample.clinical_stage_group');
    
    if (clinical_stage_group){
        clinical_stage_group = clinical_stage_group.replace("Stage ", "");
    }
    
    let method_procurement = _.get(model, 'sample.method_procurement');
    let barretts_goblet_cells_present = _.get(model, 'sample.barretts_goblet_cells_present');
    let metastasis_at_diagnosis = _.get(model, 'sample.metastasis_at_diagnosis');
    
    if (metastasis_at_diagnosis === 'Non-metastatic (confirmed)'){
        metastasis_at_diagnosis = 'Non-metastatic';
    } else if ((metastasis_at_diagnosis === 'Non-metastatic (unconfirmed)')){
        metastasis_at_diagnosis = 'Unknown';
    } else if ((metastasis_at_diagnosis === 'Metastatic')){
        metastasis_at_diagnosis = 'Metastatic';
    } else {
        metastasis_at_diagnosis = '';
    }
    
    let metastatic_site_at_diagnosis = _.get(model, 'sample.metastatic_site_at_diagnosis');
    let colon_polyps_present_at_time_of_tissue_collection = _.get(model, 'sample.colon_polyps_present_at_time_of_tissue_collectio');
    let tumour_regression_score = _.get(model, 'sample.tumour_regression_score');
    let venous_invasion_present = _.get(model, 'sample.venous_invasion_present');
    let venous_invasion_type = _.get(model, 'sample.venous_invasion_type');
    let lymphatic_invasion_present = _.get(model, 'sample.lymphatic_invasion_present');
    let perineural_invasion_present = _.get(model, 'sample.perineural_invasion_present');
    let gastric_oesophageal_junction = _.get(model, 'sample.gastric_oesophageal_junction');
    let oesophageal_columnar_metaplasia = _.get(model, 'sample.oesophageal_columnar_metaplasia');
    let goblet_cells_oesophageal_columnar_mucosa = _.get(model, 'sample.goblet_cells_oesophageal_columnar_mucosa');
    let degree_of_dysplasia_non_cancerous_oesophageal_columnar_mucosa = _.get(model, 'sample.degree_of_dysplasia_non_cancerous_oesophageal_columnar_mucosa');
    let peripancreatic_lymph_nodes_tested_surgery = _.get(model, 'sample.peripancreatic_lymph_nodes_tested_surgery');
    let number_positive_peripancreatic_lymph_nodes = _.get(model, 'sample.number_positive_peripancreatic_lymph_nodes');
    let mismatch_repair_status = _.get(model, 'sample.mismatch_repair_status');
    let mlh1_expression_by_ihc = _.get(model, 'sample.mlh1_expression_by_ihc');
    let mlh1_promoter_methylation_status = _.get(model, 'sample.mlh1_promoter_methylation_status');
    let msh2_expression_by_ihc = _.get(model, 'sample.msh2_expression_by_ihc');
    let pms2_expression_by_ihc = _.get(model, 'sample.pms2_expression_by_ihc');
    let msh6_expression_by_ihc = _.get(model, 'sample.msh6_expression_by_ihc');
    let braf_mutation_identified = _.get(model, 'sample.braf_mutation_identified');
    let braf_expression_by_ihc = _.get(model, 'sample.braf_expression_by_ihc');
    let pik3ca_mutation_identified = _.get(model, 'sample.pik3ca_mutation_identified');
    let pten_expression_by_ihc = _.get(model, 'sample.pten_expression_by_ihc');
    let pten_mutation_identified = _.get(model, 'sample.pten_mutation_identified');
    let preoperative_ce_alevel = _.get(model, 'sample.preoperative_ce_alevel');
    let kras_mutation_identified = _.get(model, 'sample.kras_mutation_identified');
    let prior_therapy_outcome = _.get(model, 'sample.prior_therapy_outcom');
    let radiation_type = _.get(model, 'sample.radiation_type');
    
    let prior_malignancy = '';
    
    if (prior_same_malignancy && prior_same_malignancy === 'Yes') {
        prior_malignancy = 'Yes (same cancer type)';
    }else if(prior_other_malignancy && prior_other_malignancy === 'Yes') {
        prior_malignancy = 'Yes (different cancer type)';
    } else if ((prior_same_malignancy && prior_same_malignancy === 'No') || (prior_other_malignancy && prior_other_malignancy === 'No')) {
        prior_malignancy = 'No';
    }
    
    let reflux_disease_prior = _.get(model, 'sample.patient.reflux_disease_prior');
    let reflux_disease_treatment = _.get(model, 'sample.patient.reflux_disease_treatment');
    let h_pylori_infection = _.get(model, 'sample.patient.h_pylori_infection');
    let previous_current_barretts_oesophagus = _.get(model, 'sample.patient.previous_current_barretts_oesophagus');
    let colorectal_cancer_risk_factors = _.get(model, 'sample.patient.colorectal_cancer_risk_factors');
    let patient_history_of_gastrointestinal_disorder = _.get(model, 'sample.patient.patient_history_of_gastrointestinal_disorder');
    let gastrointestinal_disorder_diagnosed = _.get(model, 'sample.patient.gastrointestinal_disorder_diagnosed');
    
    if (gastrointestinal_disorder_diagnosed && gastrointestinal_disorder_diagnosed.length > 1) {
        gastrointestinal_disorder_diagnosed = gastrointestinal_disorder_diagnosed.join(' & ');
    }
    
    let gastrointestinal_disorder = '';
    
    if (gastrointestinal_disorder_diagnosed) {
        gastrointestinal_disorder = gastrointestinal_disorder_diagnosed;
    } else if (patient_history_of_gastrointestinal_disorder) {
        gastrointestinal_disorder = patient_history_of_gastrointestinal_disorder;
    }
    
    let history_synchronous_colon_rectal_tumours = _.get(model, 'sample.patient.history_synchronous_colon_rectal_tumours');
    let history_prior_colon_polyps = _.get(model, 'sample.patient.history_prior_colon_polyps');
    let alcohol_exposure_intensity = _.get(model, 'sample.patient.alcohol_exposure_intensity');
    let alcohol_consumption_per_week = _.get(model, 'sample.patient.alcohol_consumption_per_week');
    let history_diabetes = _.get(model, 'sample.patient.history_diabetes');
    let diabetes_treatment = _.get(model, 'sample.patient.diabetes_treatment');
    let history_clinical_chronic_pancreatitis = _.get(model, 'sample.patient.history_clinical_chronic_pancreatitis');

    let mutations_per_mb = _.get(model, 'mutations_per_mb');
    if (mutations_per_mb) {
        mutations_per_mb = Math.round(mutations_per_mb * 1000) / 1000;
    } else {
        mutations_per_mb = '-';
    }

    let ploidy = _.get(model, 'ploidy');
    if (ploidy) {
        ploidy = Math.round(ploidy * 1000) / 1000;
    }

    let ploidy_wes = _.get(model, 'ploidy_wes');
    if (ploidy_wes) {
        ploidy_wes = Math.round(ploidy_wes * 1000) / 1000;
    }

    let ploidy_wgs = _.get(model, 'ploidy_wgs');
    if (ploidy_wgs) {
        ploidy_wgs = Math.round(ploidy_wgs * 1000) / 1000;
    }

    let doi = _.get(model, 'doi');
    let sample_treatment_details = _.get(model, 'sample.sample_treatment_details', '-');
    let names = _.get(model, 'names');
    let other_names = '-';
    // Collect synonym
    if (names.length > 1) {
        other_names = names.slice(1).join(',  ');
    }

    // collect sample treatment
    if (sample_treatment.length > 1) {
        sample_treatment = sample_treatment.join(' & ');
    }
    
    if (reflux_disease_treatment && reflux_disease_treatment.length > 1) {
        reflux_disease_treatment = reflux_disease_treatment.join(' & ');
    }
    
    if (diabetes_treatment && diabetes_treatment.length > 1) {
        diabetes_treatment = diabetes_treatment.join(' & ');
    }
    
    if (metastatic_site_at_diagnosis && metastatic_site_at_diagnosis.length > 1) {
        metastatic_site_at_diagnosis = metastatic_site_at_diagnosis.join(' & ');
    }

    return (
        <Col className="my-3">
            <Card className="model-info models" style={{minHeight: "900px", width: "100%"}}>
                <CardHeader>Model Information</CardHeader>
                <CardBody className="card-body">
                    <Row>
                        <Col xs={12}>
                            <h1>{names[0]}</h1>
                        </Col>
                    </Row>
                    <Nav tabs>
                        <NavItem>
                            <NavLink className={activeTab === '1' ? 'active' : ''} onClick={() => setActiveTab('1')}>
                                General Information
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink className={activeTab === '2' ? 'active' : ''} onClick={() => setActiveTab('2')}>
                                Clinical Profile
                            </NavLink>
                            
                        </NavItem>
                        
                        
                    </Nav>
                    <TabContent activeTab={activeTab}>
                    <TabPane tabId="1">
                        <Col xs={12}>
                                <Col sm={12}>
                                <div className="subheader">
                                <h3>Overview</h3>
                            </div>
                                    <p>
                                        <span className="list-key">Name(s)</span>&nbsp;
                                        <span className="list-value">{names[0]} {other_names !== '-' && ("(" + other_names + ")")}</span>
                                    </p>
                                    <p>
                                        <span className="list-key">Tissue</span>&nbsp;
                                        <span className="list-value">{tissue}</span>
                                    </p>

                                    <p>
                                        <span className="list-key">Cancer Type</span>&nbsp;
                                        <span className="list-value">{cancer_type}</span>
                                    </p>

                                    <p>
                                        <span className="list-key">Tissue Status </span>
                                        <span className="list-value">{tissue_status} </span>
                                    </p>

                                    <p>
                                        <span className="list-key">Sample Site </span>
                                        <span className="list-value">{sample_site}</span>
                                    </p>
                                    {(ncit_description !== '-')?(

                                        <p>
                                            <span className="list-key">Cancer Type Details</span>&nbsp;

                                            <span className="list-value">{ncit_description} &nbsp;    (NCIT <a
                                                href={"https://ncit.nci.nih.gov/ncitbrowser/ConceptReport.jsp?dictionary=NCI_Thesaurus&code=" + ncit_id}>{ncit_id})</a> </span>
                                        </p>

                                    ):(

                                        <p>
                                            <span className="list-key">Cancer Type Details </span>

                                            <span className="list-value">-</span>
                                        </p>
                                    )}
                                    <p>
                                        <span className="list-key">GDSC Growth Estimate </span>

                                        <span className="list-value">{growth_rate}</span>
                                    </p>

                                </Col>
                                
                        <Col lg={6}>

                            <div className="subheader">
                                <h3>Derivation</h3>
                            </div>
                            <p>
                                <span className="list-key">Model Type </span>
                                <span className="list-value">{model_type}</span>
                            </p>

                            <p>
                                <span className="list-key">Growth Properties </span>
                                <span className="list-value">{growth_properties}</span>
                            </p>

                            <p>
                                <span className="list-key">Model treatment </span>
                                <span className="list-value">{model_treatment} </span>
                            </p>

                            <p>
                                <span className="list-key">Year Generated </span>
                                <span className="list-value">{sampling_year}</span>
                            </p>

                            {(pmed || doi) && (
                                <p>
                                    <span className="list-key">Publication </span>
                                    <span className="list-value"><a
                                        href={"http://europepmc.org/abstract/MED/" + pmed}>{pmed}</a></span>
                                    <span className="list-value"><a
                                        href={"http://dx.doi.org/" + doi}>{doi}</a></span>
                                </p>)
                            }
                        </Col>
                        <Col lg={6}>

                            <div className="subheader">
                                <h3>Model Genomics</h3>
                            </div>
                            <p>
                                <span className="list-key">MSI Status </span>
                                <span className="list-value">{msi_status} </span>
                            </p>
                            {(ploidy) && (
                                <p>
                                    <span className="list-key">Ploidy (SNP6) </span>
                                    <span className="list-value">{ploidy} </span>
                                </p>
                            )}
                            {(ploidy_wes) && (
                                <p>
                                    <span className="list-key">Ploidy (WES) </span>
                                    <span className="list-value">{ploidy_wes} </span>
                                </p>
                            )}
                            {(ploidy_wgs) && (
                                <p>
                                    <span className="list-key">Ploidy (WGS) </span>
                                    <span className="list-value">{ploidy_wgs} </span>
                                </p>
                            )}
                            <p>
                                <span className="list-key">Mutations per Mb </span>
                                <span className="list-value">{mutations_per_mb} </span>
                            </p>

                        </Col>
                   
                    {comment &&
                    //<Row className="general">
                        <Col><br/>
                            <p>
                                <span className="list-key">Comment</span><br/>
                                <span className="list-value">{comment}</span>
                            </p>
                        </Col>
                    //</Row>
                    
                    }
                        </Col>
                        </TabPane>
                        <TabPane tabId="2">
                        <Col sm={12}>
                            <div className="subheader">
                                <h3>Summary</h3>
                            </div>
                            <p>
                                <span className="list-key">Gender </span>
                                <span className="list-value">{gender}</span>
                            </p>

                            <p>
                                <span className="list-key">Ethnicity </span>
                                <span className="list-value">{ethnicity} </span>
                            </p>
    
                            <p>
                                <span className="list-key">Age at sampling </span>
                                <span className="list-value">{age}</span>
                            </p>

                        </Col>
                        <Col lg={6}>
                            <div className="subheader">
                                <h3>History</h3>
                            </div>
                            {(family_history_of_cancer) && (
                            <p>
                                <span className="list-key">Family History of Cancer </span>
                                <span className="list-value">{family_history_of_cancer}</span>
                            </p>)}
                            {(prior_malignancy) && (
                            <p>
                                <span className="list-key">Prior Malignancies </span>
                                <span className="list-value">{prior_malignancy}</span>
                            </p>)}
                            {(smoker_status) && (
                            <p>
                                <span className="list-key">Smoker Status </span>
                                <span className="list-value">{smoker_status}</span>
                            </p>)}
                            {(alcohol_exposure_intensity && alcohol_exposure_intensity !== 'NA') && (
                            <p>
                                <span className="list-key">Alcohol Status </span>
                                <span className="list-value">{alcohol_exposure_intensity}</span>
                            </p>)}
                            {(alcohol_consumption_per_week || alcohol_consumption_per_week  === 0) && (
                            <p>
                                <span className="list-key">Alcohol Frequency </span>
                                <span className="list-value">{alcohol_consumption_per_week}</span>
                            </p>)}
                            {(history_diabetes) && (
                            <p>
                                <span className="list-key">Diabetic </span>
                                <span className="list-value">{history_diabetes}</span>
                            </p>)}
                            {(diabetes_treatment) && (
                            <p>
                                <span className="list-key">Diabetes Treatment </span>
                                <span className="list-value">{diabetes_treatment}</span>
                            </p>)}
                            {(colorectal_cancer_risk_factors) && (
                            <p>
                                <span className="list-key">Colorectal Cancer Risk Factors </span>
                                <span className="list-value">{colorectal_cancer_risk_factors}</span>
                            </p>)}
                            {(gastrointestinal_disorder) && (
                            <p>
                                <span className="list-key">Prior Diagnosis of Gastrointestinal Disorder </span>
                                <span className="list-value">{gastrointestinal_disorder}</span>
                            </p>)}
                            {(history_synchronous_colon_rectal_tumours) && (
                            <p>
                                <span className="list-key">Prior Synchronous Tumours </span>
                                <span className="list-value">{history_synchronous_colon_rectal_tumours}</span>
                            </p>)}
                            {(history_prior_colon_polyps) && (
                            <p>
                                <span className="list-key">Colon Polyp History </span>
                                <span className="list-value">{history_prior_colon_polyps}</span>
                            </p>)}
                            {(previous_current_barretts_oesophagus) && (
                            <p>
                                <span className="list-key">Barrett's Esophagus Status </span>
                                <span className="list-value">{previous_current_barretts_oesophagus}</span>
                            </p>)}
                            {(reflux_disease_prior) && (
                            <p>
                                <span className="list-key">Prior Diagnosis of Reflux Disease </span>
                                <span className="list-value">{reflux_disease_prior}</span>
                            </p>)}
                            {(reflux_disease_treatment) && (
                            <p>
                                <span className="list-key">Reflux Disease Treatment </span>
                                <span className="list-value">{reflux_disease_treatment}</span>
                            </p>)}
                            {(h_pylori_infection) && (
                            <p>
                                <span className="list-key">H. pylori Infection Status </span>
                                <span className="list-value">{h_pylori_infection}</span>
                            </p>)}
                            {(history_clinical_chronic_pancreatitis) && (
                            <p>
                                <span className="list-key">Prior Diagnosis of Chronic Panreatitis </span>
                                <span className="list-value">{history_clinical_chronic_pancreatitis}</span>
                            </p>)}


                        </Col>
                        
                        <Col lg={6}>
                            <div className="subheader">
                                <h3>Diagnosis & Treatment</h3>
                            </div>
                            {(tumour_grade) && (
                                <p>
                                    <span className="list-key">Tumour Grade </span>
                                    <span className="list-value">{tumour_grade}</span>
                                </p>
                            )}
                            {(tnm_integraded) && (
                                <p>
                                    <span className="list-key">Tumour Stage </span>
                                    <span className="list-value">{tnm_integraded}</span>
                                </p>
                            )}
                            {(tnm_t || tnm_m || tnm_n) && (
                                <p>
                                    <span className="list-key">TNM Status </span>
                                    <span className="list-value">{tnm_t} {tnm_n} {tnm_m}</span>
                                </p>
                            )}
                            {(clinical_stage_group) && (
                            <p>
                                <span className="list-key">Clinical Staging (Pre-Sampling) </span>
                                <span className="list-value">{clinical_stage_group}</span>
                            </p>)}
                            {(metastasis_at_diagnosis) && (
                            <p>
                                <span className="list-key">Disease Metastasis Status </span>
                                <span className="list-value">{metastasis_at_diagnosis}</span>
                            </p>)}
                            {(metastatic_site_at_diagnosis) && (
                            <p>
                                <span className="list-key">Disease Metastasis Sites </span>
                                <span className="list-value">{metastatic_site_at_diagnosis}</span>
                            </p>)}
                            {(method_procurement) && (
                            <p>
                                <span className="list-key">Sampling Procedure </span>
                                <span className="list-value">{method_procurement}</span>
                            </p>)}
                            {(sample_treatment) && (
                            <p>
                                <span className="list-key">Prior treatment </span>
                                <span className="list-value">{sample_treatment}</span>
                            </p>)}
                            {(sample_treatment_details) && (
                            <p>
                                <span className="list-key">Prior treatment details </span>
                                <span className="list-value">{sample_treatment_details}</span>
                            </p>)}
                            {(radiation_type) && (
                            <p>
                                <span className="list-key">Radiotherapy Details </span>
                                <span className="list-value">{radiation_type}</span>
                            </p>)}
                            {(prior_therapy_outcome) && (
                            <p>
                                <span className="list-key">Prior Therapy Outcome </span>
                                <span className="list-value">{prior_therapy_outcome}</span>
                            </p>)}
                            {(tumour_regression_score) && (
                            <p>
                                <span className="list-key">Tumour Regression Score </span>
                                <span className="list-value">{tumour_regression_score}</span>
                            </p>)}
                            {(colon_polyps_present_at_time_of_tissue_collection) && (
                            <p>
                                <span className="list-key">Colon Polyp Status </span>
                                <span className="list-value">{colon_polyps_present_at_time_of_tissue_collection}</span>
                            </p>)}
                            {(venous_invasion_present) && (
                            <p>
                                <span className="list-key">Venous Invasion </span>
                                <span className="list-value">{venous_invasion_present}</span>
                            </p>)}
                            {(venous_invasion_type) && (
                            <p>
                                <span className="list-key">Venous Invasion Type </span>
                                <span className="list-value">{venous_invasion_type}</span>
                            </p>)}
                            {(lymphatic_invasion_present) && (
                            <p>
                                <span className="list-key">Lymphatic Invasion </span>
                                <span className="list-value">{lymphatic_invasion_present}</span>
                            </p>)}
                            {(perineural_invasion_present) && (
                            <p>
                                <span className="list-key">Perineural Invasion </span>
                                <span className="list-value">{perineural_invasion_present}</span>
                            </p>)}
                            {(gastric_oesophageal_junction) && (
                            <p>
                                <span className="list-key">Tumour Crosses GEJ </span>
                                <span className="list-value">{gastric_oesophageal_junction}</span>
                            </p>)}
                            {(barretts_goblet_cells_present) && (
                            <p>
                                <span className="list-key">Goblet Cell Status </span>
                                <span className="list-value">{barretts_goblet_cells_present}</span>
                            </p>)}
                            {(oesophageal_columnar_metaplasia) && (
                            <p>
                                <span className="list-key">Esophageal Columnar Metaplasia </span>
                                <span className="list-value">{oesophageal_columnar_metaplasia}</span>
                            </p>)}
                            {(goblet_cells_oesophageal_columnar_mucosa) && (
                            <p>
                                <span className="list-key">ECM Goblet Cells </span>
                                <span className="list-value">{goblet_cells_oesophageal_columnar_mucosa}</span>
                            </p>)}
                            {(degree_of_dysplasia_non_cancerous_oesophageal_columnar_mucosa) && (
                            <p>
                                <span className="list-key">Dysplasia Of Non-cancerous ECM </span>
                                <span className="list-value">{degree_of_dysplasia_non_cancerous_oesophageal_columnar_mucosa}</span>
                            </p>)}
                            {(peripancreatic_lymph_nodes_tested_surgery) && (
                            <p>
                                <span className="list-key">Peripancreatic Lymph Nodes Tested </span>
                                <span className="list-value">{peripancreatic_lymph_nodes_tested_surgery}</span>
                            </p>)}
                            {(number_positive_peripancreatic_lymph_nodes) && (
                            <p>
                                <span className="list-key">Peripancreatic Lymph Nodes Positive </span>
                                <span className="list-value">{number_positive_peripancreatic_lymph_nodes}</span>
                            </p>)}

                            
                            <div className="subheader">
                                <h3>Clinical Profiling</h3>
                            </div>
                            {(mlh1_expression_by_ihc) && (
                            <p>
                                <span className="list-key">Clinical MLH1 Expression </span>
                                <span className="list-value">{mlh1_expression_by_ihc}</span>
                            </p>)}
                            {(mlh1_promoter_methylation_status) && (
                            <p>
                                <span className="list-key">Clinical MLH1 Promoter Methylation Status </span>
                                <span className="list-value">{mlh1_promoter_methylation_status}</span>
                            </p>)}
                            {(msh2_expression_by_ihc) && (
                            <p>
                                <span className="list-key">Clinical MSH2 Expression </span>
                                <span className="list-value">{msh2_expression_by_ihc}</span>
                            </p>)}
                            {(pms2_expression_by_ihc) && (
                            <p>
                                <span className="list-key">Clinical PMS2 Expression </span>
                                <span className="list-value">{pms2_expression_by_ihc}</span>
                            </p>)}
                            {(msh6_expression_by_ihc) && (
                            <p>
                                <span className="list-key">Clinical MSH6 Expression </span>
                                <span className="list-value">{msh6_expression_by_ihc}</span>
                            </p>)}
                            {(braf_mutation_identified) && (
                            <p>
                                <span className="list-key">Clinical BRAF Mutation Status </span>
                                <span className="list-value">{braf_mutation_identified}</span>
                            </p>)}
                            {(braf_expression_by_ihc) && (
                            <p>
                                <span className="list-key">Clinical BRAF Expression </span>
                                <span className="list-value">{braf_expression_by_ihc}</span>
                            </p>)}
                            {(pik3ca_mutation_identified) && (
                            <p>
                                <span className="list-key">Clinical PIK3CA Mutation Status </span>
                                <span className="list-value">{pik3ca_mutation_identified}</span>
                            </p>)}
                            {(pten_expression_by_ihc) && (
                            <p>
                                <span className="list-key">Clinical PTEN Expression </span>
                                <span className="list-value">{pten_expression_by_ihc}</span>
                            </p>)}
                            {(pten_mutation_identified) && (
                            <p>
                                <span className="list-key">Clinical PTEN Mutation Status </span>
                                <span className="list-value">{pten_mutation_identified}</span>
                            </p>)}
                            {(kras_mutation_identified) && (
                            <p>
                                <span className="list-key">Clinical KRAS Mutation Status </span>
                                <span className="list-value">{kras_mutation_identified}</span>
                            </p>)}
                            {(mismatch_repair_status) && (
                            <p>
                                <span className="list-key">Clinical MSI Status </span>
                                <span className="list-value">{mismatch_repair_status}</span>
                            </p>)}
                            {(preoperative_ce_alevel) && (
                            <p>
                                <span className="list-key">Preoperative CEA Level </span>
                                <span className="list-value">{preoperative_ce_alevel}</span>
                            </p>)}
                        </Col>
                        </TabPane>
                    </TabContent>
                </CardBody>
            </Card>
        </Col>
    );
}

export default ModelInfo;
