import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {
    return (
        <BaseIcon
            {...props}
            tooltipText="Copy Number Variants"
        >

            <polygon points="485,291 134,291 134,111 116,111 116,489 134,489 134,309 485,309 "/>
            <rect x="144" y="317" width="54" height="63"/>
            <rect x="198" y="128" width="54" height="153"/>
            <rect x="252" y="317" width="54" height="153"/>
            <polygon points="360,200 360,245 306,245 306,281 360,281 414,281 414,200 "/>
            <rect x="414" y="317" width="54" height="126"/>
        </BaseIcon>
    )
}