import React, { Component } from "react";
 
import ReactChangelog from "react-changelog";
 
class Changes extends Component {
  render() {
    return <ReactChangelog endpoint="changelog.json" />;
  }
}

export default Changes;