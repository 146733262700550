import React, { Component } from "react";
import {Row, Col, CardBody, Card, CardHeader, Container} from "reactstrap";
 
class Tutorials extends Component {
  render() {
    return <Container className="bg-faded pt-5 pb-5" fluid>
      <Row>
        <Col>
          <Card className="flex-fill">
            <CardHeader>Describing the Cell Model Passports resource</CardHeader>
            <CardBody className="px-4">
              <div class="video-container">
                <iframe src='https://www.youtube.com/embed/PffrN_-7bS8' frameborder='0' allow='autoplay; encrypted-media; fullscreen' title='video' />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="flex-fill">
            <CardHeader>Searching model based on characteristics</CardHeader>
            <CardBody className="px-4">
              <div class="video-container">
                <iframe src='https://www.youtube.com/embed/-Bpqv6gQl7c' frameborder='0' allow='autoplay; encrypted-media; fullscreen' title='video' />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <br/><br/>
      <Row>
        <Col>
          <Card className="flex-fill">
            <CardHeader>Searching for an organoid of interest</CardHeader>
            <CardBody className="px-4">
              <div class="video-container">
                <iframe src='https://www.youtube.com/embed/J4oQ-fkr9Iw' frameborder='0' allow='autoplay; encrypted-media; fullscreen' title='video' />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col>
          <Card className="flex-fill">
            <CardHeader>Exploring an individual model</CardHeader>
            <CardBody className="px-4">
              <div class="video-container">
                <iframe src='https://www.youtube.com/embed/v4mTyGsf-TE' frameborder='0' allow='autoplay; encrypted-media; fullscreen' title='video' />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  ;
  }
}

export default Tutorials;