import React, {Component} from 'react';
import {
    Col,
    Card,
    CardBody,
    CardHeader,
} from "reactstrap";
import {api_base_url} from "../../../settings";
import Deserialiser from 'deserialise-jsonapi'
import Loading from '../../dumb/loading'
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min';
import filterFactory, {
    textFilter,
} from 'react-bootstrap-table2-filter';

import ExportCSVButton from '../../dumb/fancyTable/ExportCSVButton'
import ExportXLSXButton from '../../dumb/fancyTable/ExportXLSXButton'
import {paginationOptions} from '../../dumb/fancyTable/Pagination'
import ColumnHeader from "../../dumb/fancyTable/columnHeader";

const des = new Deserialiser();

class ModelRNASeq extends Component {
    constructor(props) {
        super(props);
        this.state = {
            activeColumns: 5,
            model: props.model
        };

        this.wideBoxThreshold = 5;

        this.loadRnaseq = this.loadRnaseq.bind(this);
        this.renderTable = this.renderTable.bind(this);
        this.onColumnToggleHandler = this.onColumnToggleHandler.bind(this);
    }


    onColumnToggleHandler(activeColumns) {
        this.setState({activeColumns});
    }

    loadRnaseq() {
        const model_id = this.state.model.id;

        const url = `${api_base_url}/models/${model_id}/datasets/rnaseq?drivers=true&include=gene&page[size]=0&fields[gene]=symbol&sort=-tpm`;

        fetch(url)
            .then(response => response.json())
            .then(resp_obj => des.deserialise(resp_obj))
            .then(rnaseq => {
                    this.setState({
                        rnaseq: rnaseq,
                        load_complete: 1
                    })
                }
            ).catch((ex) => {
            console.log('parsing failed', ex);
        });
    }

    renderTable() {

        const columns = [
            {
                dataField: 'gene.symbol',
                text: "Symbol",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Gene Symbol"
            }, {
                dataField: 'read_count',
                text: "Read count",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "Read count"
            }, {
                dataField: 'fpkm',
                text: "FPKM value",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "FPKM value"
            }, {
                dataField: 'tpm',
                text: "TPM value",
                filter: textFilter({placeholder: ' '}),
                headerFormatter: (column, colIndex, {sortElement, filterElement}) =>
                        <ColumnHeader column={column} colIndex={colIndex} sortElement={sortElement} filterElement={filterElement} />,
                tooltipText: "TPM value"
            }
        ];

        return (

            <ToolkitProvider
                keyField="id"
                data={this.state.rnaseq}
                columns={columns}
                exportCSV={{fileName: `CMP_${this.state.model.names[0]}_rnaseq.csv`}}
            >
                {
                    props => (
                        <div>
                            <div className="d-flex justify-content-left">
                                <b>Note:&nbsp;</b>Displays data for cancer driver genes.
                            </div>
                            <div className="d-flex justify-content-end">
                                <ExportCSVButton {...props.csvProps} />
                                <ExportXLSXButton data={this.state.rnaseq} filename={`CMP_${this.state.model.names[0]}_rnaseq`} />
                            </div>
                            <BootstrapTable
                                {...props.baseProps}
                                bootstrap4
                                hover
                                keyField='id'
                                bordered={false}
                                pagination={this.state.rnaseq.length > 5 ? paginationFactory(paginationOptions(5, this.state.rnaseq.length)) : null}
                                filter={filterFactory()}
                                tableHeaderClass="border-top-none"
                            />
                        </div>
                    )
                }
            </ToolkitProvider>


        )
    }

    componentDidMount() {
        this.loadRnaseq()
    }

    componentDidUpdate() {
        if (this.props.model.id !== this.state.model.id) {
            this.setState({model: this.props.model, load_complete: false}, this.loadRnaseq)

        }
    }

    render() {
        if (!this.state.model.rnaseq_available) return null;

        let content = null;


        if (!this.state.load_complete) {
            content = (
                <div className="py-5"><Loading subtitle="Loading RNASeq"/></div>);
        } else {
            content = this.renderTable()
        }
        return (
            <Col className="my-3" lg={(this.state.activeColumns > this.wideBoxThreshold) ? 12 : 6}
                 xl={(this.state.activeColumns > this.wideBoxThreshold) ? 12 : 6}>
                <Card className="flex-fill models fusions">
                    <CardHeader>RNASeq</CardHeader>
                    <CardBody>
                        {content}
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default ModelRNASeq;
