import React from 'react';

import {humanFileSize} from "../../../../utils";
import p3 from '../../../../../src/assets/img/cmp_webguide/cmp_webguide_20210720p3_lores.jpg'
import p4 from '../../../../../src/assets/img/cmp_webguide/cmp_webguide_20210720p4_lores.jpg'
import p5 from '../../../../../src/assets/img/cmp_webguide/cmp_webguide_20210720p5_lores.jpg'
import p6 from '../../../../../src/assets/img/cmp_webguide/cmp_webguide_20210720p6_lores.jpg'
import p7 from '../../../../../src/assets/img/cmp_webguide/cmp_webguide_20210720p7_lores.jpg'
import p8 from '../../../../../src/assets/img/cmp_webguide/cmp_webguide_20210720p8_lores.jpg'
import p9 from '../../../../../src/assets/img/cmp_webguide/cmp_webguide_20210720p9_lores.jpg'

export default function () {
    return (
        <div className="pb-5">
            <h1>Website User Guide</h1>
            <p className="lead">
                The Cell Model Passports provides a user-friendly interface to explore cell models and their associated
                datasets.
            </p>
            <p>
                Please see the documentation below for instructions on how to use the website. These instructions can
                also be
                downloaded <a
                href="https://cog.sanger.ac.uk/cmp/download/cmp_webguide_20180911.pdf">here</a> (PDF, {humanFileSize(1106409)}).
            </p>
            <a href="https://cog.sanger.ac.uk/cmp/download/cmp_webguide_20180911p3.jpg">
                <img src={p3} alt="Homepage" className="w-100"/>
            </a>
            <a href="https://cog.sanger.ac.uk/cmp/download/cmp_webguide_20180911p4.jpg">
                <img src={p4} alt="List view" className="w-100"/>
            </a>
            <a href="https://cog.sanger.ac.uk/cmp/download/cmp_webguide_20180911p5.jpg">
                <img src={p5} alt="Model summary in list view" className="w-100"/>
            </a>
            <a href="https://cog.sanger.ac.uk/cmp/download/cmp_webguide_20180911p6.jpg">
                <img src={p6} alt="Model view" className="w-100"/>
            </a>
            <a href="https://cog.sanger.ac.uk/cmp/download/cmp_webguide_20180911p7.jpg">
                <img src={p7} alt="Related models view" className="w-100"/>
            </a>
            <a href="https://cog.sanger.ac.uk/cmp/download/cmp_webguide_20180911p8.jpg">
                <img src={p8} alt="Related models view" className="w-100"/>
            </a>
            <a href="https://cog.sanger.ac.uk/cmp/download/cmp_webguide_20180911p9.jpg">
                <img src={p9} alt="Related models view" className="w-100"/>
            </a>
        </div>
    )
}