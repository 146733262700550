import {scaleLinear, map, select, axisRight, arc, selectAll, mouse}  from 'd3'

function radialBarChart() {

    // Configurable variables
    let margin = {top: 20, right: 20, bottom: 20, left: 20};
    let barHeight = 100;
    let reverseLayerOrder = false;
    let barColors = undefined;
    let capitalizeLabels = false;
    let domain = [0, 100];
    let tickValues = undefined;
    let colorLabels = false;
    let tickCircleValues = [];
    let transitionDuration = 1000;
    let onClickCallback = undefined;

    // Scales & other useful things
    let numBars = null;
    let barScale = null;
    let keys = null;
    let labelRadius = 0;


    function init(d) {
        barScale = scaleLinear().domain(domain).range([0, barHeight]);

        keys = map(d[0].data).keys();
        numBars = keys.length;

        // Radius of the key labels
        labelRadius = barHeight * 1.025;
    }

    function svgRotate(a) {
        return 'rotate('+ +a +')';
    }

    function svgTranslate(x, y) {
        return 'translate('+ +x +','+ +y +')';
    }

    function initChart(container) {
        let g = select(container)
            .append('svg')
            .style('width', 2 * barHeight + margin.left + margin.right + 'px')
            .style('height', 2 * barHeight + margin.top + margin.bottom + 'px')
            .append('g')
            .classed('radial-barchart', true)
            .attr('transform', svgTranslate(margin.left + barHeight, margin.top + barHeight));

        // Concentric circles at specified tick values
        g.append('g')
            .classed('tick-circles', true)
            .selectAll('circle')
            .data(tickCircleValues)
            .enter()
            .append('circle')
            .attr('r', function(d) {return barScale(d);})
            .style('fill', 'none');
    }

    function renderOverlays(container) {
        let g = select(container).select('svg g.radial-barchart');

        // Spokes
        g.append('g')
            .classed('spokes', true)
            .selectAll('line')
            .data(keys)
            .enter()
            .append('line')
            .attr('y2', -barHeight)
            .attr('transform', function(d, i) {return svgRotate(i * 360 / numBars);});

        // Axis
        let axisScale = scaleLinear().domain(domain).range([0, -barHeight]);
        let axis = axisRight(axisScale);
        if(tickValues)
            axis.tickValues(tickValues);
        // g.append('g')
        //     .classed('axis', true)
        //     .call(axis);

        // Outer circle
        g.append('circle')
            .attr('r', barHeight)
            .classed('outer', true)
            .style('fill', 'none');

        // Labels
        let labels = g.append('g')
            .classed('labels', true);

        labels.append('def')
            .append('path')
            .attr('id', 'label-path')
            .attr('d', 'm0 ' + -labelRadius + ' a' + labelRadius + ' ' + labelRadius + ' 0 1,1 -0.01 0');

        labels.selectAll('text')
            .data(keys)
            .enter()
            .append('text')
            .style('text-anchor', 'middle')
            .style('fill', function(d, i) {return colorLabels ? barColors[i % barColors.length] : null;})
            .append('textPath')
            .attr('xlink:href', '#label-path')
            .attr('startOffset', function(d, i) {return i * 100 / numBars + 50 / numBars + '%';})
            .text(function(d) {return capitalizeLabels ? d.toUpperCase() : d;});

    }

    function chart(selection) {
        selection.each(function(d) {

            init(d);

            if(reverseLayerOrder)
                d.reverse();

            let g = select(this).select('svg g.radial-barchart');

            // check whether chart has already been created
            let update = false //= g[0][0] !== null; // true if data is being updated

            if(!update)
                initChart(this);

            g = select(this).select('svg g.radial-barchart');

            // Layer enter/exit/update
            let layers = g.selectAll('g.layer')
                .data(d)
                .enter()
                .append('g')
                .attr('class', function(d, i) {
                    return 'layer-' + i;
                })
                .classed('layer', true);

            layers.exit().remove();
            let tt = select("#mut_tooltip");

            // Segment enter/exit/update
            let segments = layers
                .selectAll('path')
                .data(d => map(d.data).values())
                .enter()
                .append('path')
                .style('fill', function(d, i) {
                    if(!barColors) return;
                    return barColors[i % barColors.length];
                })
                .on("mouseover", (d, e, f) => {
                    selectAll(f)
                        .classed("inactive", true);

                    let d3this = select(f[e]);

                    d3this
                        .classed("inactive", false)
                        .classed("active", true)
                        .raise()
                        .transition()
                        .attr('d', arc().innerRadius(0).outerRadius(or(d * 1.1)).startAngle(sa(d, e)).endAngle(ea(d, e)))

                    tt.select(".model-count")
                        .text(d);
                    tt.selectAll(".mut_name")
                        .text(keys[e])
                })
                .on("mousemove", (d) => {
                    let mouse_coords = mouse(this);
                    tt
                        .classed("hidden", false)
                        .style("transform", "translate(" + (mouse_coords[0] + 50) + "px," + (mouse_coords[1] - 30) + "px)")
                })
                .on("mouseout", (d, e, f) => {
                    selectAll(f)
                        .classed("inactive", false);
                    select(f[e])
                        .classed("active", false)
                        .transition()
                        .attr('d', arc().innerRadius(0).outerRadius(or(d)).startAngle(sa(d, e)).endAngle(ea(d, e)))

                    tt
                        .classed("hidden", true)
                        .style("transform","translate(0px, 0px)")
                } )
                .on("click", onClickCallback);

            segments.exit().remove();

            segments
                .transition()
                .duration(transitionDuration)
                .attr('d', arc().innerRadius(0).outerRadius(or).startAngle(sa).endAngle(ea));

            if(!update)
                renderOverlays(this);
        });

    }

    /* Arc functions */
    let or = function(d, i) {
        return barScale(Math.min(d, domain[1]));
    };
    let sa = function(d, i) {
        return (i * 2 * Math.PI) / numBars;
    };
    let ea = function(d, i) {
        return ((i + 1) * 2 * Math.PI) / numBars;
    };

    /* Configuration getters/setters */
    chart.margin = function(_) {
        if (!arguments.length) return margin;
        margin = _;
        return chart;
    };

    chart.barHeight = function(_) {
        if (!arguments.length) return barHeight;
        barHeight = _;
        return chart;
    };

    chart.reverseLayerOrder = function(_) {
        if (!arguments.length) return reverseLayerOrder;
        reverseLayerOrder = _;
        return chart;
    };

    chart.barColors = function(_) {
        if (!arguments.length) return barColors;
        barColors = _;
        return chart;
    };

    chart.capitalizeLabels = function(_) {
        if (!arguments.length) return capitalizeLabels;
        capitalizeLabels = _;
        return chart;
    };

    chart.domain = function(_) {
        if (!arguments.length) return domain;
        domain = _;
        return chart;
    };

    chart.tickValues = function(_) {
        if (!arguments.length) return tickValues;
        tickValues = _;
        return chart;
    };

    chart.colorLabels = function(_) {
        if (!arguments.length) return colorLabels;
        colorLabels = _;
        return chart;
    };

    chart.tickCircleValues = function(_) {
        if (!arguments.length) return tickCircleValues;
        tickCircleValues = _;
        return chart;
    };

    chart.transitionDuration = function(_) {
        if (!arguments.length) return transitionDuration;
        transitionDuration = _;
        return chart;
    };

    chart.onClickCallback = function(_) {
        if (!arguments.length) return onClickCallback;
        onClickCallback = _;
        return chart;
    };

    return chart;
}

export default radialBarChart;