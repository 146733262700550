import React, {Component} from 'react';
import _ from 'lodash';
import {Col, Card, CardHeader, CardBody, Table} from "reactstrap";
import {gdsc_api_base_url} from "../../../settings";

class DrugData extends Component {
    constructor(props) {
        super(props);
        this.state = {
            drug_data: null,
            drug_data_loaded: false,
            model: props.model
        };

        this.load_drug_data = this.load_drug_data.bind(this)
    }

    load_drug_data() {
        let model = this.state.model;
        let cosmic_id_obj = _.filter(model.identifiers, id => {
            return id.source.name === 'COSMIC_ID';
        });

        if (_.isEmpty(cosmic_id_obj)) {
            this.setState({drug_data: null, link: null});
            return
        }

        let cosmic_id = cosmic_id_obj[0].identifier;

        const drug_data_url = gdsc_api_base_url + '/api/drugs_response?cosmic_id=' + cosmic_id;

        fetch(drug_data_url)
            .then(response => response.json())
            .then(drugs => {
                if (drugs.link) {
                    this.props.setDrugLink( gdsc_api_base_url + drugs.link);
                    this.setState({
                        drug_data: drugs,
                        link: gdsc_api_base_url + drugs.link,
                        drug_data_loaded: true
                    });
                } else {
                    this.setState({
                        drug_data: null,
                        link: null,
                        drug_data_loaded: true,
                    });
                }
            }).catch(function (ex) {
            console.log('parsing failed', ex);
        });

    }

    componentDidMount() {
        this.load_drug_data()
    }
    componentDidUpdate() {
        if (this.state.model.id !== this.props.model.id) {
            this.setState({model: this.props.model, drug_data_loaded: false}, this.load_drug_data)
        }
    }

    render() {
        if (!this.state.drug_data_loaded || this.state.drug_data == null) {
            return null;
        }
        return (
            <Col className="my-3 d-flex" md={6}>
                <Card className="flex-fill drugs">
                    <CardHeader>Drug Sensitivity - highlights from <a
                        href='https://cancerrxgene.org' target="_blank"
                        rel="noopener noreferrer">CancerRxGene.org</a></CardHeader>
                    <CardBody className="card-body">
                        <p>
                            <a href={this.state.link} className="bold" target="_blank"
                               rel="noopener noreferrer"> View drug sensitivity
                                for {this.state.model.names[0]} on CancerRxGene.org</a>
                        </p>
                        <p>
                            Top-5 drugs with lowest z-score
                        </p>
                        <div className="table-responsive">
                        <Table>
                            <thead>
                            <tr>
                                <th>Drug Name<br /><small>(Link to GDSC)</small></th>
                                <th>Drug Targets<br /><small>&nbsp;</small></th>
                                <th>IC50 (μM)<br /><small>&nbsp;</small></th>
                                <th>Z Score<br /><small>&nbsp;</small></th>
                                <th>Dataset<br /><small>&nbsp;</small></th>
                            </tr>
                            </thead>
                            <tbody>{_.map(this.state.drug_data.top_hits, drug => {
                                return (
                                    <tr key={drug.DRUG_ID}>
                                        <td><a
                                            href={gdsc_api_base_url + '/compound/' + drug.DRUG_NAME + "/" + drug.DRUG_ID + '/overview/ic50?screening_set=' + drug.SCREENING_SET} target="_blank" rel="noopener noreferrer">{drug.DRUG_NAME}</a>
                                        </td>
                                        <td>{drug.PUTATIVE_TARGET}</td>
                                        <td>{drug.LN_IC50}</td>
                                        <td>{drug.Z_SCORE}</td>
                                        <td>{drug.SCREENING_SET}</td>
                                    </tr>)
                            })}
                            </tbody>
                        </Table>
                        </div>
                    </CardBody>
                </Card>
            </Col>
        );
    }
}

export default DrugData;
