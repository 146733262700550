import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {
    return (
            <BaseIcon
                {...props}
                tooltipText="CRISPR KO data"
            >

                <path className="cls-1" d="M505.12,370.62c4.13-10.81.18-21.57,0,0Z"/>
                <path d="M103.25,328.64c98.08,57,219.85,44.72,228.38-36.77,16.11-154,124.43-239,110.11-30.44-4.05,58.95-39.39,85.94,28.64,117.27,14.6,6.72,23.57,5,29,.41.1-18.18,0-34.84-13.49-48.27,5.3-12.26,9.73-27.29,13.29-45.56C516.51,196,483.35,114.35,425.09,103S305.51,154.93,288.15,244.27c-4.09,21-4.11,38.72-1,53.72C175.86,298.33,125.78,310.16,103.25,328.64Z"/>
                <path d="M483.81,414.51c-19.73-8-57.29-20.59-73.4-51.92-15.73-30.59,30.43-190.68-.9-201.42-34.89-2.91-55.5,119.06-61.77,166.51-3.18,24.11-25.28,37-57.82,46.44l9.68,41.94L265.15,424l-9.62-41.64c-11.48,2.38-23.66,4.66-36.29,7l9.42,40.83-34.45,8-11.06-47.9c-34.81-3.1-83.26-13.51-97.74-25.43-.6,6.37-.6,13-.6,19.84,0,47.9,0,86.72,207.26,86.72,158,0,195.56-22.57,204.48-54.51A28.77,28.77,0,0,1,483.81,414.51Z"/>
                <polygon
                    points="162.96 43.12 136.54 16.71 92.01 61.24 47.48 16.71 21.06 43.12 65.6 87.65 21.06 132.19 47.48 158.6 92.01 114.06 136.54 158.6 162.96 132.19 118.42 87.65 162.96 43.12"
                    transform="translate(84.81 101.71)"
                />
            </BaseIcon>
    )

}