import React, { Component } from 'react';
import Autocomplete from 'react-autocomplete';
import Deserialiser from 'deserialise-jsonapi'
import {api_base_url} from "../../../settings";
import {debounce} from "../../../utils"
let des = new Deserialiser();

class GeneticFilterAutocomplete extends Component {


    constructor(props){
        super(props);

        this.getItemsFromAPI = this.getItemsFromAPI.bind(this);
        this.state = {
            value: '',
            items: []
        };
        this.d = new Date();

    }


    getItemsFromAPI(query){

        let apiurl = api_base_url + '/genes?page[size]=5&filter=[{"name":"identifiers","op":"any","val":{"name":"identifier","op":"startswith","val":"' + query + '"}}]&fields[gene]=symbol'
        fetch(apiurl)
            .then(response => response.json())
            .then(data => {
                return des.deserialise(data)
            })
            .then((genes) => {
                this.setState({items: genes})
            })
    }

    debouncedGetItemsFromAPI = debounce((query) => this.getItemsFromAPI(query), 100);



    render(){
        return(
            <Autocomplete className="form-group"
                inputProps={{ className: 'autocomplete form-control', placeholder: "Type gene symbol..." }}
                wrapperStyle={{ position: 'relative', display: 'inline-block' }}
                value={this.state.value}
                items={this.state.items}
                getItemValue={(item) => item.symbol}
                onSelect={(value, item) => {
                    this.setState({
                        value: '',
                        items: []
                    });
                    this.props.onSelect(item);
                }}
                onChange={(event, value) => {
                    this.setState({value: value.toUpperCase()});
                    this.debouncedGetItemsFromAPI(value.toUpperCase())
                }}
                renderMenu={children => (
                    <div className="menu">
                        {children}
                    </div>
                )}
                renderItem={(item, isHighlighted) => (
                    <div
                        className={`item ${isHighlighted ? 'item-highlighted z1' : ''}`}
                        key={item.symbol}
                    >{item.symbol}</div>
                )}
            />
        )


    }
}

export default GeneticFilterAutocomplete;
