import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {
    return (
            <BaseIcon
                {...props}
                tooltipText="RNA-Seq data"
            >

                <path class="st0" d="M151,474c-12.3-148.1,73.6-211.6,184.2-220.8c74.1-6.1,173.2,21.3,111.4-129.6c-1.4-3.5-8.4-3.9-8.4-3.9s-17-11.5-16.7-7.8c1.3,15.2,1.8,28.4,1.7,39.9c-0.8,101.1-52.3,72.3-129.8,81.9c-73.8,9.1-229.4,77.7-167.5,228.6"/>
                <rect x="165.9" y="352.2" transform="matrix(0.5736 -0.8192 0.8192 0.5736 -257.8265 325.5279)" class="st0" width="35.6" height="116.5"/>
                <polygon class="st0" points="229,379.3 158.3,326.2 180.6,300.9 251.3,354 "/>
                <polygon class="st0" points="430.5,188.5 368.7,131 386.5,105 448.3,162.4 "/>
                <polygon class="st0" points="252,302 231.3,282.9 253.9,263.4 274.7,282.5 "/>
                <polygon class="st0" points="388.6,242 349.6,201.6 371.8,182.8 410.7,223.2 "/>
            </BaseIcon>
    )

}