import React from "react";

const customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total small">
        ({size} total)
    </span>
);


const sizePerPageGenerator = (maxSize) => {
    return ({options, currSizePerPage, onSizePerPageChange}) => {

        return (parseInt(currSizePerPage) !== maxSize) ?
            <button className="btn btn-link"
                    onClick={() => onSizePerPageChange(options[1].page)}>
                Show all
            </button> :
            <button className="btn btn-link"
                    onClick={() => onSizePerPageChange(options[0].page)}>
                Show {options[0].text} per page
            </button>
    }
}


export const paginationOptions = (pageSize, maxSize) => {
    return {
    sizePerPageList: [{
        text: `${pageSize}`, value: pageSize
    }, {
        text: 'All', value: maxSize
    }],
    showTotal: true,
    paginationSize: 3,
    hidePageListOnlyOnePage: true,
    paginationTotalRenderer: customTotal,
    sizePerPageRenderer: sizePerPageGenerator(maxSize),
}};