import React, {Component} from 'react';
import {Col, Container, Nav, NavItem, NavLink, ListGroupItemHeading, Row} from "reactstrap";
import {Route, Switch, NavLink as RRNavLink} from 'react-router-dom';

//import Main from './documentation/home';
import About from './documentation/cellmodelpassports/about'
import Model from './documentation/cellmodelpassports/model'
import Datasets from './documentation/cellmodelpassports/datasets'
import Model_General from './documentation/models/general'
import Relationships from './documentation/models/relationships'
import Authentication from './documentation/models/authentication'
import Glossary from './documentation/models/glossary'
import Datasets_general  from './documentation/datasets/general'
import Sequencing from './documentation/datasets/sequencing'
import CNV from './documentation/datasets/cnv'
import Expression from './documentation/datasets/expression'
import Methylation from './documentation/datasets/methylation'
import Drivergenes  from './documentation/datasets/drivergenes'
import Website from './documentation/guides/website'
import API from './documentation/guides/API'

class Documentation extends Component {

    render(){
        return(
            <Container fluid className="docs-container">
                <Row>
                    <Col sm={4} lg={3} xl={2} className="docnav">
                        <Nav vertical className="my-3">
                            {/*<NavItem>*/}
                                {/*<NavLink tag={RRNavLink} to="/documentation" className="pl-0 pt-0 small">Documentation Home</NavLink>*/}
                            {/*</NavItem>*/}
                            <ListGroupItemHeading className="mt-3">
                                <NavItem>
                                  <NavLink tag={RRNavLink} to="/documentation/cellmodelpassports/about">Cell Model Passports</NavLink>
                                </NavItem>
                            </ListGroupItemHeading>
                            <NavItem>
                                <NavLink tag={RRNavLink} to="/documentation/cellmodelpassports/about">About</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://depmap.sanger.ac.uk/documentation/cell-models">Models Overview</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://depmap.sanger.ac.uk/documentation/datasets">Datasets Overview</NavLink>
                            </NavItem>

                            <ListGroupItemHeading className="mt-4">
                                <NavItem>
                                    <NavLink tag={RRNavLink} to="/documentation/models/relationships">Models</NavLink>
                                </NavItem>
                            </ListGroupItemHeading>
                            {/*<NavItem>*/}
                                {/*<NavLink tag={RRNavLink} to="/documentation/models/general">General</NavLink>*/}
                            {/*</NavItem>*/}
                            <NavItem>
                                <NavLink href="https://depmap.sanger.ac.uk/documentation/cell-models/model-relationships">Model Relationships</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://depmap.sanger.ac.uk/documentation/glossary-terms">Glossary of Terms</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://depmap.sanger.ac.uk/documentation/datasets/model-authentication-datasets">Model Authentication</NavLink>
                            </NavItem>
                            {/*<ListGroupItemHeading className="mt-4">*/}
                                {/*Datasets*/}
                            {/*</ListGroupItemHeading>*/}
                            {/*<NavItem>*/}
                                {/*<NavLink tag={RRNavLink} to="/documentation/datasets/general">General</NavLink>*/}
                            {/*</NavItem>*/}
                            {/*<NavItem>*/}
                                {/*<NavLink tag={RRNavLink} to="/documentation/datasets/sequencing">Genomic Sequencing</NavLink>*/}
                            {/*</NavItem>*/}
                            {/*<NavItem>*/}
                                {/*<NavLink tag={RRNavLink} to="/documentation/datasets/cnv">Copy Number Variations</NavLink>*/}
                            {/*</NavItem>*/}
                            {/*<NavItem>*/}
                                {/*<NavLink tag={RRNavLink} to="/documentation/datasets/expression">Gene Expression Data</NavLink>*/}
                            {/*</NavItem>*/}
                            {/*<NavItem>*/}
                                {/*<NavLink tag={RRNavLink} to="/documentation/datasets/methylation">DNA Methylation</NavLink>*/}
                            {/*</NavItem>*/}
                            {/*<NavItem>*/}
                                {/*<NavLink tag={RRNavLink} to="/documentation/datasets/drivergenes">Cancer Driver Genes</NavLink>*/}
                            {/*</NavItem>*/}
                            <ListGroupItemHeading className="mt-4">
                                <NavItem>
                                    <NavLink tag={RRNavLink} to="/documentation/guides/website">User Guides</NavLink>
                                </NavItem>
                            </ListGroupItemHeading>
                            <NavItem>
                                <NavLink tag={RRNavLink} to="/documentation/guides/website">Website</NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink href="https://depmap.sanger.ac.uk/documentation/api">API</NavLink>
                            </NavItem>


                        </Nav>
                    </Col>
                    <Col sm={8} lg={9} xl={10} className="docmain">
                        <Col xl={{size: 8, offset: 1}} className="mt-5" >
                            <Switch>
                                <Route path="/documentation/cellmodelpassports/about" component={About}/>
                                <Route path="/documentation/cellmodelpassports/models" component={Model}/>
                                <Route path="/documentation/cellmodelpassports/datasets" component={Datasets}/>
                                <Route path="/documentation/models/general" component={Model_General}/>
                                <Route path="/documentation/models/relationships" component={Relationships}/>
                                <Route path="/documentation/models/glossary" component={Glossary}/>
                                <Route path="/documentation/models/authentication" component={Authentication}/>
                                <Route path="/documentation/datasets/general" component={Datasets_general}/>
                                <Route path="/documentation/datasets/sequencing" component={Sequencing}/>
                                <Route path="/documentation/datasets/cnv" component={CNV}/>
                                <Route path="/documentation/datasets/expression" component={Expression}/>
                                <Route path="/documentation/datasets/methylation" component={Methylation}/>
                                <Route path="/documentation/datasets/drivergenes" component={Drivergenes}/>
                                <Route path="/documentation/guides/website" component={Website}/>
                                <Route path="/documentation/guides/API" component={API}/>
                                <Route path="/documentation/" component={About}/>
                            </Switch>
                        </Col>
                    </Col>
                </Row>

            </Container>
            )
    }

}

export default Documentation;