import React from 'react'
import radialBarChart from './radialBarChart'

import {select} from 'd3';
import _ from 'lodash';
import {withRouter} from "react-router-dom";
import {api_base_url} from "../../settings";

class MutationsChart extends React.Component {

    constructor(props){
        super(props);
        this.createMutationsChart = this.createMutationsChart.bind(this);
        this.onclickcallback = this.onclickcallback.bind(this);
        this.chart = radialBarChart()
            .barHeight(200)
            .domain([0, 200])
            .tickCircleValues([100])
            .onClickCallback(this.onclickcallback)
    }

    componentDidMount() {
        fetch(api_base_url + "/mutationchart_data")
            .then(response => response.json())
            .then(response => this.setState({mutationCounts: response}))
            .catch(error => console.log(error))

    }
    componentDidUpdate() {
        this.createMutationsChart()
    }

    onclickcallback(d, e) {
        let clickedGene = _.keys(this.state.mutationCounts.data)[e];

        this.props.history.push("/passports?mutated=" + clickedGene)
        window.scrollTo(0,0);

    }

    createMutationsChart() {

        select(this.node)
            // takes a list, because it's possible to add multiple layers
            .datum([this.state.mutationCounts])
            .call(this.chart)

    }

    render(){
        return(
            <div>
                <div ref={node => this.node = node} id="mutations-chart-container" style={{minHeight: '450px'}}>
                </div>
                <div id="mut_tooltip" className="hidden z4">
                    <span className="mut_name"></span> <span>mutants</span><br />
                    <span className="model-count"></span><span> Models</span> <br />
                    <span className="explain">Click to view</span>
                </div>
            </div>
        )
    }

}

export default withRouter(MutationsChart);
