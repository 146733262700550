import React, {Fragment} from 'react';
import {HashLink} from "react-router-hash-link";
import {Table} from "reactstrap"

export default function () {
    return (
        <Fragment>

            <h1>Glossary of Terms</h1>
            <span className="small">Scroll to:&nbsp;&nbsp;&nbsp;<HashLink
                to="#identifiers">Identifiers</HashLink>&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;
                <HashLink
                    to="#description">Model Descriptors & Derivation Information</HashLink>&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;
                <HashLink
                    to="#clinical">Clinical Information</HashLink>&nbsp;&nbsp;&nbsp;- &nbsp;&nbsp;
                <HashLink to="#genomics">Model Genomics</HashLink></span>


            <h4 id="identifiers" className="mt-4">Identifiers</h4>
            <Table size="sm" responsive={true}>
                <tr>
                    <td className='w-25 bold'>Passport Identifiers</td>
                    <td>
                        <p>
                            The core identifiers used by the passport represent each
                            point in the Patient, Sample, Model hierarchy. See <a href="/documentation/models/relationships">model relationships</a> for additional information.
                        </p>
                        <ul>
                            <li>Sanger IDentifier Patient (SIDP)</li>
                            <li>Sanger IDentifier Sample (SIDS)</li>
                            <li>Sanger IDentifier Model (SIDM)</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>External Resource Identifiers</td>
                    <td>
                        <p>Additional commonly used identifiers and database links to
                            support cross referencing and integration of data sets.
                            Currently these comprise of:
                        </p>
                        <ul>
                            <li>COSMIC</li>
                            <li>Cellosaurus (RRID)</li>
                            <li>Broad (CCLE)</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Supplier & Catalogue Identifiers</td>
                    <td>
                        <p>Repositories and cell banks where the cell model can be
                            obtained with
                            their associated catalogue ID's.</p>
                    </td>
                </tr>
            </Table>

            <h4 id="description" className="mt-4">
                Model Descriptors & Derivation Information
            </h4>
            <Table size="sm" responsive={true}>
                <tr>
                    <td className='w-25 bold'>Model Name</td>
                    <td>
                        <p>The name of the model as used by the Sanger Dependency Map.
                            Synonyms are
                            also been provided where applicable.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Tissue</td>
                    <td>
                        <p>Describes the site of the tumour from which the sample was
                            taken. Where a
                            model was derived from a metastatic tissue lists the site of
                            the primary
                            tumour.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Cancer Type</td>
                    <td>
                        <p>A broad disease classification describing the sample tumour.
                            Cancer type
                            also highlights samples obtained from non-cancerous tissue.
                            All cancer
                            types are NCIT terms.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Cancer Type Detail & NCIT ID</td>
                    <td>
                        <p>The most detailed description of the tumour available on the
                            passport
                            using NCI Thesaurus terms. Definitions of these terms are
                            available via
                            the NCIT ID links.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Tissue Status</td>
                    <td>
                        <p>Defines the sample as originating from:</p>
                        <ul>
                            <li>Tumour</li>
                            <li>Metastasis</li>
                            <li>Normal</li>
                            <li>Transformed (e.g. lymphoblastoid cells transformed using
                                Epstein Barr
                                virus)
                            </li>
                            <li>Precancerous (e.g. Adenoma or Barrett's Esophagus)</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Sample Site</td>
                    <td>
                        <p>The location or tissue from which the sample was taken.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Growth Properties</td>
                    <td>
                        <p>Models have been grouped into three categories, Adherent,
                            Semi-Adherent
                            and Suspension.For detailed information on the cell type and
                            morphology
                            refer to the repository documentation.
                            Only applies to cell line models.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Relationship Details</td>
                    <td>
                        <p>A description of the relationship between models linked to
                            the same
                            patient and the source of the data to support this
                            relationship.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Model Treatment</td>
                    <td>
                        <p>Model treatments occur during or following model derivation.
                            Drug
                            resistant subclones are the most frequent example.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Date Generated</td>
                    <td>
                        <p>The date that the model was generated, sourced from
                            repositories or
                            publications.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Publication</td>
                    <td>
                        <p>The original publication outlining how the cell model was
                            established.</p>
                    </td>
                </tr>

            </Table>


            <h4 id="clinical" className="mt-4">Clinical Information</h4>

            <Table size="sm" responsive={true}>
                <tr>
                    <td className='w-25 bold'>Species, Gender & Ethnicity</td>
                    <td>
                        <p>Basic patient information. Ethnicity has been divided into
                            six broad
                            classifications based on the data available, for more
                            specific data
                            refer to the cell model supplier.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Age</td>
                    <td>
                        <p>The age of the patient at the time sample tissue was obtained
                            for model
                            derivation. All ages are presented in years.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Smoking Status</td>
                    <td>
                        <p>Describes the patient’s smoking history:</p>
                        <ul>
                            <li>Current Smoker</li>
                            <li>Ex-Smoker</li>
                            <li>Non-Smoker</li>
                            <li>Never Smoked</li>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Sample Treatment <br/>(Prior Treatment) & <br/>Treatment
                        Details
                    </td>
                    <td>
                        <p>Treatment data has been annotated both at the sample and
                            model
                            level.Sample treatment refers to any therapy the patient
                            received prior
                            to sampling and has been broadly classified into groups such
                            as
                            chemotherapy and radiotherapy. Treatment Details provides
                            specific
                            information on the drugs or treatment undertaken.</p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>TNM Status</td>
                    <td>
                        <p>A set of clinical descriptions used within to describe
                            tumours.</p>
                        <ul>
                            <li>Tumour (T) describes the size of the tumour.</li>
                            <li>Node (N) details if the cancer has spread to the lymph
                                nodes.
                            </li>
                            <li>Metastasis (M) describes if the cancer has spread to
                                other areas on
                                the body.
                            </li>
                            <p>
                                Details descriptions of the subclassification can be
                                found <a
                                href="https://www.cancer.org/treatment/understanding-your-diagnosis/staging.html">here.</a>
                            </p>
                        </ul>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Tumour Stage</td>
                    <td>
                        <p>Describes the size of the tumour and and it’s spread from the
                            location in
                            which it originated.
                            Stage descriptions within the passports associated with
                            organoids use
                            established NHS standards
                            found <a
                                href="https://www.nhs.uk/common-health-questions/operations-tests-and-procedures/what-do-cancer-stages-and-grades-mean/">here.</a>
                        </p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Tumour Grade</td>
                    <td>
                        <p>Provides information on the cell types and aggressiveness of
                            a tumour.
                            Grade descriptions within
                            the passports associated with organoids use established NHS
                            standards <a
                                href="https://www.nhs.uk/common-health-questions/operations-tests-and-procedures/what-do-cancer-stages-and-grades-mean/">here.</a>
                        </p>
                    </td>
                </tr>
            </Table>

            <h4 id="genomics" className="mt-4">Model Genomics</h4>
            <Table size="sm" responsive={true} className="mb-5">
                <tr>
                    <td className='w-25 bold'>MSI Status</td>
                    <td>
                        <p>Displays the microsatellite instability status of a cell
                            model. Models
                            have been characterised using the following markers; BAT25,
                            BAT26,
                            D5S346, D2S123 and D17S250.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Ploidy</td>
                    <td>
                        <p>Refers to the ploidy status of the cell model determined by
                            using mean
                            copy-number obtained from PICNIC using the Affymetrix SNP6
                            data.
                        </p>
                    </td>
                </tr>
                <tr>
                    <td className='bold'>Mutations per Mb</td>
                    <td>
                        <p>Shows the mutation rate per Mb estimated from Whole Exome
                            Sequencing.
                        </p>
                    </td>
                </tr>

            </Table>

        </Fragment>
    )
}