import React from 'react';
import PaginationBlock from './pagination'

class ModelListFooter extends React.Component {

    render(){
        return(
            <PaginationBlock
                registerRequest={this.props.registerRequest}
                registerResponse={this.props.registerResponse}
                unregisterRequest={this.props.unregisterRequest}
                unregisterResponse={this.props.unregisterResponse}
                pagination={this.props.pagination}
                total={this.props.total}
            />
        )
    }
}

export default ModelListFooter