import React, {Fragment} from 'react';

import smp from '../../../../../src/assets/img/PatientSampleModel_Publication_Web.jpg'

export default function(){
    return(
        <Fragment>
            <h1>Patient, Sample & Model Relationships</h1>
            <p>The Cell Model Passports connects cell models originating from the same patient or sample and identifies the parental cell model of sub-clones or derivatives.
            </p>
            <p>Where multiple models have been derived from a single patient the Cell Model Passports aims to provide details on how the samples are related, enabling researchers to make informed decisions regarding their use.
            </p>
            <p>An example Patient, Sample, Model relationship is illustrated in the figure below:
            </p>
            <a href={smp} target="_blank" rel="noopener noreferrer">
                <img src={smp} className="w-100 my-5" alt="Visual representation of Sample, Model and Patient Hierarchy" />
            </a>
            <p>
                Model relationships are constructed by combining information obtained during annotation with data
                obtained from model identification resources such as International Cell Line Authentication
                Committee (<a href="http://iclac.org/" target="_blank" rel="noopener noreferrer">ICLAC</a>) and additional publications. In addition STR profiles obtained during model
                curation or internally at the Sanger Institute are compared to inform model-patient associations.
                The Cell Model Passports conservatively assigns models sharing common ancestry to separate samples
                from the same patient unless evidence indicates otherwise.
            </p>
            <br />
        </Fragment>
    )
}