import React from 'react';
import {Col, Row} from "reactstrap";
// import SortSelect from "./sortSelect";
import ListSummary from "./ListSummary";

class ModelListHeader extends React.Component {

    constructor(props){
        super(props)
        this.state = {};
    }

    render(){
        return(
            <Row>
                <Col>
                    <div className="ml-1 mt-2 d-flex flex-row align-bottom">
                        <h2>Model List</h2>
                        <ListSummary
                            registerRequest={this.props.registerRequest}
                            registerResponse={this.props.registerResponse}
                            unregisterRequest={this.props.unregisterRequest}
                            unregisterResponse={this.props.unregisterResponse}
                            models={this.props.models}
                        />
                    </div>


                </Col>
                {/*<Col className="justify-content-end">*/}
                    {/*<SortSelect registerRequest={this.props.registerRequest}*/}
                                {/*registerResponse={this.props.registerResponse} />*/}

                {/*</Col>*/}
            </Row>
        )
    }
}

export default ModelListHeader
