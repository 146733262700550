import React, {Component, Fragment} from 'react';
import Autocomplete from 'react-autocomplete';
import {Col, Form} from "reactstrap";
import qs from "qs";
import {withRouter, Link} from "react-router-dom";
import {api_base_url} from "../../settings";
import {debounce} from "../../utils";
import _ from 'lodash'

class HomeSearch extends Component {

    constructor(props){
        super(props);
        this.renderSearchFilter = this.renderSearchFilter.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.getItemsFromAPI = this.getItemsFromAPI.bind(this);
        this.activate = this.activate.bind(this);
        this.deactivate = this.deactivate.bind(this);
        this.instantSearch = this.instantSearch.bind(this);
        this.escFunction = this.escFunction.bind(this);

        this.state = {
            query: "",
            items: [],
            active: false,
            error: false,
            value: "",
            suggestions: [],
            loading: false
        }
        this.minQueryLength = 2

    }

    handleChange(event) {
        this.setState({query: event.target.value});
    }

    escFunction(event){
        if(event.keyCode === 27) {
          this.deactivate()
        }
      }

    handleSubmit(event) {
        event.preventDefault();
        if(!this.state.error) {
            let query = event.target.querySelector('input.autocomplete').value
            window.scrollTo(0, 0);
            this.props.history.push("/passports" + qs.stringify({q: query}, {addQueryPrefix: true}));
        }
    }

    instantSearch(item){
        window.scrollTo(0,0);
        this.props.history.push(item.url);
    }

    componentDidMount() {
        let qstring = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        this.setState({ query: ('q' in qstring)? qstring.q: ""});
        document.addEventListener("keydown", this.escFunction, false);
    };

    componentWillUnmount() {
        document.removeEventListener("keydown", this.escFunction, false);
    }

    getItemsFromAPI(query){

        let apiurl = api_base_url + '/cmp_autocomplete/' + query;
        fetch(apiurl)
            .then(response => response.json())
            .then((resp) => {
                if(_.isArray(resp)) {
                    this.setState({
                        items: resp,
                        suggestions: [],
                        loading: false,
                        error: false
                    })
                } else{
                    this.setState({
                        items: resp.results,
                        suggestions: resp.suggestions,
                        loading: false,
                        error: false
                    })
                }
            })
            .catch(() => {
                this.setState({items: [], error: true})
            })
    }

    activate(){
        this.setState({active: true})
    }
    deactivate(){
        this.setState({active: false})
    }

    debouncedGetItemsFromAPI = debounce((query) => this.getItemsFromAPI(query), 100);

    renderSearchFilter(){

        return(
            <Form onSubmit={this.handleSubmit}>
                <Autocomplete
                    open={true}
                    className="form-group"
                    inputProps={{
                        className: 'autocomplete form-control',
                        placeholder: this.props.placeholder || "Find Cell Model...",
                        onFocus: this.activate,
                        onBlur: this.deactivate,
                        onClick: this.activate,
                        type: "search"
                    }}
                    wrapperProps={{className: 'home-search-container' + (this.state.active? ' active': '') + ` ${this.props.wrapperClasses || ''}`}}
                    value={this.state.value}
                    items={this.state.items}
                    getItemValue={(item) => item.text}
                    onSelect={(value, item) => {
                        this.setState({value: ""});
                        this.instantSearch(item)
                        this.deactivate()
                    }}
                    onChange={(event, value) => {
                        if(value.length >= this.minQueryLength) {
                            this.setState({loading: true})
                            this.debouncedGetItemsFromAPI(value)
                            this.setState({value});}
                        else {
                            this.setState({value, items: []})
                        }}}
                    renderMenu={(children) => {

                        if(this.state.error){
                            return <div className="menu">
                                <span className="text-danger bolder">Oops, something went wrong</span>
                            </div>
                        }

                        let suggestions = (this.state.suggestions.length > 0) && (
                            <div className="suggestions-block">
                                <span className="text">Did you mean </span>
                                <span className="suggestions">
                                    {(this.state.suggestions.length > 2) && _.map(this.state.suggestions.slice(0, -2), (suggestion) => {
                                        return <Link key={suggestion}
                                                     to={"/passports?q=" + suggestion}>{suggestion}, </Link>
                                    })}
                                    {(this.state.suggestions.length > 1) && (
                                        <Fragment>
                                            <Link key={this.state.suggestions.slice(-2)[0] } to={"/passports?q=" + this.state.suggestions.slice(-2)[0]}>
                                                {this.state.suggestions.slice(-2)[0]}
                                            </Link>{" or "}
                                        </Fragment>
                                        )}

                                    {<Link key={this.state.suggestions.slice(-1)[0] } to={"/passports?q=" + this.state.suggestions.slice(-1)[0]}>{this.state.suggestions.slice(-1)[0]}</Link>}
                                </span>
                            </div>
                        );

                        if(this.state.value.length < this.minQueryLength){
                            return <div className="menu"></div>
                        } else if(this.state.loading){
                            return <div className="menu"><span>Loading...</span></div>
                        } else if(children.length > 0){
                                return <div className="menu">{children}</div>
                        } else {
                            return <div className="menu"><span>No models found</span>{suggestions}</div>
                        }

                    }}
                    renderItem={(item, isHighlighted) => (
                        item.header ?
                            <div className="item item-header" key={item.text}>
                                {item.text}</div>:
                            <div className={`item ${isHighlighted ? 'item-highlighted' : ''}`} key={item.text}>
                                <span className="name">{item.text}</span>
                                <span className="cancer-type">{item.cancer_type}</span>
                                {(item.synonym !== item.text) && <span className='synonym'>{item.synonym}</span>}</div>
                    )}
                    isItemSelectable={(item) => !item.header}
                    autoHighlight={false}
                />
            </Form>

        )


    }

    render(){

        return(
            <Col sm={this.props.sm} md={this.props.md} xl={this.props.xl} className={this.props.className}>
                {this.renderSearchFilter()}
            </Col>
        )
    }
}

export default withRouter(HomeSearch)
