import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {
        return (
            <BaseIcon style={props.style} active={props.active}>
                <path d="M341.1,168.2C315.79,123,288.32,96.8,228.41,97c-118.77.37-113.69,30.16-125.67,81.38C85.22,253.3,194.16,287.16,233.11,289.75,279.12,292.81,369.39,218.67,341.1,168.2Zm-67,5.39c-14.2,20-21.24,15.66-39.16,15.66s-30.29-5.47-32.58-23.87C197.07,124.58,286.2,145.85,274.09,173.59Z" transform="translate(-72.2 -96.26)"/>
                <path class="cls-1" d="M96.22,469.34" transform="translate(-72.2 -96.26)"/>
                <path d="M475.47,131.32c-20.21-20.79-64.16-51.3-89.24-24.64-16.26,17.28,12,45.89,15.84,83.47,7.75,75.15-75.35,80.15-94.2,147.9-6.69,24,13,94.29,53,108.8,146.36,53,107-75.25,132.18-162.06C520.25,191.18,503.82,160.48,475.47,131.32ZM412.34,336.24c-21.15,38.77-28.58,40.3-39.12,33.81s-13.19-19.49,1.07-48.92c14.54-30,27.9-30.22,38.45-23.73S427.37,308.67,412.34,336.24Z" transform="translate(-72.2 -96.26)"/>
                <path d="M208.31,338.46C124.43,326,121.13,279.8,87.92,284.31c-29.71,4-10,87.46,0,112.82,24.91,63.18,83,63.18,120.39,58.67,42.26-5.1,56.93-5.65,73.69-34.8C302.76,384.9,299.65,352,208.31,338.46Zm-56.07,56.71c-17.32,19.68-21.13,9.51-31.35-7.92-24.67-34.83-19-74.55,31.35-31.67C167,368.14,162.69,383.29,152.24,395.17Z" transform="translate(-72.2 -96.26)"/>
                <path d="M229,173.08c-14.9,11.31-21.78-8.57-7.4-17.08,6.7-4.57,9.91-6,12.81,1.3C236.45,162.51,233,169.31,229,173.08Z" transform="translate(-72.2 -96.26)"/>
                <path d="M145,387.29c-12.06,13.39-21.26-4.2-9.47-14.65C147.21,358.44,152.58,379.17,145,387.29Z" transform="translate(-72.2 -96.26)"/>
                <path d="M391.18,333c-8.93-3.91,5-31,13.69-23.48C414.24,313.45,398.72,336.18,391.18,333Z" transform="translate(-72.2 -96.26)"/>
            </BaseIcon>
    )

}
