import React, {Fragment} from 'react';
import {
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown
} from "reactstrap";
import {humanFileSize} from "../../../utils";
import _ from 'lodash';

export default function (props) {
    let g = props.group;

    let versions = _.reverse(_.sortBy(g.files, 'timestamp'));

    if (versions.length === 0) {
        return <p className="bold pt-3">{g.title}</p>
    }
    let latest = versions[0];

    return (
        <div>
            <p className="bold pt-3">{g.title}</p>
            <p className="mb-0">
                {g.link_lead} <strong><a href={latest.url}>{g.link_text}</a></strong> {latest.size ? "(" + humanFileSize(latest.size) + ")" : ""} <br />
                <em><small>{g.description}</small></em>
            </p>
            {(!g.title.includes('CRISPR') && !g.category_id === 9) &&
            <div className="small">
                <UncontrolledDropdown>
                    Archive:
                    <DropdownToggle caret color="link">
                        View all versions
                    </DropdownToggle>
                    <DropdownMenu>
                        <DropdownItem header>Current</DropdownItem>
                        <DropdownItem tag="a" href={latest.url} >{latest.url.substring(latest.url.lastIndexOf('/')+1)}</DropdownItem>

                        {(versions.length > 1) && (
                            <Fragment>
                                <DropdownItem header>Previous</DropdownItem>
                                {versions.slice(1).map(v => {
                                    let filename = v.url.substring(v.url.lastIndexOf('/')+1);
                                    return <DropdownItem tag="a" href={v.url} key={v.id}>{filename}</DropdownItem>
                                })}
                            </Fragment>
                        )}
                    </DropdownMenu>
                </UncontrolledDropdown>

                {g.latest_link && (
                    <p>Stable <a href={g.latest_link}>link</a> that always points to the latest version.</p>
                )}

            </div>}
        </div>
    )
}