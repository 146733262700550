import React, { Component } from 'react';
import {Switch, Route} from 'react-router-dom';
import Home from '../pages/home';
import Passports from '../pages/passports';
import Documentation from '../pages/documentation';
import Downloads from '../pages/downloads';
import Changes from '../pages/changes';
import Tutorials from '../pages/tutorials';
import Links from '../pages/links';
import CellModelPage from '../pages/cellModelPage';

class Main extends Component {

    render(){
        return(
            <Switch>
                <Route path="/links" component={Links}/>
                <Route path="/documentation" component={Documentation}/>
                <Route path="/passports/:modelId" component={CellModelPage}/>
                <Route path="/hcmi/:hcmiId" component={CellModelPage}/>
                <Route path="/passports" component={Passports}/>
                <Route path="/downloads" component={Downloads}/>
                <Route path="/changes" component={Changes}/>
                <Route path="/tutorials" component={Tutorials}/>
                <Route path="/" component={Home}/>
            </Switch>
        )
    }

}

export default Main;