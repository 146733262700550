import React from 'react';
import BaseIcon from './baseIcon';

export default (props) => {

    return (
        <BaseIcon style={props.style} active={props.active}>

            <circle cx="300.5" cy="186.5" r="85.5"/>
            <path d="M480,424.7c0,12.3,0,75.3,0,75.3H120c0,0,0-41,0-74.8S203.1,293,300,293S480,391,480,424.7z"/>
        </BaseIcon>
    )

}