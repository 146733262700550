import React, {Fragment} from 'react';

export default function(){
    return(
        <Fragment>
            <h1>Comprehensive list of Cancer Genes and Variants</h1>
            <p>
               Using pre-existing datasets we have generated a list of cancer genes comprising of both oncogenes and tumour suppressor genes.
               Each of the cell lines have been annotated with the presence of a known driver mutation for an oncogene or a loss of function mutation in a tumour suppressor gene. 
            </p>
            <p>
              The datasets used to generate this list of cancer genes and variants are listed below:
            </p>
            <ol>
              <li><p>Tamborero et al. Cancer Genome Interpreter annotates the biological and clinical relevance of tumor alterations. Genome Medicine, 2018.</p></li> 
             
              <p> This is a compiled inventory of cancer genes that drive cancer growth or predispose to the disease. Its combines a number of databases including DoCM (PMID:27684579),
              ClinVar (PMID:26582918) and OncoKB (PMID:28890946) aswell as the results of several published experimental assays and additional manual curation efforts.
            </p>
            <li>   <p>  Chang et al. Accelerating Discovery of Functional Mutant Alleles in Cancer. Cancer Discovery, 2018.</p>
            </li>
             <p>  Analysis of 24,592 advanced disease tumours across the MSKCC IMPACT gene list fo which there are 247 cancer genes </p> 
            
            <li>  <p>    Bailey et al. Comprehensive Characterization of Cancer Driver Genes and Mutations. Cell, 2018.</p> 
            </li>
           
            <p>
            The identification of 299 cancer driver genes from the analysis if 9,423 tumour exomes comprising all of the 33 TCGA projects.
            Genes and variants used from this dataset were restricted to those genes in which variant information was provided. 
            </p>
            </ol>
            <p>
              These three lists of cancer genes and associated variants were merged by gene name and protein mutation, providing a list of 348 cancer genes and 8016 variants.
              Using the TSGene database ("https://bioinfo.uth.edu/TSGene/")
                                         and genes containing the acronym "TSG" in the COSMIC Cancer Gene Census gene list a tumour suppressor
                                         gene list of 1386 genes was curated. 191 of these genes was found to be already present our initial 348 cancer gene list and were therefore annotated as a tumour suppressor. 
            
            </p>
            <p>The final cancer gene list contains 157 oncogenes with 2233 associated variants and 191 tumour suppressor genes.
            </p>
        </Fragment>
    )
}