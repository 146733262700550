import {Dropdown, DropdownItem, DropdownMenu, DropdownToggle} from "reactstrap";
import React, {Component} from "react";


export class ColumnToggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            activeColumns: 5
        };
        this.toggle = this.toggle.bind(this);
        this.onColumnToggleHandler = this.onColumnToggleHandler.bind(this);
    }

    toggle() {
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
    }

    onColumnToggleHandler(column, onColumnToggle) {
        let activeColumnCount = (column.toggle) ? this.state.activeColumns - 1 : this.state.activeColumns + 1;
        this.setState(prevState => ({
            activeColumns: activeColumnCount
        }));
        onColumnToggle(column.dataField);
        //this.props.columnToggleCallback(activeColumnCount)
    }

    render() {
        return (
            <Dropdown isOpen={this.state.dropdownOpen} toggle={this.toggle}>
                <DropdownToggle caret className="btn btn-link secondary" tag='button'>
                    Display
                    columns {`(${this.state.activeColumns-1}/${Object.keys(this.props.toggles).length})`}
                </DropdownToggle>
                <DropdownMenu>

                    {
                        this.props.columns
                            .map(column => ({
                                ...column,
                                toggle: this.props.toggles[column.dataField]
                            }))
                            .map(column => (
                                <DropdownItem
                                    key={column.dataField}
                                    active={column.toggle}
                                    onClick={() => {
                                       this.onColumnToggleHandler(column, this.props.onColumnToggle)
                                    }}
                                    //onClick = {() => this.props.onColumnToggle(column.dataField)}
                                    toggle={false}
                                >
                                    {column.text}
                                </DropdownItem>
                            ))
                    }

                </DropdownMenu>
            </Dropdown>

        )
    }

}
